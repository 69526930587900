import LayoutOne from "../../../../../assets/product-review-layout-1.svg";
import LayoutTwo from "../../../../../assets/product-review-layout-2.svg";
import LayoutThree from "../../../../../assets/product-review-layout-3.svg";

export const layoutItems: { id: number; name: string; src?: string, isPro: boolean }[] = [
  { id: 1, name: "V1", src: LayoutOne, isPro: false },
  { id: 2, name: "V2", src: LayoutTwo, isPro: true },
  { id: 3, name: "V3", src: LayoutThree, isPro: true },
];

export const whoCanReview = [
  {
    text: "Signed-In User",
    value: "signed_in_user",
  },
  {
    text: "Only Verified Buyer",
    value: "verified_user",
  },
];

export const whenNoReview = [
  {
    text: "Hide Review Section",
    value: "hide_review",
  },
  {
    text: "Show No Review",
    value: "no_reviews_img",
  },
];

export const themeColour = [
  {
    text: "Theme Colour",
    value: "theme_colour",
  },
  {
    text: "Multi-Colour",
    value: "multi_colour",
  },
];

export const ratingType = [
  {
    text: "Number Rating",
    value: "number_rating",
  },
  {
    text: "Star Rating",
    value: "star_rating",
  },
  {
    text: "Number & Star Rating",
    value: "both_star_and_number_rating",
  },
];
