import { getApplicationInfo, getCompanyId } from "../utils";
import axiosInstance from "./axios.service";

export const addProxy = (body: any) => {
  const { applicationId } = getApplicationInfo();

  return axiosInstance.post(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/proxy/`,
    body
  );
};

export const deleteProxy = (body: any) => {
  const { applicationId } = getApplicationInfo();

  return axiosInstance.delete(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/proxy/`,
    body
  );
};

export const updateSettings = (body: any) => {
  return axiosInstance.patch(
    `/api/v1.0/company/${getCompanyId()}/application`,
    body
  );
};
