export const whoCanAskQAndA = [
  {
    text: "Signed-In User",
    value: "signed_in_user",
  },
  {
    text: "Guest User",
    value: "guest_user",
  },
];

export const defaultQuestionAnswerConfig = {
  who_can_ask_q_and_a: "signed_in_user",
  section_heading: "Question and Answer",
  question_button_text: "Ask A Question",
  answered_by_title: "Answered by Customer Care",
  display_user_avatar: true,
  show_like_and_dislike: true,
  is_active: true,
};
