import React, { memo } from "react";
import { Dropdown } from "@gofynd/nitrozen-react";
import CSS from "./multi-select.atom.module.scss";

interface MultiSelectAtomProps {
  placeholder: string;
  id?: string;
  items: any[];
  onChange: Function;
  value: string[];
}

function MultiSelect({
  placeholder,
  id,
  onChange,
  items,
  value
}: MultiSelectAtomProps): JSX.Element {
  return (
    <>
      <div className={CSS["select-wrapper"]}>
        <Dropdown
          enableSelectAll
          multiple
          items={items}
          //   className={CSS["select-font-size"]}
          placeholder={placeholder}
          data-testid={id}
          onChange={onChange}
          value={value}
        />
      </div>
    </>
  );
}

const MultiSelectAtom = memo(MultiSelect);
export default MultiSelectAtom;
