import React, { useState, useEffect, useCallback, useRef } from "react";
import { Tooltip } from "@gofynd/nitrozen-react";

import Tabs from "../../components/molecules/tabs/tabs";
import ApplicationReviews from "./pages/application-reviews/application-review.component";
import ProductReviews from "./pages/product-reviews";
import CSS from "./index.module.scss";
import ToggleAtom from "../../components/atoms/toggle/toggle";
import TextAtom from "../../components/atoms/text/text";
import QuestionAnswerTable from "./pages/question-answer/question-answer.component";
import { IProductDetailConfig } from "../display-widgets/components/product-detail/product-detail.component";
import { getFormConfig, updateFormConfig } from "../../services/config.service";
import { defaultProductDetailsConfig } from "../display-widgets/components/product-detail/config/product-details.state.config";

const ManageReviews = () => {
  const [activeTabKey, setActiveTabKey] = useState<string | number>();
  const [autoPublish, setAutoPublish] = useState<boolean>(true);
  const [productReviewConfig, setProductReviewConfig] =
    useState<IProductDetailConfig>();

  const tabsItems = [
    {
      key: 1,
      label: `Product Reviews`,
      children: `Content of Tab Pane 1`,
    },
    {
      key: 2,
      label: `Application Reviews`,
      children: `Content of Tab Pane 2`,
    },
    {
      key: 3,
      label: `Questions And Answers`,
      children: `Content of Tab Pane 3`,
    },
  ];

  useEffect(() => {
    if (activeTabKey) {
      window.localStorage.setItem(
        "activeTabKey",
        JSON.stringify(activeTabKey ?? 1)
      );
    }
  }, [activeTabKey]);

  useEffect(() => {
    const activeKey = JSON.parse(
      window.localStorage.getItem("activeTabKey") ?? "1"
    );
    if (activeKey) {
      setActiveTabKey(activeKey ?? 1);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await getFormConfig<IProductDetailConfig>("product_review");
        if (res?.data?.data) {
          setProductReviewConfig(res.data.data?.[0]?.config);
          setAutoPublish(res.data.data?.[0]?.config?.auto_publish ?? false);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleTabChange = useCallback((key: string | number) => {
    setActiveTabKey(key);
  }, []);

  useEffect(() => {
    if (productReviewConfig) {
      (async () => {
        try {
          const res = await updateFormConfig<IProductDetailConfig>({
            widget: "product_review",
            payload: { ...productReviewConfig, auto_publish: autoPublish },
          });
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [autoPublish]);

  const tabChildren = () => {
    switch (activeTabKey) {
      case 1:
        return <ProductReviews />;

      case 2:
        return <ApplicationReviews />;

      case 3:
        return <QuestionAnswerTable />;

      default:
        return <ProductReviews />;
    }
  };

  return (
    <div>
      <div className={CSS.header}>
        <div className={CSS.tab_wrapper}>
          <Tabs
            items={tabsItems}
            defaultActiveKey={1}
            active={activeTabKey}
            onChange={handleTabChange}
          />
        </div>
        {activeTabKey === 1 && (
          <div className={CSS.auto_wrapper}>
            <TextAtom
              fontWeight={500}
              id="text-heading"
              text="Auto Publish"
              type="heading"
            />
            <Tooltip
              tooltipContent="Enable this toggle to auto-publish 4+ star reviews to your Storefront"
              position="left"
            />
            <ToggleAtom
              id="publish-toggle"
              value={autoPublish}
              onToggle={() => setAutoPublish((val) => !val)}
            />
          </div>
        )}
      </div>
      <div>{tabChildren()}</div>
    </div>
  );
};

export default ManageReviews;
