import axios from "axios";
import {
  IEmailConfig,
  IEmailProvider,
} from "../pages/manage-emails/context/email-context";
import { getApplicationInfo, getCompanyId } from "../utils";

type emailConfigType =
  | "email_product_review"
  | "email_questions_answers"
  | "email_application_review";

interface IGetEmailConfigResponse {
  message: string;
  data: [
    {
      emailConfig: IEmailConfig;
    }
  ];
}

interface IGetEmailProviderParams {
  pageSize: number;
  pageNo: number;
}
interface IGetEmailProviderResponse {
  message: string;
  data: [
    {
      emailProviders: IEmailProvider;
    }
  ];
}

interface ISendEmailResponse {
  message: string;
}

interface IGetEmailFormConfigResponse<T> {
  message: string;
  data: [
    {
      application: string;
      config: T;
    }
  ];
}

interface IUpdateEmailFormConfigResponse<T> {
  data: [
    {
      config: T;
    }
  ];
}

interface IGetEmailConfigStatusResponse {
  message: string;
  data: {
    _id: string;
    entity_type: string;
    config: {
      is_enabled: boolean;
    };
  }[];
}

export const getEmailConfig = async <T>(type: emailConfigType) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetEmailFormConfigResponse<T>>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/email/configurations?company_id=${getCompanyId()}&type=${type}`
  );
};

export const getEmailConfigStatus = () => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetEmailConfigStatusResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/email/status?company_id=${getCompanyId()}`
  );
};

export const updateEmailConfig = async <T>(
  type: emailConfigType,
  payload: T
) => {
  const { applicationId } = getApplicationInfo();

  return axios.post<IUpdateEmailFormConfigResponse<T>>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/email/configurations?company_id=${getCompanyId()}&type=${type}`,
    { config: { ...payload } }
  );
};

export const getEmailMetadata = async () => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetEmailConfigResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/email/metadata?company_id=${getCompanyId()}`
  );
};

export const getEmailProviders = async ({
  pageNo,
  pageSize,
}: IGetEmailProviderParams) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetEmailProviderResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/email/providers?company_id=${getCompanyId()}&page_no=${pageNo}&page_size=${pageSize}`
  );
};

export const sendEmail = async (
  type: "submit_review" | "follow_up" | "seller_replied",
  payload: any
) => {
  const { applicationId } = getApplicationInfo();

  return axios.post<ISendEmailResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/email/send?company_id=${getCompanyId()}&type=${type}&is_test_email=true`,
    {
      ...payload,
    }
  );
};
