import { IPageOption } from "../floating-review.component";

export const defaultFloatingReviewConfig = {
  heading: "All Reviews",
  floating_position: "right",
  floating_behaviour: "open_in_modal",
  page_link: "",
  show_pages: [],
  is_active: true,
};

export const defaultPageOptions: IPageOption[] = [
  {
    value: "about-us",
    text: "About Us",
    path: "about-us",
  },
  {
    value: "single-page-checkout",
    text: "Single Page Checkout",
    path: "cart/checkout",
  },
  {
    value: "wishlist",
    text: "Wishlist",
    path: "wishlist",
  },
];
