import React, { useState } from "react";
import MonacoEditor from "@monaco-editor/react";

import CSS from "./code-editor.module.scss";
import ButtonAtom from "../../atoms/button/button.atom";

interface ICodeEditor {
  defaultValue: string;
  content: string;
  handleSaveClick: (value: string) => void;
  handleCancelClick: () => void;
}

const CodeEditor: React.FunctionComponent<ICodeEditor> = ({
  defaultValue,
  content,
  handleSaveClick,
  handleCancelClick,
}) => {
  const [code, setCode] = useState<string>(content);

  const editorOptions = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    theme: "vs-dark",
  };

  function handleEditorChange(value: string | undefined) {
    setCode(value ?? "");
  }

  const handleSetDefaultValue = () => {
    setCode(defaultValue);
  };

  return (
    <>
      <MonacoEditor
        language="html"
        theme="vs-dark"
        options={editorOptions}
        onChange={handleEditorChange}
        value={code}
        height="60vh"
        width="100%"
      />
      <div className={CSS.footer}>
        <ButtonAtom label="Reset Default" onClick={handleSetDefaultValue} />
        <ButtonAtom
          label="Cancel"
          onClick={handleCancelClick}
          theme="secondary"
          backgroundColor="#fff"
          color="#2E31BE"
        />
        <ButtonAtom
          label="Save & Update"
          onClick={() => handleSaveClick(code)}
        />
      </div>
    </>
  );
};

export default React.memo(CodeEditor);
