import axios from "axios";
import { getApplicationInfo, getCompanyId } from "../utils";

export type IApplicationPageData = {
  value: string;
  text: string;
  path: string;
};

interface IGetPagesResponse {
  message: string;
  data: IApplicationPageData[];
}

export const getStorefrontTheme = async () => {
  const { applicationId } = getApplicationInfo();

  return axios.get<any>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/configurations/themes-variables?company_id=${getCompanyId()}`
  );
};
