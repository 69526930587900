import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import jsonToCsvExport from "json-to-csv-export";
import CSS from "./table.module.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { tableColumnData } from "./config/table.grid.constant";
import PaginationOrganism from "./organism/pagination/pagination.organism";
import {
  getApplications,
  IGetApplicationReviewResponse,
} from "../../service/application-review.service";
import ButtonAtom from "../../../../../../components/atoms/button/button.atom";
import { FirstDataRenderedEvent } from "ag-grid-community";
import { getApplicationInfo, getCompanyId } from "../../../../../../utils";

type ColumnDef = {
  [key: string]:
    | string
    | boolean
    | React.ComponentClass<unknown>
    | Function
    | number
    | {};
};

interface IRowData {
  product_name?: string;
  brief?: string;
  description?: string;
  image?: string;
  model?: string;
  price?: number;
  date?: string;
  status?: string;
}

interface ITableGrid {
  tableData: IGetApplicationReviewResponse | undefined;
  paginationPageData: {
    pageList: any[];
    currentPage: number;
    pageSize: number;
    itemTotal: number;
  };
  handleRowPerPage: (pageSize: string) => void;
  handleNextPage: () => void;
  handlePrevPage: () => void;
}

let worker: any = null;

const TableGrid = ({
  tableData,
  paginationPageData,
  handleRowPerPage,
  handleNextPage,
  handlePrevPage,
}: ITableGrid) => {
  // TODO: need to change type any here
  const [columnDefs] = useState<any>(() => tableColumnData());
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const fallbackData = useRef<any[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);

  const agGridRef = useRef<AgGridReact<IRowData>>(null);

  const defaultColDef = useMemo(() => {
    return {
      resizable: false,
    };
  }, []);

  useEffect(() => {
    setSelectedData([]);
  }, [tableData]);

  useEffect(() => {
    if (!worker) {
      worker = new Worker(
        `${window.location.protocol}//${window.location.host}/application_review_worker.js`
      );
    }

    worker.onmessage = function (e: any) {
      setShowLoader(false);
      setSelectedData(e.data);
    };
  }, []);

  const handleSelectAllRecords = async () => {
    setShowLoader(true);

    agGridRef.current?.api.selectAllOnCurrentPage();

    worker.postMessage({
      pageSize: tableData?.page?.item_total,
      companyId: getCompanyId(),
      applicationId: getApplicationInfo()?.applicationId,
    });
  };

  const handleClearSelection = () => {
    setSelectedData([]);
    agGridRef?.current?.api?.deselectAll();
  };

  const handleExportClick = () => {
    const dataToBeExported = selectedData?.map((data) => {
      const formattedDate = new Date(data.created_at).toLocaleString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return {
        id: data?.id,
        review: data?.description,
        tags: data?.tags?.join(", "),
        orderId: data?.order_id,
        rating: data?.rating,
        date: formattedDate,
      };
    });

    jsonToCsvExport({
      data: dataToBeExported,
      filename: "Application_Review",
      delimiter: ",",
      headers: ["ID", "Review", "Tags", "Order Id", "Rating", "Date"],
    });
  };

  const onSelectionChanged = () => {
    const selectedRows = agGridRef?.current?.api?.getSelectedRows();

    if (selectedRows) {
      setSelectedData(selectedRows);
    }
  };

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    agGridRef.current!.api.sizeColumnsToFit();
  }, []);

  return (
    <div className={`${CSS.cs_table_container} ag-theme-alpine`}>
      {selectedData?.length > 0 && (
        <div className={CSS.box_container}>
          <div className={CSS.text}>
            {selectedData?.length === paginationPageData?.itemTotal
              ? `All ${selectedData?.length} entries in Application Review are selected.`
              : `All ${selectedData?.length} records on this page are selected.`}
          </div>
          {selectedData?.length === paginationPageData?.itemTotal ? (
            <ButtonAtom
              className="cs-rm-20"
              theme={"secondary"}
              borderColor="#7D7676"
              backgroundColor="#fff"
              color="#7D7676"
              label="Clear Selection"
              onClick={handleClearSelection}
            />
          ) : (
            <ButtonAtom
              className="cs-rm-20"
              theme={"secondary"}
              borderColor="#7D7676"
              backgroundColor="#fff"
              color="#7D7676"
              label={
                showLoader
                  ? "Selecting, Please Wait"
                  : `Select All ${tableData?.page?.item_total} Records`
              }
              onClick={handleSelectAllRecords}
            />
          )}
          <ButtonAtom
            theme="primary"
            label="Export"
            onClick={handleExportClick}
          />
        </div>
      )}
      <AgGridReact
        ref={agGridRef}
        rowData={tableData?.items}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection={"multiple"}
        suppressRowClickSelection={true}
        pagination={true}
        suppressPaginationPanel={true}
        onSelectionChanged={onSelectionChanged}
        onFirstDataRendered={onFirstDataRendered}
      ></AgGridReact>

      {/* -------- pagination -------- */}
      <PaginationOrganism
        handleRowPerPage={handleRowPerPage}
        paginationPageData={paginationPageData}
        handleNextPage={() => {
          setSelectedData([]);
          handleNextPage();
        }}
        handlePrevPage={() => {
          setSelectedData([]);
          handlePrevPage();
        }}
      />
    </div>
  );
};

export default TableGrid;
