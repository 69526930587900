import CSS from "./card.module.scss";
import upArrow from "./../../../assets/svgs/up_arrow.svg";
import downArrow from "./../../../assets/svgs/down_arrow.svg";

interface IItemProps {
  name: string;
  is_increased: boolean;
  percentage: number;
  value: number;
}

interface ICardProps {
  item: IItemProps;
  analyticsFilter: string | number | undefined;
}

export const Card = (props: ICardProps) => {
  const { item, analyticsFilter } = props;

  return (
    <div className={CSS.card_container}>
      <div className={CSS.text_name}>{item.name}</div>
      <div className={CSS.value_container}>
        <div className={CSS.text_value}>{item.value}</div>
        {analyticsFilter !== "all" && (
          <div className={item.is_increased ? CSS.increase : CSS.decrease}>
            {item.is_increased ? (
              <img src={upArrow} alt="up_arrow" />
            ) : (
              <img src={downArrow} alt="down_arrow" />
            )}{" "}
            {Math.abs(item.percentage).toFixed(2) + "%"}
          </div>
        )}
      </div>
    </div>
  );
};
