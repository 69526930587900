import { memo, useEffect, useState } from "react";
import { produce } from "immer";

import EditIcon from "../../../../assets/edit.svg";
import DeleteIcon from "../../../../assets/delete.svg";
import TickIcon from "../../../../assets/tick.svg";
import CrossIcon from "../../../../assets/cross.svg";

import CSS from "./table.molecule.module.scss";
import InputWithTags from "../../../../components/atoms/input-with-tags/input-with-tags.atom";
import InputTextAtom from "../../../../components/atoms/input-text/input-text.atom";
import { IRowData, ITableData, ITableProps } from "./table.molecule.types";
import { DefaultTableData } from "./table.constants";

const Table: React.FunctionComponent<ITableProps> = ({
  headers = [],
  placeholder = "",
  tableData = DefaultTableData,
  handleChange = () => {},
}) => {
  const [tableDataCopy, setTableDataCopy] = useState<ITableData[]>(tableData);
  const [inEditMode, setInEditMode] = useState<{
    status: boolean;
    rowKey: number | string | null;
  }>({
    status: false,
    rowKey: null,
  });

  useEffect(() => {
    setTableDataCopy(tableData);
  }, [tableData]);

  const handleOnChange = (
    key: number | string,
    index: number,
    value: string | string[]
  ) => {
    setTableDataCopy((prevState) => {
      return produce(prevState, (draft: ITableData[]) => {
        draft?.forEach((tableData: ITableData) => {
          if (tableData?.key === key) {
            tableData.rowData[index].value = value;
          }
        });
      });
    });
  };

  const handleEditButtonClick = (key: number | string) => {
    setInEditMode({
      status: true,
      rowKey: key,
    });
  };

  const handleCrossButtonClick = () => {
    setTableDataCopy(tableData);
    setInEditMode({
      status: false,
      rowKey: null,
    });
  };

  const handleSaveButtonClick = (key: string | number) => {
    const newRowData =
      tableDataCopy?.find((tableData) => tableData.key === key)?.rowData ?? [];
    handleChange({ action: "CHANGE", rowKey: key, rowData: newRowData });

    setInEditMode({
      status: false,
      rowKey: null,
    });
  };

  const handleDeleteButtonClick = (key: string | number) => {
    handleChange({ action: "DELETE", rowKey: key, rowData: [] });
  };

  return (
    <div className={CSS.table_wrapper}>
      <table className={CSS.table}>
        <thead className={CSS.table_heading}>
          <tr>
            {headers?.map((heading) => {
              return <th>{heading}</th>;
            })}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableDataCopy?.map((item: ITableData) => {
            return (
              <tr key={item.key}>
                {item.rowData?.map((row: IRowData, index: number) => {
                  return (
                    <>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === item.key ? (
                          <>
                            {Array.isArray(row.value) ? (
                              <InputWithTags
                                tags={row.value}
                                onChange={(tags) => {
                                  handleOnChange(item.key, index, tags);
                                }}
                              />
                            ) : (
                              <InputTextAtom
                                value={row.value}
                                onChange={(text) => {
                                  handleOnChange(item.key, index, text);
                                }}
                                placeholder={placeholder}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {Array.isArray(row.value)
                              ? row.value.join(" | ")
                              : row.value}
                          </>
                        )}
                      </td>
                    </>
                  );
                })}
                <td>
                  {inEditMode.status && inEditMode.rowKey === item.key ? (
                    <div className={CSS.table_action_wrapper}>
                      <button
                        className={CSS.table_action_button}
                        onClick={() => handleSaveButtonClick(item.key)}
                      >
                        <img src={TickIcon} alt="tick" />
                      </button>
                      <button
                        className={CSS.table_action_button}
                        onClick={() => handleCrossButtonClick()}
                      >
                        <img src={CrossIcon} alt="cross" />
                      </button>
                    </div>
                  ) : (
                    <div className={CSS.table_action_wrapper}>
                      <button
                        className={CSS.table_action_button}
                        onClick={() => handleEditButtonClick(item.key)}
                      >
                        <img src={EditIcon} alt="edit" />
                      </button>
                      <button
                        className={CSS.table_action_button}
                        onClick={() => handleDeleteButtonClick(item.key)}
                      >
                        <img src={DeleteIcon} alt="delete" />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default memo(Table);
