import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ActionBar from "./components/action-bar";
import CSS from "./question.detail.module.scss";
import generateIC from "./assets/icons/generate.png";
import ProductOverview from "./components/product-overview";
import ThumbUpIcon from "./assets/icons/thumb_up_icon.svg";
import ThumbDownIcon from "./assets/icons/thumb_down_icon.svg";
import {
  getGeneratedReply,
  getQuestionDetails,
  getQuestionReply,
  IQuestionDetail,
  IQuestionReply,
  replyQuestion,
  updateQuestionStatus,
} from "./service/question.details.service";
import BadgeAtom from "../../../../../../components/atoms/badge/badge.atom";
import ButtonAtom from "../../../../../../components/atoms/button/button.atom";
import BorderAtom from "../../../../../../components/atoms/border/border.component";
import FieldInputBox from "../../../../../../components/molecules/field-control/input-box/input-box.molecules";
import { IQuestionAnswerData } from "../../service/question-answer.service";
import TextAtom from "../../../../../../components/atoms/text/text";
import { getFormattedDate } from "../../../../../../utils";
import {
  showDangerToast,
  showSuccessToast,
} from "../../../../../../services/toast.service";

const QuestionDetail = () => {
  const [questionDetail, setQuestionDetail] = useState<IQuestionDetail>();
  const [questionComment, setQuestionComment] = useState<IQuestionReply>();
  const [comment, setComment] = useState<string>("");
  const [sellerReply, setSellerReply] = useState<string>("");
  const { question_id } = useParams();
  const location = useLocation();

  useEffect(() => {
    getReviewDetail();
    getReply();
  }, []);

  const getReviewDetail = async () => {
    try {
      const res = await getQuestionDetails(question_id ?? "");

      if (res?.data) {
        setQuestionDetail(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getReply = async () => {
    try {
      const res = await getQuestionReply(question_id ?? "");

      if (res?.data?.items) {
        setQuestionComment(res?.data?.items?.[0]);
        setSellerReply(res?.data?.items?.[0]?.comment);
      }

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRejectClick = async () => {
    try {
      const res = await updateQuestionStatus(question_id ?? "", "rejected");
      if (res?.data) {
        showSuccessToast("Review Rejected Successfully");
        getReviewDetail();
      }
    } catch (err) {
      showDangerToast("Error in Rejecting Review");
      console.log(err);
    }
  };

  const handlePublishClick = async () => {
    try {
      const res = await updateQuestionStatus(question_id ?? "", "published");
      if (res?.data) {
        showSuccessToast("Review Published Successfully");
        getReviewDetail();
        setComment("");
      }
    } catch (err) {
      showDangerToast("Error in Publishing Review");
      console.log(err);
    }
  };

  const handleReplyClick = async () => {
    if (comment) {
      try {
        const res = await replyQuestion(
          question_id ?? "",
          comment,
          questionDetail?.created_by ?? "",
          questionDetail?.entity?.id ?? ""
        );
        if (res?.data) {
          showSuccessToast("Question Replied Successfully");
          getReply();
        }
      } catch (err) {
        showDangerToast("Error in Replying Question");
        console.log(err);
      }
    }
  };

  const handleGenerateAutoReply = async () => {
    try {
      const res = await getGeneratedReply(question_id ?? "");

      if (res?.data?.data) {
        showSuccessToast("Reply Generated Successfully");
        setComment(res?.data?.data?.autoReply);
      }
    } catch (err) {
      showDangerToast("Error in Generating Reply");
      console.log(err);
    }
  };

  const handleReplyAndPublishClick = () => {
    handlePublishClick();
    handleReplyClick();
  };

  return (
    <div>
      <ActionBar />
      <div className={`${CSS.cs_review_detail_layout} cs-tm-24`}>
        <div>
          <div className={CSS.header}>
            <div className={`${CSS.cs_review_detail_badge}`}>
              {questionDetail?.approval?.status === "published" && (
                <BadgeAtom label="PUBLISHED" size="small" />
              )}
              {questionDetail?.approval?.status === "unpublished" && (
                <BadgeAtom label="UNPUBLISHED" size="small" state="disable" />
              )}
              {questionDetail?.approval?.status === "rejected" && (
                <BadgeAtom label="REJECTED" size="small" state="error" />
              )}
              {questionDetail?.approval?.status === "reported" && (
                <BadgeAtom label="REPORTED" size="small" state="error" />
              )}
            </div>
            <div className={CSS.flex}>
              {questionDetail?.vote_count?.upvote !== undefined && (
                <div className={`${CSS.flex} cs-rm-16`}>
                  <div className={CSS.icon}>
                    <img src={ThumbUpIcon} alt="thumb-up" />
                  </div>
                  <TextAtom
                    text={String(questionDetail?.vote_count?.upvote)}
                    type="label"
                  />
                </div>
              )}
              {questionDetail?.vote_count?.downvote !== undefined && (
                <div className={CSS.flex}>
                  <div className={CSS.icon}>
                    <img src={ThumbDownIcon} alt="thumb-down" />
                  </div>
                  <TextAtom
                    text={String(questionDetail?.vote_count?.downvote)}
                    type="label"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="cs-tm-12 cs-bm-12">
            {questionDetail?.created_by ? (
              <TextAtom text="Signed In User" type="label" />
            ) : (
              <TextAtom text="Non Signed In User" type="label" />
            )}
          </div>

          <TextAtom
            className="cs-tm-10"
            text={questionDetail?.question?.text ?? ""}
            type="header"
          />

          <TextAtom
            className="cs-tm-10"
            text={`${getFormattedDate(questionDetail?.created_at ?? "")}`}
            type="label"
          />

          {sellerReply && (
            <div className={`${CSS.box} ${CSS.arrow_top}`}>
              <div className={CSS.box_heading}>Seller</div>
              <div className={CSS.box_description}>{sellerReply}</div>
            </div>
          )}

          {/* Actions */}
          <div className="cs-tm-20 cs-dis-flex">
            {questionDetail?.approval?.status !== "rejected" && (
              <ButtonAtom
                className="cs-rm-20"
                theme={"secondary"}
                borderColor="#7D7676"
                backgroundColor="#fff"
                color="#7D7676"
                label="Reject"
                onClick={handleRejectClick}
              />
            )}
            {questionDetail?.approval?.status === "rejected" && (
              <ButtonAtom
                theme="primary"
                label="Publish"
                onClick={handlePublishClick}
              />
            )}
          </div>
          <BorderAtom className="cs-tm-24" />
          {/* UI ACTIONS */}
          <FieldInputBox
            labelClassName="cs-tm-20"
            inputType="textarea"
            label="Reply To Buyer"
            placeholder="Write reply"
            value={comment}
            onChange={(text: string) => setComment(text)}
          />
          <div className="cs-dis-flex cs-tm-20">
            {questionComment?.comment && (
              <ButtonAtom
                theme="primary"
                label="Update"
                onClick={handleReplyClick}
              />
            )}
            {!questionComment?.comment &&
              questionDetail?.approval?.status !== "unpublished" && (
                <ButtonAtom
                  theme="primary"
                  label="Reply"
                  onClick={handleReplyClick}
                />
              )}
            {!questionComment?.comment &&
              questionDetail?.approval?.status === "unpublished" && (
                <ButtonAtom
                  theme="primary"
                  label="Reply & Publish"
                  onClick={handleReplyAndPublishClick}
                />
              )}
            <div
              className="cs-dis-flex cs-center cs-lm-20 cs-cursor-pointer"
              onClick={handleGenerateAutoReply}
            >
              <img
                src={generateIC}
                className={CSS.cs_generate_btn_ic}
                alt="generate-btn"
              />

              <div className={`${CSS.cs_generate_btn} cs-lm-8`}>Generate</div>
            </div>
          </div>
        </div>

        {/** todo once we get product id  */}
        <div>
          <ProductOverview
            productId={questionDetail?.entity?.id ?? ""}
            fallbackProductDetails={location?.state}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionDetail;
