import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import TextAtom from "../../components/atoms/text/text";
import Card from "../../components/molecules/card/card.molecule";

import { defaultDisplayWidgets } from "./display-widgets.constants";

import { IDisplayWidgetType } from "./display-widgets.types";

import CSS from "./display-widgets.module.scss";
import { getStatus } from "../../services/config.service";

const DisplayWidgets: React.FunctionComponent = () => {
  const [displayWidgets, setDisplayWidgets] = useState<IDisplayWidgetType[]>(
    []
  );

  useEffect(() => {
    (async () => {
      /**
       * @todo adding api call for the widgets
       */
      try {
        const res = await getStatus();
        if (res) {
          const displayWidgets = defaultDisplayWidgets?.map((widget) => {
            const is_active =
              res?.data?.data?.find((a) => a?.entity_type === widget?.type)
                ?.config?.is_active ?? false;
            return { ...widget, is_active: is_active };
          });

          setDisplayWidgets(displayWidgets);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div data-testid="display-widgets">
      <div className={CSS.widget_heading}>
        <TextAtom
          text={"Display Widgets"}
          type="heading-1"
          id={"display-widgets"}
        />
      </div>
      <div className={CSS.widget_cards}>
        {displayWidgets?.map((widgetData: IDisplayWidgetType) => {
          return <Card cardData={widgetData} id="widget-card" key={uuidv4()} />;
        })}
      </div>
    </div>
  );
};

export default DisplayWidgets;
