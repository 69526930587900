import React, { memo } from "react";
import withLabel from "../../../../hoc/label.hoc";
import { ColorInputProps } from "../../../atoms/color-input/color-input-props";
import ColorInput from "../../../atoms/color-input/color-input.component.atom";

function ColorBox({ value, onChange, label }: ColorInputProps): JSX.Element {
  return (
    <>
      <ColorInput label={label} value={value} onChange={onChange} />
    </>
  );
}

const FieldColorBox = withLabel(memo(ColorBox));
export default FieldColorBox;
