import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSubscriptionContext } from "../context/subscription-context";
import { setCompanyId } from "../utils";

interface IRouteWrapperProps {
  children: JSX.Element;
}
export function RouteWrapper({ children }: IRouteWrapperProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { subscription } = useSubscriptionContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      subscription?.approved === "true" ||
      subscription?.details?.status === "active"
    ) {
      setIsLoading(false);
    } else {
      navigate(`/payment?redirect=${pathname}`);
    }
  }, [subscription]);

  return <>{!isLoading && children}</>;
}
