import React, { memo } from "react";
import TextAtom from "../../../../../../../../components/atoms/text/text";
import ImageContainerAtom from "../../atoms/image-container/image.container.atom";
import DefaultImage from "../../../../../../../../assets/default_icon.png";

import CSS from "./product.container.module.scss";

interface ProductContainerProps {
  id?: string;
  src: string;
  productName: string;
  productBrief?: string;
}

const ProductContainerOrganism: React.FunctionComponent<
  ProductContainerProps
> = ({ id = "", src = DefaultImage, productName, productBrief = "" }) => {
  return (
    <div
      id={"data-testid-product-container" + id}
      className={`${CSS.cs_center} ${CSS.cs_p_20}`}
    >
      <div>
        <ImageContainerAtom src={src} id={id} />
      </div>
      <div>
        <TextAtom
          text={productName?.length ? productName : "Product Not Found"}
          type="heading"
          ellipsis={true}
          lineHeight={15.6}
        />
        <TextAtom
          fontWeight={300}
          text={productBrief}
          type="heading"
          ellipsis={true}
          lineHeight={15.6}
        />
      </div>
    </div>
  );
};

export default memo(ProductContainerOrganism);
