import React, { memo } from "react";
import CSS from "./action.container.module.scss";
import replyButtonIc from "../../assets/icons/replyButtonIC.png";

interface ActionContainerProps {
  id?: string;
  buttonDisable: boolean;
  handleOpenClick: () => void;
}

const ActionContainerOrganism: React.FunctionComponent<
  ActionContainerProps
> = ({ id = "", buttonDisable = false, handleOpenClick = () => {} }) => {
  return (
    <div
      id={"action-container" + id}
      className={`${CSS.cs_dis_flex} ${
        buttonDisable ? CSS.cs_btn_disable : ""
      }`}
    >
      <button
        onClick={handleOpenClick}
        disabled={buttonDisable}
        className={`${CSS.cs_mr_8} ${CSS.cs_center} ${CSS.cs_button} ${
          buttonDisable ? CSS.cs_pointer_disable : CSS.cs_pointer
        }`}
        title="Reply Question"
      >
        <img src={replyButtonIc} alt="reply-btn-icon" />
      </button>
    </div>
  );
};

export default memo(ActionContainerOrganism);
