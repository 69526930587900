import React, { useCallback } from "react";
import arrowBack from "./assets/icon/arrow_back.png";
import CSS from "./action-bar.module.scss";
import TextAtom from "../../../../../../components/atoms/text/text";
import BorderAtom from "../../../../../../components/atoms/border/border.component";
import { useNavigate } from "react-router-dom";

const ActionBar = () => {
  const navigate = useNavigate();

  const handleBackBtn = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div>
      <div className={CSS.cs_dis_flex}>
        <img
          onClick={handleBackBtn}
          alt="back-arrow"
          src={arrowBack}
          className={`${CSS.cs_back_arrow} ${CSS.cs_rm_5} ${CSS.cs_cursor_pointer}`}
        />
        <TextAtom
          text="Back to Product Review"
          type="heading"
          lineHeight={18}
        />
      </div>

      <div className="cs-tm-20">
        <BorderAtom />
      </div>
    </div>
  );
};

export default ActionBar;
