export const filterDropdownOptions = [
  { text: "All", value: "all" },
  { text: "Last 7 days", value: 7 },
  { text: "Last 30 Days", value: 30 },
  { text: "Last 3 Months", value: 90 },
];

export const analyticsFilterDropdownOptions = [
  { text: "All", value: "all" },
  { text: "Last 1 day", value: 1 },
  { text: "Last 7 days", value: 7 },
  { text: "Last 30 Days", value: 30 },
];

export const defaultTotalReviews = [
  {
    _id: "2023-07-28",
    negativeCount: 0,
    positiveCount: 5,
  },
  {
    _id: "2023-07-27",
    negativeCount: 4,
    positiveCount: 11,
  },
  {
    _id: "2023-07-29",
    negativeCount: 0,
    positiveCount: 16,
  },
  {
    _id: "2023-07-30",
    negativeCount: 5,
    positiveCount: 50,
  },
  {
    _id: "2023-07-31",
    negativeCount: 8,
    positiveCount: 11,
  },
  {
    _id: "2023-08-01",
    negativeCount: 10,
    positiveCount: 5,
  },
  {
    _id: "2023-08-02",
    negativeCount: 0,
    positiveCount: 1,
  },
  {
    _id: "2023-08-03",
    negativeCount: 2,
    positiveCount: 15,
  },
  {
    _id: "2023-08-04",
    negativeCount: 0,
    positiveCount: 11,
  },
  {
    _id: "2023-08-05",
    negativeCount: 10,
    positiveCount: 1,
  },
  {
    _id: "2023-08-06",
    negativeCount: 6,
    positiveCount: 17,
  },
  {
    _id: "2023-08-07",
    negativeCount: 9,
    positiveCount: 25,
  },
  {
    _id: "2023-08-08",
    negativeCount: 11,
    positiveCount: 11,
  },
  {
    _id: "2023-08-09",
    negativeCount: 12,
    positiveCount: 1,
  },
  {
    _id: "2023-08-10",
    negativeCount: 9,
    positiveCount: 11,
  },
  {
    _id: "2023-08-11",
    negativeCount: 4,
    positiveCount: 11,
  },
  {
    _id: "2023-08-12",
    negativeCount: 6,
    positiveCount: 11,
  },
  {
    _id: "2023-08-13",
    negativeCount: 7,
    positiveCount: 11,
  },
  {
    _id: "2023-08-14",
    negativeCount: 1,
    positiveCount: 11,
  },
  {
    _id: "2023-08-15",
    negativeCount: 2,
    positiveCount: 11,
  },
  {
    _id: "2023-08-16",
    negativeCount: 6,
    positiveCount: 11,
  },
  {
    _id: "2023-08-17",
    negativeCount: 7,
    positiveCount: 11,
  },
  {
    _id: "2023-08-18",
    negativeCount: 8,
    positiveCount: 11,
  },
  {
    _id: "2023-08-19",
    negativeCount: 0,
    positiveCount: 11,
  },
  {
    _id: "2023-08-20",
    negativeCount: 7,
    positiveCount: 11,
  },
  {
    _id: "2023-08-21",
    negativeCount: 5,
    positiveCount: 11,
  },
  {
    _id: "2023-08-22",
    negativeCount: 5,
    positiveCount: 11,
  },
  {
    _id: "2023-08-23",
    negativeCount: 5,
    positiveCount: 11,
  },
  {
    _id: "2023-08-24",
    negativeCount: 7,
    positiveCount: 11,
  },
  {
    _id: "2023-08-25",
    negativeCount: 8,
    positiveCount: 11,
  },
  {
    _id: "2023-08-26",
    negativeCount: 9,
    positiveCount: 11,
  },
];
