import axios from "axios";
import { getApplicationInfo, getCompanyId } from "../../../../../../../utils";

export interface IQuestionDetail {
  vote_count: {
    upvote: number;
    downvote: number;
  };
  _id: string;
  question: {
    text: string;
  };
  entity: {
    type: string;
    id: string;
  };
  approval: {
    status: string;
  };
  created_by: string;
  application: string;
  created_at: string;
  updated_at: string;
}

export interface IProductDetailResponse {
  category_slug: string;
  tags: string[];
  name: string;
  no_of_boxes: number;
  is_image_less_product: boolean;
  is_dependent: boolean;
  description: string;
  item_type: string;
  slug: string;
  uid: number;
  media: [
    {
      url: string;
      type: string;
    }
  ];
  is_active: boolean;
  id: string;
  images: [
    {
      url: string;
      secure_url: string;
    }
  ];
  status: string;
  is_expirable: boolean;
  hsn_code: string;
  review_metric: {
    entity: {
      id: string;
      type: string;
    };
    created_at: string;
    rating_count: number;
    rating_metric: [
      {
        rating: number;
        count: number;
      }
    ];
    rating_sum: number;
    review_count: number;
    updated_at: string;
  };
  brand: {
    name: string;
  };
}

export interface IQuestionReply {
  comment: string;
  id: string;
}

export interface IUpdateQuestionStatusResponse extends IQuestionDetail {}

export interface IGetQuestionDetail extends IQuestionDetail {}

export interface IGetQuestionReply {
  items: IQuestionReply[];
}

export interface IGetAutoGeneratedReplyResponse {
  message: string;
  data: {
    autoReply: string;
  };
}

export interface IQuestionReplyResponse {}

export const getProductDetails = async (productId: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IProductDetailResponse>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/products/?item_ids=${productId}&company_id=${getCompanyId()}`
  );
};

export const getQuestionDetails = async (questionId: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetQuestionDetail>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/questions/${questionId}?company_id=${getCompanyId()}`
  );
};

export const updateQuestionStatus = async (
  questionId: string,
  status: "rejected" | "published"
) => {
  const { applicationId } = getApplicationInfo();

  return axios.patch<IUpdateQuestionStatusResponse>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/questions/${questionId}?company_id=${getCompanyId()}`,
    {
      approval: {
        status: status,
      },
    }
  );
};

export const replyQuestion = async (
  questionId: string,
  comment: string,
  userId: string,
  productId: string
) => {
  const { applicationId } = getApplicationInfo();

  return axios.post<IQuestionReplyResponse>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/comments/entity/question/entity-id/${questionId}/?company_id=${getCompanyId()}`,
    {
      comment,
      userId,
      productId,
    }
  );
};

export const getQuestionReply = async (questionId: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetQuestionReply>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/comments/entity/question/entity-id/${questionId}/?company_id=${getCompanyId()}`
  );
};

export const getGeneratedReply = async (questionId: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetAutoGeneratedReplyResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/questions/${questionId}/generate-reply?company_id=${getCompanyId()}`
  );
};
