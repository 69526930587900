import React, { memo } from "react";
import CSS from "./action.container.module.scss";
import replyButtonIc from "../../assets/icons/replyButtonIC.png";
import likeButtonIc from "../../assets/icons/likeButtonIC.png";
import likeButtonRedIcon from "../../../../../../../../assets/favourite_icon.svg";

interface ActionContainerProps {
  id?: string;
  buttonDisable?: boolean;
  reviewDetailView: () => void;
  isFavourite: boolean;
  onLikeButtonClick: () => void;
}

const ActionContainerOrganism: React.FunctionComponent<
  ActionContainerProps
> = ({
  id = "",
  buttonDisable = false,
  isFavourite = false,
  reviewDetailView = () => {},
  onLikeButtonClick = () => {},
}) => {
  return (
    <div
      id={"action-container" + id}
      className={`${CSS.cs_dis_flex} ${buttonDisable && CSS.cs_btn_disable}`}
    >
      <div
        onClick={reviewDetailView}
        className={`${CSS.cs_mr_8} ${CSS.cs_center} ${
          buttonDisable ? CSS.cs_pointer_disable : CSS.cs_pointer
        }`}
        title="Reply Review"
      >
        <img src={replyButtonIc} alt="reply-btn-icon" />
      </div>

      <div
        className={`${CSS.cs_center} ${
          buttonDisable ? CSS.cs_pointer_disable : CSS.cs_pointer
        }`}
        onClick={onLikeButtonClick}
        title="Mark Favourite Review"
      >
        <img
          src={isFavourite ? likeButtonRedIcon : likeButtonIc}
          alt="reply-btn-icon"
        />
      </div>
    </div>
  );
};

export default memo(ActionContainerOrganism);
