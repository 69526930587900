import { v4 as uuidv4 } from "uuid";
import { ITableData } from "./table.molecule.types";

export const transformToTableData = (data: string[]): ITableData[] => {
  return data?.map((value: string) => {
    return {
      key: uuidv4(),
      rowData: [
        {
          value,
        },
      ],
    };
  });
};

export const transformToQuestionData = (data: ITableData[]): string[] => {
  const transformedData: string[] = [];

  data?.forEach((value: ITableData) => {
    if (value?.rowData?.[0]?.value) {
      transformedData.push(value?.rowData?.[0]?.value as string);
    }
  });

  return transformedData;
};
