import React, { useState, useCallback } from "react";
import nunjucks from "nunjucks";

interface IEmailTemplate {
  emailTemplate: string;
  emailValues: { [key: string]: string | number };
}

const EmailTemplate: React.FunctionComponent<IEmailTemplate> = ({
  emailTemplate,
  emailValues,
}) => {
  // todo :- Add images for emails
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: nunjucks.renderString(emailTemplate, emailValues),
        }}
        style={{ borderRadius: "8px" }}
      ></div>
    </>
  );
};

export default EmailTemplate;
