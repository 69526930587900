import React, { memo } from "react";
import { Button } from "@gofynd/nitrozen-react";
import CSS from "./button.atom.module.scss";

interface IButtonAtomProps {
  id?: string;
  state?: string;
  size?: string;
  label: string;
  backgroundColor?: string;
  theme?: string;
  borderRadius?: string;
  borderColor?: string;
  color?: string;
  padding?: string;
  className?: string;
  onClick: (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => void;
  disable?: boolean;
}

const ButtonAtom: React.FunctionComponent<IButtonAtomProps> = ({
  id = "",
  size = "small",
  label = "",
  backgroundColor = "#2E31BE",
  borderRadius = "4px",
  borderColor = "#2E31BE",
  theme = "primary",
  color = "#FFF",
  padding = "9px 34px",
  onClick = () => { },
  className = "",
  disable = false
}) => {
  return (
    <div className={`${CSS.button_wrapper} ${className}`}>
      <Button
        theme={theme}
        data-testid={"button" + id}
        size={size}
        style={{ backgroundColor, borderRadius, borderColor, color, padding }}
        onClick={onClick}
        disabled={disable}
      >
        {label}
      </Button>
    </div>
  );
};

export default memo(ButtonAtom);
