import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import TextAtom from "../../components/atoms/text/text";
import Card from "../../components/molecules/card/card.molecule";

import { defaultManageEmails } from "./manage-emails.constants";

import { IManageEmailsType } from "./manage-emails.types";

import CSS from "./manage-emails.module.scss";
import {
  getEmailConfigStatus,
  getEmailMetadata,
  getEmailProviders,
} from "../../services/email.service";
import { EmailContextProvider, useEmailContext } from "./context/email-context";

const ManageEmails: React.FunctionComponent = () => {
  const [manageEmails, setManageEmails] = useState<IManageEmailsType[]>([]);
  const { setEmailData } = useEmailContext();

  useEffect(() => {
    (async () => {
      /**
       * @todo adding api call for the emails
       */
      try {
        const res = await getEmailConfigStatus();
        if (res) {
          const manageEmails = defaultManageEmails?.map((email) => {
            const is_enabled =
              res?.data?.data?.find((a) => a?.entity_type === email?.type)
                ?.config?.is_enabled ?? false;

            return { ...email, is_active: is_enabled };
          });

          setManageEmails(manageEmails);
        }
      } catch (err) {
        console.log(err);
      }

      try {
        const res = await getEmailMetadata();

        if (res?.data?.data) {
          setEmailData((prevState) => {
            return {
              ...prevState,
              emailConfig: res?.data?.data?.[0]?.emailConfig,
            };
          });
        }
      } catch (err) {
        console.log(err);
      }

      try {
        const params = { pageSize: 100, pageNo: 1 };
        const res = await getEmailProviders(params);

        if (res?.data?.data) {
          setEmailData((prevState) => {
            return {
              ...prevState,
              emailProviders: res?.data?.data?.[0]?.emailProviders,
            };
          });
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div data-testid="manage-emails">
      <div className={CSS.manage_emails_heading}>
        <TextAtom
          text={"Manage Emails"}
          type="heading-1"
          id={"text-manage-emails"}
        />
      </div>
      <div className={CSS.manage_emails_cards}>
        {manageEmails?.map((manageEmailData: IManageEmailsType) => {
          return (
            <Card cardData={manageEmailData} id="emails-card" key={uuidv4()} />
          );
        })}
      </div>
    </div>
  );
};

export default ManageEmails;
