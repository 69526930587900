export const QUESTIONS = {
    items: [
        {
            _id: "64d0cdf716046c6deb0173e5",
            vote_count: {
                upvote: 0,
                downvote: 0
            },
            question: {
                text: "sdsd"
            },
            entity: {
                type: "product",
                id: "7502058"
            },
            approval: {
                status: "published"
            },
            application: "6399ba5d24ab1b8fce131471",
            created_at: "2023-08-07T10:56:55.153Z",
            updated_at: "2023-08-07T10:56:55.153Z",
            id: "64d0cdf716046c6deb0173e5",
            is_upvotable: true,
            is_downvotable: true,
            created_by: {
                first_name: "Guest",
                last_name: "User",
                account_type: "guest"
            }
        },
        {
            _id: "64d0ca5216046c16f901736d",
            vote_count: {
                upvote: 0,
                downvote: 0
            },
            question: {
                text: "ssfsf"
            },
            entity: {
                type: "product",
                id: "7502058"
            },
            approval: {
                status: "published"
            },
            application: "6399ba5d24ab1b8fce131471",
            created_at: "2023-08-07T10:41:22.912Z",
            updated_at: "2023-08-07T10:41:22.912Z",
            id: "64d0ca5216046c16f901736d",
            is_upvotable: true,
            is_downvotable: true,
            created_by: {
                first_name: "Guest",
                last_name: "User",
                account_type: "guest"
            }
        },
        {
            _id: "64d0c75016046c04e6017322",
            vote_count: {
                upvote: 0,
                downvote: 0
            },
            question: {
                text: "sdsd"
            },
            entity: {
                type: "product",
                id: "7502058"
            },
            approval: {
                status: "published"
            },
            application: "6399ba5d24ab1b8fce131471",
            created_at: "2023-08-07T10:28:32.221Z",
            updated_at: "2023-08-07T10:28:32.221Z",
            id: "64d0c75016046c04e6017322",
            is_upvotable: true,
            is_downvotable: true,
            created_by: {
                first_name: "Guest",
                last_name: "User",
                account_type: "guest"
            }
        },
        {
            _id: "64d0c6d516046c2aeb017318",
            vote_count: {
                upvote: 0,
                downvote: 0
            },
            question: {
                text: "ssdsd"
            },
            entity: {
                type: "product",
                id: "7502058"
            },
            approval: {
                status: "published"
            },
            application: "6399ba5d24ab1b8fce131471",
            created_at: "2023-08-07T10:26:29.235Z",
            updated_at: "2023-08-07T10:26:29.235Z",
            id: "64d0c6d516046c2aeb017318",
            is_upvotable: true,
            is_downvotable: true,
            created_by: {
                first_name: "Guest",
                last_name: "User",
                account_type: "guest"
            }
        }
    ],
    page: {
        type: "number",
        current: 1,
        size: 4,
        item_total: 22,
        has_next: true
    }
};

export const PRODUCT = {
    type: "product",
    grouped_attributes: [
        {
            title: "Product Details",
            details: [
                {
                    key: "Name of the commodity",
                    type: "text",
                    value: "Tops"
                }
            ]
        }
    ],
    medias: [
        {
            url: "https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyndnp/wrkr/x5/products/pictures/item/free/original/play-clan/SB56254-KTP-CKSBLUE/0/L0YLW212dZ-1.jpg",
            type: "image"
        },
        {
            url: "https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyndnp/wrkr/x5/products/pictures/item/free/original/play-clan/SB56254-KTP-CKSBLUE/1/1-SMHJn8Es-2.jpg",
            type: "image"
        },
        {
            url: "https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyndnp/wrkr/x5/products/pictures/item/free/original/play-clan/SB56254-KTP-CKSBLUE/2/-YEOYni6rX-3.jpg",
            type: "image"
        },
        {
            url: "https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyndnp/wrkr/x5/products/pictures/item/free/original/play-clan/SB56254-KTP-CKSBLUE/3/Xeywu2XGW1-4.jpg",
            type: "image"
        },
        {
            url: "https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyndnp/wrkr/x5/products/pictures/item/free/original/play-clan/SB56254-KTP-CKSBLUE/4/Nunx1CL37g-5.jpg",
            type: "image"
        }
    ],
    brand: {
        name: "TESTBRAND1555",
        uid: 1,
        logo: {
            type: "image",
            url: "https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyndnp/wrkr/x5/brands/pictures/square-logo/original/Vw2jIte4G-Logo.png"
        },
        action: {
            page: {
                type: "products",
                query: {
                    brand: [
                        "testbrand1"
                    ]
                }
            },
            type: "page"
        },
        _custom_json: {},
        description: "Demo test brand"
    },
    uid: 7502058,
    item_code: "SB56254-KTP-CKS@BLUE",
    slug: "blue-top-5hlotkggarb",
    attributes: {
        primary_color: "Red",
        gender: "Gender",
        brand_name: "TESTBRAND1",
        primary_color_hex: "D0021B",
        item_code: "SB56254-KTP-CKS@BLUE",
        country_of_origin: "India",
        image_nature: "standard",
        brand: "TESTBRAND1555"
    },
    name: "Blue Top",
    has_variant: false,
    categories: [
        {
            id: 12,
            uid: 12,
            name: "Tops",
            logo: {
                type: "image",
                url: "https://hdn-1.fynd.com/media/banner_portrait/brand/original/540_ecba3a1af141467da8abc20500f983db.jpg"
            },
            action: {
                page: {
                    type: "products",
                    query: {
                        category: [
                            "tops"
                        ]
                    }
                },
                type: "page"
            },
            _custom_json: {}
        }
    ],
    category_map: {
        l1: {
            id: 5,
            uid: 5,
            name: "Clothing",
            logo: {
                type: "image",
                url: "https://hdn-1.fynd.com/media/banner_portrait/brand/original/540_ecba3a1af141467da8abc20500f983db.jpg"
            },
            action: {
                page: {
                    type: "products",
                    query: {
                        category: [
                            "clothing"
                        ]
                    }
                },
                type: "page"
            },
            _custom_json: {}
        },
        l2: {
            id: 6,
            uid: 6,
            name: "Tops, Tees & Tunics",
            logo: {
                type: "image",
                url: "https://hdn-1.fynd.com/media/banner_portrait/brand/original/540_ecba3a1af141467da8abc20500f983db.jpg"
            },
            action: {
                page: {
                    type: "products",
                    query: {
                        category: [
                            "tops-tees-tunics"
                        ]
                    }
                },
                type: "page"
            },
            _custom_json: {}
        },
        l3: {
            id: 12,
            uid: 12,
            name: "Tops",
            logo: {
                type: "image",
                url: "https://hdn-1.fynd.com/media/banner_portrait/brand/original/540_ecba3a1af141467da8abc20500f983db.jpg"
            },
            action: {
                page: {
                    type: "products",
                    query: {
                        category: [
                            "tops"
                        ]
                    }
                },
                type: "page"
            },
            _custom_json: {}
        }
    },
    tryouts: [],
    promo_meta: {},
    rating: null,
    rating_count: null,
    image_nature: "standard",
    tags: [],
    teaser_tag: "",
    no_of_boxes: 1,
    custom_order: {
        manufacturing_time_unit: "days",
        is_custom_order: false,
        manufacturing_time: 0
    },
    _custom_json: {
        _app: {}
    },
    _custom_meta: [],
    is_dependent: false,
    moq: {
        minimum: 1,
        increment_unit: 1
    },
    seo: {
        title: "Blue Top",
        description: ""
    },
    net_quantity: {},
    highlights: [],
    description: "",
    short_description: "",
    country_of_origin: "India",
    similars: []
}