import { useEffect, useRef, useState } from "react";

import { ReactComponent as DashboardSvg } from "../../../assets/dashboard.svg";
import { ReactComponent as ManageReviewSvg } from "../../../assets/manage-review.svg";
import { ReactComponent as DisplayWidgetsSvg } from "../../../assets/display-widgets.svg";
import { ReactComponent as ManageEmailsSvg } from "../../../assets/email.svg";
import { ReactComponent as SmartControlsSvg } from "../../../assets/smart-controls.svg";
import { ReactComponent as RewardsSvg } from "../../../assets/rewards.svg";

import CSS from "./layout.template.module.scss";
import classNames from "classnames";

import Dashboard from "../../../pages/dashboard";
import Rewards from "../../../pages/rewards";
import SmartControls from "../../../pages/smart-controls";
import ManageReview from "../../../pages/manage-review";
import DisplayWidgets from "../../../pages/display-widgets";
import ManageEmails from "../../../pages/manage-emails";
import { Outlet, useNavigate } from "react-router-dom";
import ToggleAtom from "../../atoms/toggle/toggle";
import { getApplicationInfo } from "../../../utils";
import {
  addProxy,
  deleteProxy,
  updateSettings,
} from "../../../services/application.service";
import {
  showDangerToast,
  showSuccessToast,
} from "../../../services/toast.service";
import { Tooltip } from "@gofynd/nitrozen-react";

import { useConfigContext } from "../../../context/config-context";
import { getStorefrontTheme } from "../../../services/theme.service";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import ButtonAtom from "../../atoms/button/button.atom";

const Layout = () => {
  const [isActive, setActive] = useState(0);
  const [toggle, setToggle] = useState<boolean>();
  const { setConfig } = useConfigContext();
  const preventConfigUpdate = useRef<boolean>(false);

  useEffect(() => {
    let applicationData = getApplicationInfo();
    setToggle(applicationData?.is_active);

    preventConfigUpdate.current = true;

    MENUITEM.forEach((item, index) => {
      if (window.location.pathname.includes(item.path)) setActive(index);
    });
  }, []);

  useEffect(() => {
    const getTheme = async () => {
      try {
        const res = await getStorefrontTheme();
        if (res?.data?.data?.global_config) {
          const flattenObject = (obj: any, prefix = "") =>
            Object.keys(obj).reduce((acc: any, k) => {
              const pre = "";
              if (typeof obj[k] === "object")
                Object.assign(acc, flattenObject(obj[k], pre + k));
              else acc[pre + k] = obj[k];
              return acc;
            }, {});

          setConfig((prevData: any) => ({
            ...prevData,
            theme: flattenObject(res?.data?.data?.global_config),
          }));
        }
      } catch (err) {
        console.log(err);
      }
    };

    getTheme();
  }, []);

  const applyProxy = async () => {
    try {
      let res = await addProxy({});
      await updateSettings({
        application: getApplicationInfo().applicationId,
        enabled: true,
      });
      console.log("PROXY ADDED");

      if (res?.data) {
        showSuccessToast("Added Extension Successfully");
      }
    } catch (error) {
      console.log("FAILED TO ADD PROXY:", error);
      showDangerToast("Failed to Add Extension");
      preventConfigUpdate.current = true;
      setToggle((prevState) => !prevState);
    }
  };
  const removeProxy = async () => {
    try {
      let res = await deleteProxy({});
      await updateSettings({
        application: getApplicationInfo().applicationId,
        enabled: false,
      });
      console.log("REMOVED THE PROXY");

      if (res?.data) {
        showSuccessToast("Removed Extension Successfully");
      }
    } catch (error) {
      console.log("FAILED TO REMOVE PROXY:", error);
      showDangerToast("Failed to Remove Extension");
      preventConfigUpdate.current = true;
      setToggle((prevState) => !prevState);
    }
  };

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      if (toggle) {
        applyProxy();
      } else {
        removeProxy();
      }
    } else {
      preventConfigUpdate.current = false;
    }
  }, [toggle]);

  const handleToggle = async () => {
    setToggle(!toggle);
  };

  const navigate = useNavigate();

  const MENUITEM = [
    {
      name: "Dashboard",
      svg: DashboardSvg,
      component: Dashboard,
      path: "dashboard",
    },
    {
      name: "Manage Review",
      svg: ManageReviewSvg,
      component: ManageReview,
      path: "manage-review",
    },
    {
      name: "Display Widgets",
      svg: DisplayWidgetsSvg,
      component: DisplayWidgets,
      path: "display-widgets",
    },
    {
      name: "Manage Emails",
      svg: ManageEmailsSvg,
      component: ManageEmails,
      path: "manage-emails",
    },
    {
      name: "Smart Controls",
      svg: SmartControlsSvg,
      component: SmartControls,
      path: "smart-controls",
    },
    {
      name: "Rewards",
      svg: RewardsSvg,
      component: Rewards,
      path: "rewards",
    },
    // {
    //   name: "Integration",
    //   svg: IntegrationSvg,
    //   component: Integration,
    //   path: "integration",
    // },
  ];

  const getSvg = (index: number) => {
    const SvgComponent = MENUITEM[index].svg;
    return <SvgComponent />;
  };

  const handleMenuChange = (path: string, index: number) => {
    setActive(index);
    navigate(path);
  };

  const handleHelpClick = () => {
    window.open(
      "https://platform.fynd.com/help/docs/extensions/available-extensions/rating-and-review",
      "_blank"
    );
  };

  return (
    <>
      <div className={`${CSS["layout-container"]} position-rel`}>
        <div className={CSS["feature-menu"]}>
          {MENUITEM.map((item, index) => (
            <div
              className={classNames(CSS.menuItem, {
                [CSS.active]: isActive === index,
              })}
              key={item.name}
              onClick={() => handleMenuChange(item.path, index)}
            >
              <div className={CSS["svg-container"]}>
                <div className={CSS["circle"]}>{getSvg(index)}</div>
              </div>
              <div className={CSS["menu-title"]}>{item.name}</div>
            </div>
          ))}
        </div>
        <div className={CSS["feature-wrapper"]}>
          {/* This can be removed as it is not present on all pages */}
          {/* <SectionHeader
          id="1"
          heading="Product Detail Reviews"
          description="Select your design layout and customize the settings as per your needs"
          value={sectionToggle}
          onToggle={() => setSectionToggle(!sectionToggle)}
          onBackClick={useCallback(() => navigate(-1), [])}
        /> */}
          <div className={CSS["feature-component"]}>
            <div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <div className="active-inactive-toggle">
        {/* <ButtonAtom
          label="Help"
          onClick={handleHelpClick}
          theme="tertiary"
          size="small"
        /> */}
        <div className={CSS.help_text} onClick={handleHelpClick}>
          Help
        </div>
        <div className={CSS.toggle_box}>
          <Tooltip
            tooltipContent="Enable/Disable Review & Rating extension from Application"
            position="left"
          />
          <ToggleAtom
            id="proxy-toggle"
            value={toggle ?? false}
            onToggle={handleToggle}
          />
        </div>
      </div>
    </>
  );
};

export default Layout;
