import React, { memo } from "react";
import { Radio } from "@gofynd/nitrozen-react";
import CSS from "./radio.atom.module.scss";

interface RadioAtomProps {
  labelText: string;
  name: string;
  id?: string;
  onChange: Function;
  value: string;
  radioValue: string;
  className?: string;
}

function RadioInput({
  id,
  onChange,
  value,
  name,
  labelText,
  radioValue,
  className = ''
}: RadioAtomProps): JSX.Element {
  return (
    <>
      <div className={CSS["radio-atom"]}>
        <Radio
          labelText={labelText}
          name={name}
          onChange={onChange}
          radioValue={radioValue}
          value={value}
          id={id || 'radio_' + labelText}
          className={className}
        />
      </div>
    </>
  );
}

const SelectAtom = memo(RadioInput);
export default SelectAtom;
