import { memo } from "react";
import TextAtom from "../../../../../../../../components/atoms/text/text";
import ImageContainerAtom from "../../atoms/image-container/image.container.atom";
import DefaultImage from "../../../../../../../../assets/default_icon.png";
import CSS from "./question.container.module.scss";

interface QuestionContainerProps {
  id?: string;
  src: string;
  questionText: string;
}

const QuestionContainer: React.FunctionComponent<QuestionContainerProps> = ({
  id = "",
  src = DefaultImage,
  questionText = "",
}) => {
  return (
    <div id={"data-testid-product-container" + id} className={CSS.cs_dis_flex}>
      <div className={`${CSS.cs_center}`}>
        <ImageContainerAtom src={src} id={id} />
      </div>
      <div className={CSS.cs_p_20}>
        <TextAtom
          fontWeight={600}
          text={questionText}
          type="heading"
          ellipsis={true}
          lineHeight={15.6}
        />
      </div>
    </div>
  );
};

export default memo(QuestionContainer);
