export const DefaultTableData = [
  {
    key: "1",
    rowData: [
      {
        value: "abcd",
      },
      {
        value: ["a", "b"],
      },
    ],
  },
  {
    key: "2",
    rowData: [
      {
        value: "xyz",
      },
      {
        value: ["q", "ans"],
      },
    ],
  },
  {
    key: "3",
    rowData: [
      {
        value: "dknbdckla",
      },
      {
        value: ["asndn", "enaen"],
      },
    ],
  },
];
