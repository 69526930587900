export const list = [
  {
    name: "Total Reviews Received",
    value: 396,
    percentage: 57,
    is_increased: true,
  },
  {
    name: "Total Coupon Send",
    value: 396,
    percentage: 37,
    is_increased: false,
  },
  {
    name: "Total Coupon Applied",
    value: 396,
    percentage: 78,
    is_increased: true,
  },
  {
    name: "Total Review Email Send",
    value: 396,
    percentage: 38,
    is_increased: false,
  },
];
//["#2e31be", "#6162d2", "#a0a1e3","#cccff0","#e3e3fd"]
export const barInfo = {
  average: [
    {
      label: "5 Star",
      percentage: 67,
      color: "#2e31be",
      value: 21,
      is_increased: false,
    },
    {
      label: "4 Star",
      percentage: 37,
      color: "#6162d2",
      value: 21,
      is_increased: true,
    },
    {
      label: "3 Star",
      percentage: 27,
      color: "#a0a1e3",
      value: 21,
      is_increased: true,
    },
    {
      label: "2 Star",
      percentage: 17,
      color: "#cccff0",
      value: 21,
      is_increased: true,
    },
    {
      label: "1 Star",
      percentage: 7,
      color: "#e3e3fd",
      value: 21,
      is_increased: false,
    },
  ],
  sentimental: [
    {
      label: "Happy",
      percentage: 67,
      color: "#2e31be",
      value: 71,
      is_increased: true,
    },
    {
      label: "Neutral",
      percentage: 27,
      color: "#6162d2",
      value: 21,
      is_increased: true,
    },
    {
      label: "Sad",
      percentage: 6,
      color: "#a0a1e3",
      value: 9,
      is_increased: true,
    },
  ],
  nps: [
    {
      label: "Promotors",
      percentage: 50,
      color: "#2e31be",
      value: 50,
      is_increased: false,
    },
    {
      label: "Passivest",
      percentage: 30,
      color: "#6162d2",
      value: 30,
      is_increased: true,
    },
    {
      label: "Detractors",
      percentage: 20,
      color: "#a0a1e3",
      value: 20,
      is_increased: true,
    },
  ],
};
export const headers = ["Product", "Last Reviewed Date"];

export const tableData = [
  {
    title: "Sample heading goes here....",
    date: "30 Nov 2022 03:09",
    review_count: 342,
    rating: 5,
  },
  {
    title: "Sample heading goes here....",
    date: "30 Nov 2022 03:09",
    review_count: 992,
    rating: 4,
  },
  {
    title: "Sample heading goes here....",
    date: "30 Jan 2022 03:09",
    review_count: 42,
    rating: 1,
  },
  {
    title: "Sample heading goes here....",
    date: "30 Jan 2022 03:09",
    review_count: 42,
    rating: 2,
  },
];
