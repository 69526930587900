import ProductContainerOrganism from "../organism/product-container/product.container.organism";
import dropdownIc from "../assets/icons/dropdownIC.png";
import CSS from "../table.module.scss";
import RatingContainerOrganism from "../organism/rating-container/rating.container.organism";
import BadgeAtom from "../../../../../../../components/atoms/badge/badge.atom";
import ActionContainerOrganism from "../organism/action-container/action.container.organism";

const computeState = (value: string) => {
  switch (value) {
    case "PUBLISHED":
      return "success";
    case "REJECTED":
      return "error";
    case "unpublished":
      return "warning";
    default:
      return "success";
  }
};

export const tableColumnData = (
  reviewDetailView: any,
  onLikeButtonClick: (id: string, isFavourite: boolean) => void
) => [
  {
    field: "review",
    width: 350,
    headerComponent: () => <div style={{ display: "flex" }}>Product</div>,
    // wrapText: true,
    autoHeight: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: (content: any) => {
      return (
        <ProductContainerOrganism
          productName={
            content.data?.product_details?.name ??
            content.data?.product_details?.title
          }
          src={content.data?.product_details?.media?.[0]?.url}
          id="main-table-vew"
          productBrief={
            content.data?.product_details?.brandName ??
            content.data?.product_details?.brand
          }
        />
      );
    },
  },
  {
    field: "Rating",
    width: 250,
    wrapText: true,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
      height: "auto",
      padding: "10px",
    },
    cellRenderer: (content: any) => {
      return (
        <RatingContainerOrganism
          maxTextLength={60}
          id="main-table-rating"
          reviewDescription={
            content?.data?.is_abusive ?? false
              ? content.data?.masked_review?.description
              : content.data?.review?.description
          }
          currentRating={content.data.rating.value}
        />
      );
    },
  },
  {
    field: "review date",
    width: 200,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
    },
    cellRenderer: (content: any) => {
      const formattedDate = new Date(content.data.created_at).toLocaleString(
        "en-US",
        {
          day: "2-digit",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }
      );
      return <div>{formattedDate.replace(/,/g, "")}</div>;
    },
  },
  {
    field: "review status",
    width: 150,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
    },
    cellRenderer: (content: any) => {
      return (
        <>
          {content.data.approval.status === "published" && (
            <BadgeAtom label="PUBLISHED" size="small" />
          )}
          {content.data.approval.status === "unpublished" && (
            <BadgeAtom label="UNPUBLISHED" size="small" state="disable" />
          )}
          {content.data.approval.status === "rejected" && (
            <BadgeAtom label="REJECTED" size="small" state="error" />
          )}
          {content.data.approval.status === "reported" && (
            <BadgeAtom label="REPORTED" size="small" state="error" />
          )}
        </>
      );
    },
  },
  {
    field: "Actions",
    width: 100,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
    },
    cellRenderer: (content: any) => {
      return (
        <ActionContainerOrganism
          reviewDetailView={() => reviewDetailView(content.data.id)}
          onLikeButtonClick={() =>
            onLikeButtonClick(content.data.id, content.data?.favourite)
          }
          buttonDisable={content.data.approval.status === "REJECTED"}
          isFavourite={content?.data?.favourite ?? false}
        />
      );
    },
  },
];
