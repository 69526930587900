import React, { memo } from "react";
import { SvgIcArrowBack } from "@gofynd/nitrozen-react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import TextAtom from "../../atoms/text/text";
import CSS from "./section-header.module.scss";
import ToggleAtom from "../../atoms/toggle/toggle";
import { useSubscriptionContext } from "../../../context/subscription-context";

interface SectionHeadingWithDescProps {
  id: string;
  heading: string;
  description: string;
  value: boolean;
  onToggle: Function;
  onBackClick: Function;
  isPro: boolean;
  hasBackButton?: boolean;
}

function SectionHeadingWithDesc({
  id,
  heading,
  description,
  value,
  onToggle,
  onBackClick,
  isPro,
  hasBackButton = true,
}: SectionHeadingWithDescProps): JSX.Element {
  const { subscription } = useSubscriptionContext();
  const navigate = useNavigate();

  const onUpgradeClick = () => {
    navigate("/payment");
  };

  return (
    <div className={CSS["section-box"]}>
      <div>
        <div className={classNames("cs-mb-8", CSS["section-heading-box"])}>
          {hasBackButton && (
            <div className={CSS["svg-box"]} onClick={() => onBackClick()}>
              <SvgIcArrowBack size={20} color="#261A1A" />
            </div>
          )}
          <TextAtom text={heading} id={id} type="section-heading" />
        </div>
        <TextAtom text={description} id={id} type="section-description" />
      </div>
      {subscription?.plan_type === "basic" && isPro ? (
        <div>
          <button className={CSS.upgrade} onClick={onUpgradeClick}>
            Upgrade to PRO
          </button>
        </div>
      ) : (
        <div className={CSS["section-heading-box"]}>
          <div className={CSS["toggle-label"]}>
            {value ? "Enabled" : "Disabled"}
          </div>
          <ToggleAtom id={id} value={value} onToggle={onToggle} />
        </div>
      )}
    </div>
  );
}

const SectionHeadingWithDescBox = memo(SectionHeadingWithDesc);
export default SectionHeadingWithDescBox;
