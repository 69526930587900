import React, { useMemo, useReducer, useState } from "react";
import Table from "./molecules/table/table.molecule";
import { IRowData, ITableData } from "./molecules/table/table.molecule.types";
import { produce } from "immer";
import { DefaultTableData } from "./molecules/table/table.constants";
import StarAtom from "../../components/atoms/star/star.atom";
import InputWithTagsAtom from "../../components/atoms/input-with-tags/input-with-tags.atom";

const Integration = () => {
  const [tableData, setTableData] = useState<ITableData[]>(DefaultTableData);
  const [tags, setTags] = useState<string[]>();

  const handleChange = (data: {
    action: string;
    rowKey: string | number;
    rowData: IRowData[];
  }) => {
    switch (data.action) {
      case "CHANGE":
        setTableData((currentState: ITableData[]) => {
          return produce(currentState, (draft: ITableData[]) => {
            draft?.forEach((tableData: ITableData) => {
              if (tableData?.key === data.rowKey) {
                tableData.rowData = data.rowData;
              }
            });
          });
        });
        break;
      case "DELETE":
        setTableData((currentState: ITableData[]) =>
          produce(currentState, (draft: ITableData[]) => {
            const index = draft.findIndex((el) => el.key === data.rowKey);
            draft.splice(index, 1);
          })
        );
        break;
    }
  };

  return (
    <div>
      <h4>Display Widgets</h4>
      <div className="cs-bm-10">
        <StarAtom hoverEffect clickEffect defaultRating={2} />
      </div>
      <div className="cs-bm-10">
        <InputWithTagsAtom
          tags={tags}
          onChange={(tags) => setTags(tags)}
          placeholder="Add Tag"
        />
      </div>
      <div>
        <Table
          headers={["Feature Category", "Feature Tags"]}
          tableData={tableData}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Integration;
