import StickyNoteIcon from "../../assets/sticky-note-icon.svg";
import HelpIcon from "../../assets/help-icon.svg";
import CarouselIcon from "../../assets/view-carousel-icon.svg";
import FormIcon from "../../assets/form-icon.svg";
import CartIcon from "../../assets/cart-icon.svg";
import ReviewIcon from "../../assets/review-icon.svg";

export const defaultDisplayWidgets = [
  {
    name: "Product Detail Reviews",
    type: "product_review",
    description:
      "Enable and customise widget to showcase genuine product reviews on your product detail page.",
    is_active: true,
    path: "/features-page/display-widgets/product-detail",
    icon: StickyNoteIcon,
    isPro: false,
  },
  {
    name: "Questions and Answers",
    type: "questions_answers",
    description:
      "Enable and customise widget to manage user-generated questions and provide expert answers.",
    is_active: true,
    path: "/features-page/display-widgets/question-answer",
    icon: HelpIcon,
    isPro: false,
  },
  {
    name: "Review Home Carousel",
    type: "home_carousel",
    description:
      "Enable and customise widget to display your favourite product reviews on your home page.",
    is_active: true,
    path: "/features-page/display-widgets/review-home-carousel",
    icon: CarouselIcon,
    isPro: true,
  },
  {
    name: "Review Form",
    type: "review_form",
    description:
      "Enable and customise widget to gather valuable product reviews via form on your product detail page.",
    is_active: true,
    path: "/features-page/display-widgets/review-form",
    icon: FormIcon,
    isPro: false,
  },
  {
    name: "Floating Review",
    type: "floating_review",
    description:
      "Enable and customise widget to display consolidated product reviews under one tab.",
    is_active: true,
    path: "/features-page/display-widgets/floating-review",
    icon: CartIcon,
    isPro: true,
  },
  {
    name: "Application Reviews",
    type: "application_review",
    description:
      "Enable and customise widget to gather user experience feedback once order is placed. This powers the NPS score of your store.",
    is_active: true,
    path: "/features-page/display-widgets/application-reviews",
    icon: ReviewIcon,
    isPro: true,
  },
];
