import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfigContext } from "../../context/config-context";

import FieldInputBox from "../../components/molecules/field-control/input-box/input-box.molecules";
import RightPanel from "../../components/templates/right-panel/right-panel.template";
import BorderAtom from "../../components/atoms/border/border.component";
import TextAtom from "../../components/atoms/text/text";
import Collapsible from "../../components/molecules/collapsible/collapsible.molecule";
import ButtonAtom from "../../components/atoms/button/button.atom";
import SectionHeadingWithDescBox from "../../components/molecules/section-heading-description/section-header";
import RadioBlockButtonsMolecule from "./molecules/radio-block-buttons/radio-block-buttons.molecule";

import {
  couponDiscountOptions,
  defaultRewardConfig,
  rewardConditionOneOptions,
  rewardConditionTwoOptions,
} from "./config/reward.constants";

import { getRewards, updateRewards } from "../../services/reward.service";

import CSS from "./index.module.scss";
import {
  showDangerToast,
  showSuccessToast,
  TOAST_MESSAGES,
} from "../../services/toast.service";
import useDidMountEffect from "../../hooks/useDidMountEffect";

export interface IConditionConfig {
  only_buyer_reviewed: Boolean;
  review_with: string;
}

export interface IRewardConfig {
  generated_coupon_prefix: string;
  discount_type: string;
  discount_value: number;
  minimum_order_value_requirement: number;
  max_discount_amount: number;
  coupon_validity_in_days: number;
  conditions: IConditionConfig;
  is_active: boolean;
}
export interface IRewardPostConfig {
  generated_coupon_prefix: string;
  discount_type: string;
  discount_value: number;
  minimum_order_value_requirement: number;
  max_discount_amount: number;
  coupon_validity_in_days: number;
  is_active: boolean;
  review_with: string;
}

const Rewards = () => {
  const [rewardsConfig, setRewardsConfig] =
    useState<IRewardConfig>(defaultRewardConfig);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [prefixError, setPrefixError] = useState<boolean>(false);
  const [amountError, setAmountError] = useState<boolean>(false);
  const [discountError, setDiscountError] = useState<boolean>(false);
  const { setConfig } = useConfigContext();
  const navigate = useNavigate();
  const preventConfigUpdate = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await getRewards();
        if (res?.data?.data) {
          setRewardsConfig(res?.data?.data);

          if (!res?.data?.data?.is_active) {
            preventConfigUpdate.current = true;
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (rewardsConfig?.generated_coupon_prefix?.length === 0) {
      setPrefixError(true);
    } else {
      setPrefixError(false);
    }

    if (
      rewardsConfig?.max_discount_amount === 0 &&
      rewardsConfig?.discount_type === "percentage"
    ) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }

    if (rewardsConfig?.discount_value === 0) {
      setDiscountError(true);
    } else {
      setDiscountError(false);
    }
  }, [rewardsConfig]);

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      (async () => {
        try {
          const couponObj: IRewardPostConfig = {
            generated_coupon_prefix: rewardsConfig.generated_coupon_prefix,
            discount_value: rewardsConfig.discount_value,
            discount_type: rewardsConfig.discount_type,
            max_discount_amount: rewardsConfig.max_discount_amount,
            minimum_order_value_requirement:
              rewardsConfig.minimum_order_value_requirement,
            review_with: rewardsConfig.conditions.review_with,
            coupon_validity_in_days: rewardsConfig.coupon_validity_in_days,
            is_active: rewardsConfig.is_active,
          };
          const res = await updateRewards(couponObj);

          if (res?.data) {
            showSuccessToast(
              res?.data?.data?.is_active
                ? TOAST_MESSAGES.configEnabled
                : TOAST_MESSAGES.configDisabled
            );
          }
        } catch (err) {
          console.log(err);
          showDangerToast(
            rewardsConfig?.is_active
              ? TOAST_MESSAGES.configEnabledError
              : TOAST_MESSAGES.configDisabledError
          );
          handleConfigChange("is_active", !rewardsConfig?.is_active);
          preventConfigUpdate.current = true;
        }
      })();
    } else {
      preventConfigUpdate.current = false;
    }
  }, [rewardsConfig?.is_active]);

  const handleConfigChange = (
    configKey: string,
    value: string | boolean | number
  ) => {
    setRewardsConfig((prevState) => {
      return { ...prevState, [configKey]: value };
    });
  };

  const handleRewardConfigChange = (
    configKey: string,
    value: string | boolean | number
  ) => {
    setRewardsConfig((prevState) => {
      return {
        ...prevState,
        conditions: { ...prevState.conditions, [configKey]: value },
      };
    });
  };

  useEffect(() => {
    const {
      generated_coupon_prefix,
      discount_type,
      max_discount_amount,
      minimum_order_value_requirement,
      discount_value,
    } = rewardsConfig;
    if (
      discount_type === "percentage" &&
      (discount_value <= 0 || discount_value > 100 || isNaN(discount_value))
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }

    const storefrontObj = {
      code: generated_coupon_prefix,
      type_slug:
        discount_type === "percentage"
          ? "percentage_quantity_percentage"
          : "absolute_quantity_absolute",
      rule: [
        {
          max: max_discount_amount,
          min: minimum_order_value_requirement,
          value: discount_value,
          key: 1,
        },
      ],
    };

    setConfig((prevData: any) => ({
      ...prevData,
      rewards: storefrontObj,
      type: "REWARDS",
    }));
  }, [rewardsConfig]);

  const handleSaveClick = useCallback(
    async (event: any) => {
      event.stopPropagation();
      try {
        const couponObj: IRewardPostConfig = {
          generated_coupon_prefix: rewardsConfig.generated_coupon_prefix,
          discount_value: rewardsConfig.discount_value,
          discount_type: rewardsConfig.discount_type,
          max_discount_amount: rewardsConfig.max_discount_amount,
          minimum_order_value_requirement:
            rewardsConfig.minimum_order_value_requirement,
          review_with: rewardsConfig.conditions.review_with,
          coupon_validity_in_days: rewardsConfig.coupon_validity_in_days,
          is_active: rewardsConfig.is_active,
        };
        const res = await updateRewards(couponObj);

        if (res?.data) {
          showSuccessToast(TOAST_MESSAGES.configUpdated);
        }
      } catch (err) {
        console.log(err);
        showDangerToast(TOAST_MESSAGES.configUpdateError);
      }
    },
    [rewardsConfig]
  );

  const CouponDiscount = () => {
    return (
      <>
        <div className="cs-bm-24">
          <RadioBlockButtonsMolecule
            radioOptions={couponDiscountOptions}
            selectedValue={rewardsConfig?.discount_type}
            handleClick={(key) => handleConfigChange("discount_type", key)}
          />
          <div className={`${CSS.cs_grid_of_two} ${CSS.cs_column_gap_16}`}>
            <FieldInputBox
              onChange={(text) => {
                handleConfigChange("discount_value", Number(text));
              }}
              placeholder={
                rewardsConfig?.discount_type === "percentage"
                  ? "Enter discount percentage"
                  : "Enter discount amount"
              }
              value={String(rewardsConfig?.discount_value ?? 0)}
              id="field-control"
              label={
                rewardsConfig?.discount_type === "percentage"
                  ? "Discount Percentage"
                  : "Discount Amount"
              }
              className={!isValid ? "input-error" : ""}
            />
            {rewardsConfig?.discount_type === "percentage" && (
              <>
                <FieldInputBox
                  onChange={(text) =>
                    handleConfigChange("max_discount_amount", Number(text))
                  }
                  placeholder="Enter max. discount amount value"
                  value={String(rewardsConfig?.max_discount_amount ?? 0)}
                  id="field-control"
                  label="Max. Discount Amount"
                />
              </>
            )}
          </div>
          <div className={`${CSS.cs_grid_of_two} ${CSS.cs_column_gap_16}`}>
            {discountError && (
              <div className={CSS.error_text}>
                {rewardsConfig?.discount_type === "percentage"
                  ? "Discount Percentage cannot be zero"
                  : "Discount Amount cannot be zero"}
              </div>
            )}
            {amountError && (
              <div className={CSS.error_text}>
                Max Discount Amount cannot be zero
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const MinimumRequirement = () => {
    return (
      <div className="cs-bm-24">
        <FieldInputBox
          onChange={(text) =>
            handleConfigChange("minimum_order_value_requirement", Number(text))
          }
          placeholder="Enter required amount"
          value={String(rewardsConfig?.minimum_order_value_requirement ?? 0)}
          id="field-control"
          label="Required Amount"
        />
      </div>
    );
  };

  const CouponValidity = () => {
    return (
      <div className="cs-bm-24">
        <FieldInputBox
          onChange={(text) =>
            handleConfigChange("coupon_validity_in_days", Number(text))
          }
          placeholder="Enter days"
          value={String(rewardsConfig?.coupon_validity_in_days ?? 7)}
          id="field-control"
          label="How Many Days"
        />
      </div>
    );
  };

  const RewardCondition = () => {
    return (
      <div>
        <div className="cs-bm-16">
          <TextAtom
            id="4"
            fontWeight={400}
            text="Reward Conditions: Only buyers of the reviewed product will receive a reward."
            type="title"
          />
        </div>
        <RadioBlockButtonsMolecule
          radioOptions={rewardConditionOneOptions}
          selectedValue={
            rewardsConfig?.conditions?.only_buyer_reviewed ? "buyers_only" : ""
          }
        />
        <div className="cs-bm-16">
          <TextAtom
            id="4"
            fontWeight={400}
            text="Review Conditions: Select condition for which customer will be rewarded for a product review."
            type="title"
          />
        </div>
        <RadioBlockButtonsMolecule
          radioOptions={rewardConditionTwoOptions}
          selectedValue={rewardsConfig?.conditions?.review_with}
          handleClick={(key) => handleRewardConfigChange("review_with", key)}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="cs-bm-24">
        <SectionHeadingWithDescBox
          id="1"
          heading="Rewards"
          description="Select your design layout and customise the settings as per your needs"
          value={rewardsConfig?.is_active}
          onToggle={() =>
            handleConfigChange("is_active", !rewardsConfig?.is_active)
          }
          onBackClick={useCallback(() => navigate(-1), [])}
          isPro={true}
          hasBackButton={false}
        />
      </div>

      <div className={CSS["cs-settings"]}>
        <div className={CSS["cs-settings-left"]}>
          <div className="cs-bm-16">
            <TextAtom
              fontWeight={600}
              id="text-heading"
              text="Settings"
              type="header"
            />
          </div>
          <div className="cs-bm-10">
            <FieldInputBox
              onChange={(text) =>
                handleConfigChange("generated_coupon_prefix", text)
              }
              placeholder="Enter your coupon name"
              value={rewardsConfig?.generated_coupon_prefix ?? ""}
              id="field-control"
              label="Set Coupon Prefix"
              maxLength={10}
            />
            {prefixError && (
              <span className={CSS.error_text}>Please enter a prefix</span>
            )}
          </div>
          <div className="cs-tm-24">
            <BorderAtom borderColor="#ededed" borderSize="0.5px" />
          </div>
          <div>
            <>
              <Collapsible
                subtitle={
                  "The review generated coupon can be used to get a discount on future orders."
                }
                subTitleType="label"
                className="cs-bp-15 cs-tp-15"
                type={"heading"}
                title={"Coupon Discount"}
                titleClassName={CSS["cs-vt-center"]}
              >
                {CouponDiscount()}
              </Collapsible>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </>
            <>
              <Collapsible
                subtitle={"The review coupon is only valid for orders of above"}
                subTitleType="label"
                className="cs-bp-15 cs-tp-15"
                type={"heading"}
                title={"Minimum Requirement"}
                titleClassName={CSS["cs-vt-center"]}
              >
                {MinimumRequirement()}
              </Collapsible>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </>
            <>
              <Collapsible
                subtitle={"The review coupon can only be used for"}
                subTitleType="label"
                className="cs-bp-15 cs-tp-15"
                type={"heading"}
                title={"Coupon Validity"}
                titleClassName={CSS["cs-vt-center"]}
              >
                {CouponValidity()}
              </Collapsible>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </>
            <>
              <Collapsible
                subtitle={
                  "Configure below condition to generate reward for a product review."
                }
                subTitleType="label"
                className="cs-bp-15 cs-tp-15"
                type={"heading"}
                title={"Reward Conditions"}
                titleClassName={CSS["cs-vt-center"]}
              >
                {RewardCondition()}
              </Collapsible>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </>
          </div>
          <div className={CSS["cs-save-btn"]}>
            <ButtonAtom
              label="Save"
              onClick={handleSaveClick}
              disable={
                !isValid ||
                prefixError ||
                (amountError &&
                  rewardsConfig?.discount_type === "percentage") ||
                discountError
              }
            />
          </div>
        </div>
        <RightPanel />
      </div>
    </div>
  );
};

export default Rewards;
