import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import TextAtom from "../../../../components/atoms/text/text";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import RightPanel from "../../../../components/templates/right-panel/right-panel.template";
import SectionHeadingWithDescBox from "../../../../components/molecules/section-heading-description/section-header";
import ButtonAtom from "../../../../components/atoms/button/button.atom";
import SenderEmailContainer from "../../molecules/sender-email/sender-email.molecule";

import {
  defaultApplicationReviewConfig,
  defaultProviderOptions,
} from "./config/application-review.constants";

import CSS from "./application-review.module.scss";
import FieldSelect from "../../../../components/molecules/field-control/select/select.molecule";
import SideDrawer from "../../../../components/templates/side-drawer/side-drawer.template";
import CodeEditor from "../../../../components/molecules/code-editor/code-editor.molecule";
import EmailTemplate from "../email-template/email-template.component";
import {
  submitReviewEmailTemplate,
  defaultSubmitReviewEmailValues,
} from "../email-template/email-template.constants";

const ApplicationReview = () => {
  const [applicationReviewConfig, setApplicationReviewConfig] = useState(
    defaultApplicationReviewConfig
  );
  const [showOrderDeliveredEmailEditor, setShowOrderDeliveredEmailEditor] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const handleConfigChange = (
    configKey: string,
    value: string | boolean | string[]
  ) => {
    setApplicationReviewConfig((prevState) => {
      return { ...prevState, [configKey]: value };
    });
  };

  const onSaveClick = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();
  };

  const onEditEmailClick = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();
    setShowOrderDeliveredEmailEditor(true);
  };

  const onSendEmailClick = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();
  };

  const onApplicationReviewToggle = (value: boolean) => {
    // add or remove Q & A from the main config
  };

  const handleSaveOrderEmailClick = () => { };

  return (
    <>
      <div>
        <div className="cs-bm-24">
          <SectionHeadingWithDescBox
            id="1"
            heading="Application Review"
            description="Customise the settings as per your needs"
            value={true}
            onToggle={onApplicationReviewToggle}
            onBackClick={useCallback(() => navigate(-1), [])}
            isPro={true}
          />
        </div>

        <div
          className={`${CSS["cs-grid-container"]} ${CSS["cs-template-30-70"]} ${CSS["cs-column-gap-25"]}`}
        >
          <form>
            <div className="cs-bm-12">
              <TextAtom
                fontWeight={600}
                id="text-heading"
                text="Settings"
                type="header"
              />
            </div>
            <div className="cs-bm-14">
              <SenderEmailContainer
                name="Vivek from Clothio Stores"
                email="devanshishah@gofynd.com"
                label="Sender"
              />
            </div>
            <div className="cs-bm-14">
              <FieldSelect
                items={defaultProviderOptions}
                onChange={(value) => handleConfigChange("provider", value)}
                value={applicationReviewConfig?.provider}
                placeholder="Select Provider"
                id="provider"
                label="Choose Default Provider"
              />
            </div>
            <div className="cs-bm-14">
              <FieldInputBox
                placeholder="Enter Subject Text"
                id="subject"
                value={applicationReviewConfig?.subject}
                onChange={(text) => handleConfigChange("subject", text)}
                label="Subject"
                maxLength={100}
                textLength={applicationReviewConfig?.subject?.length}
                textLimit={100}
              />
            </div>
            <div className="cs-bm-16">
              <FieldInputBox
                placeholder="Enter Preheader Text"
                id="preheader-text"
                value={applicationReviewConfig?.preheader_text}
                onChange={(text) => handleConfigChange("preheader_text", text)}
                label="Preheader Text"
                maxLength={50}
                textLength={applicationReviewConfig?.preheader_text?.length}
                textLimit={50}
              />
            </div>
            <div className={`${CSS["cs-row-flex"]} ${CSS["cs-justify-row"]} `}>
              <ButtonAtom
                id="send-test-email"
                label="Send Test Email"
                onClick={onSendEmailClick}
                theme={"secondary"}
                borderColor="#7D7676"
                backgroundColor="#fff"
                color="#7D7676"
                padding="0px 12px"
              />
              <ButtonAtom
                id="edit-test-email"
                label="Edit Test Email"
                onClick={onEditEmailClick}
                theme={"secondary"}
                borderColor="#7D7676"
                backgroundColor="#fff"
                color="#7D7676"
                padding="0px 12px"
              />
            </div>
            <div className="cs-tm-26">
              <ButtonAtom label="Save" onClick={onSaveClick} />
            </div>
          </form>
          <div>
            <RightPanel
              Component={
                <EmailTemplate
                  emailTemplate={submitReviewEmailTemplate}
                  emailValues={defaultSubmitReviewEmailValues}
                />
              }
            />
          </div>
        </div>
      </div>
      {showOrderDeliveredEmailEditor && (
        <SideDrawer
          showDrawer={showOrderDeliveredEmailEditor}
          setShowDrawer={() => {
            setShowOrderDeliveredEmailEditor(false);
          }}
          heading={"Edit Order Delivered Email"}
          component={
            <>
              <CodeEditor
                content={applicationReviewConfig?.order_delivered_email_content}
                handleCancelClick={() => {
                  setShowOrderDeliveredEmailEditor(false);
                }}
                defaultValue={""}
                handleSaveClick={handleSaveOrderEmailClick}
              />
            </>
          }
        />
      )}
    </>
  );
};

export default ApplicationReview;
