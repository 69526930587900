export const setNestedProperty = (
  obj: any,
  path: string,
  value: string | boolean | number | string[]
): any => {
  const [head, ...rest] = path.split(".");

  return {
    ...obj,
    [head]: rest.length
      ? setNestedProperty(obj[head], rest.join("."), value)
      : value,
  };
};
