import React, { memo } from "react";
import CSS from "./text.module.scss";

interface BorderProps {
  height?: string;
  borderSize?: string;
  borderColor?: string;
  className?: string;
}

function Border({
  height = "1px",
  borderSize = "1px",
  borderColor = "#E0E0E0",
  className = "",
}: BorderProps): JSX.Element {
  return (
    <>
      <div
        className={className}
        style={{
          height: `${borderSize}`,
          width: "100%",
          backgroundColor: `${borderColor}`,
        }}
      ></div>
    </>
  );
}

const BorderAtom = memo(Border);
export default BorderAtom;
