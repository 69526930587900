import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { produce } from "immer";

import NoData from "../../components/no-data/no-data";
import FilterBox from "./components/filter-box";
import TableGrid from "./components/table-grid";
import { rowPerPageLimit } from "./config/product-reviews.constants";
import {
  getProductReview,
  setReviewFavourite,
  setReviewUnfavourite,
} from "./service/product.reviews.service";

interface IPaginationData {
  pageList: any[];
  currentPage: number;
  pageSize: number;
  itemTotal: number;
}

interface IFilterData {
  search: string;
  rating: number | undefined;
  status: string | undefined;
  isFiltered: boolean;
}

const ProductReviews = () => {
  const [tableData, setTableData] = useState<{ [key: string]: any[] }>({});
  const [paginationPageData, setPaginationPageData] = useState<IPaginationData>(
    {
      pageList: rowPerPageLimit,
      currentPage: 1,
      pageSize: 10,
      itemTotal: 0,
    }
  );
  const [filterData, setFilterData] = useState<IFilterData>({
    search: "",
    rating: undefined,
    status: undefined,
    isFiltered: false,
  });
  const [initialCall, setInitialCall] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getTableData();
  }, [paginationPageData.currentPage, paginationPageData.pageSize, filterData]);
  
  useEffect(() => {
    setInitialCall(true);
  }, []);
  const getTableData = async () => {
    try {
      let payload = {
        ...paginationPageData,
        ...filterData,
      };
      const params = {
        page_size: payload.pageSize,
        sort_by: "desc_date",
        page_no: payload.currentPage,
        search: payload.search,
        rating: payload.rating,
        status: payload.status,
      };
      let response = await getProductReview(params);
      setTableData(response.data);

      setPaginationPageData((prevData) => ({
        ...prevData,
        currentPage: +response.data.page.current,
        pageSize: response.data.page.size,
        itemTotal: response.data.page.item_total,
      }));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRowPerPage = (pageSize: string) => {
    setPaginationPageData((prevData) => ({
      ...prevData,
      pageSize: parseInt(pageSize),
    }));
  };

  const handleNextPage = () => {
    let tempCurrent = paginationPageData.currentPage;
    if (
      tempCurrent ===
      Math.ceil(paginationPageData.itemTotal / paginationPageData.pageSize)
    ) {
      return;
    }
    setPaginationPageData((prevData) => ({
      ...prevData,
      currentPage: 1 + tempCurrent,
    }));
  };

  const handleFilterChange = (value: string, key: string) => {
    setFilterData((prev) => ({ ...prev, [key]: value, isFiltered: true }));
  };

  const handlePrevPage = () => {
    let tempCurrent = paginationPageData.currentPage;
    if (tempCurrent === 1) {
      return;
    }
    setPaginationPageData((prevData) => ({
      ...prevData,
      currentPage: tempCurrent - 1,
      pageSize: paginationPageData.pageSize,
    }));
  };

  const reviewDetailView = (id: string) => {
    const productDetails = tableData?.items?.find(
      (item) => item.id === id
    )?.product_details;
    navigate(`product-review/${id}`, { state: productDetails });
  };

  const onLikeButtonClick = async (id: string, isFavourite: boolean) => {
    if (!isFavourite) {
      try {
        const res = await setReviewFavourite(id);
        if (res?.data?.ok) {
          setTableData((prevTableData) => {
            return produce(prevTableData, (draft) => {
              draft?.items?.forEach((tableData) => {
                if (tableData?.id === id) {
                  tableData.favourite = !tableData?.favourite;
                }
              });
            });
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const res = await setReviewUnfavourite(id);
        if (res?.data?.ok) {
          setTableData((prevTableData) => {
            return produce(prevTableData, (draft) => {
              draft?.items?.forEach((tableData) => {
                if (tableData?.id === id) {
                  tableData.favourite = !tableData?.favourite;
                }
              });
            });
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="cs-tm-24">
      {(initialCall && tableData?.items?.length > 0) ||
      filterData.isFiltered ? (
        <FilterBox
          search={filterData.search}
          handleFilterChange={handleFilterChange}
        />
      ) : null}
      {tableData?.items?.length > 0 ? (
        <div className="cs-tm-12">
          <div className="cs-tm-12 cs-vh-100">
            <TableGrid
              reviewDetailView={reviewDetailView}
              tableData={tableData}
              paginationPageData={paginationPageData}
              handleNextPage={handleNextPage}
              handleRowPerPage={handleRowPerPage}
              handlePrevPage={handlePrevPage}
              onLikeButtonClick={onLikeButtonClick}
            />
          </div>
        </div>
      ) : (
        <div>
          <NoData text="No Product Review Found" />
        </div>
      )}
    </div>
  );
};

export default ProductReviews;
