import React, { memo } from "react";

import TextAtom from "../../../../../atoms/text/text";

import CSS from "../../../card.molecule.module.scss";

export interface ICardHeaderProps {
  id: string;
  name: string;
  icon: string;
}

const CardHeader: React.FunctionComponent<ICardHeaderProps> = ({
  id,
  name,
  icon,
}) => {
  return (
    <div data-testid={id}>
      <div className={CSS.card_image}>
        <img src={icon} alt="card-icon" data-testid="card-icon" />
      </div>
      <div className={CSS.card_heading}>
        <TextAtom text={name} type="heading-1" id={"card-name"} />
      </div>
    </div>
  );
};

export default memo(CardHeader);
