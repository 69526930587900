import React, { memo, useState } from "react";
import StarAtom from "../../../../../../../../components/atoms/star/star.atom";
import TextAtom from "../../../../../../../../components/atoms/text/text";

interface RatingContainerProps {
  id?: string;
  clickEffect?: boolean;
  reviewDescription?: string;
  currentRating?: number;
  maxTextLength?: number;
}

const RatingContainerOrganism: React.FunctionComponent<
  RatingContainerProps
> = ({
  id = "",
  clickEffect = false,
  reviewDescription = "",
  currentRating = 0,
  maxTextLength = 40,
}) => {
  const [expand, setExpand] = useState(false);

  return (
    <div id={"data-testid-rating-container" + id}>
      <StarAtom clickEffect={clickEffect} defaultRating={currentRating} />
      <div style={{ wordBreak: "break-word" }}>
        <TextAtom
          text={reviewDescription}
          type="label"
          fontWeight={400}
          ellipsis={true}
          webkitLineClamp={2}
          lineHeight={19.2}
          showReadMore={true}
          expand={expand}
          maxTextLength={maxTextLength}
          onClickReadMore={() => setExpand(!expand)}
        />
      </div>
    </div>
  );
};

export default memo(RatingContainerOrganism);
