import React, { memo } from "react";
import withLabel from "../../../../hoc/label.hoc";
import If from "../../../atoms/if/if.component";
import MultiSelectAtom from "../../../atoms/multi-select/multi-select.atom";
import SelectAtom from "../../../atoms/select/select.atom";

export interface SelectProps {
  label?: string;
  id?: string;
  placeholder: string;
  items: any[];
  mode?: string;
  onChange: (value: string | string[]) => void;
  value?: string | string[];
}

function Select({
  id,
  label,
  placeholder,
  items,
  onChange,
  mode,
  value,
}: SelectProps): JSX.Element {
  return (
    <>
      <If condition={mode !== "multiple" && !Array.isArray(value)}>
        <SelectAtom
          value={value as string}
          placeholder={placeholder}
          id={"input" + id}
          items={items}
          onChange={onChange}
        />
      </If>

      <If condition={mode === "multiple" && Array.isArray(value)}>
        <MultiSelectAtom
          placeholder={placeholder}
          id={"input" + id}
          items={items}
          onChange={onChange}
          value={value as string[]}
        />
      </If>
    </>
  );
}

const FieldSelect = withLabel(memo(Select));
export default FieldSelect;
