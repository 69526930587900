// @ts-check

/**
 *
 */
class MessageBase {
  action: any;
  auth: string;
  /**
   * @param {string} action
   */
  constructor(action: any) {
    this.action = action;
    this.auth = "cGxhdGZvcm0=";
  }
}

export class ConfigMessage extends MessageBase {
  data: any;

  /**
   * @param {{ data: any; }} config
   */
  constructor(config: any) {
    super("configChanged");
    this.data = config;
  }
}
