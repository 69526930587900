import React, { memo, useMemo } from "react";
import SelectAtom from "../../../../../../../../components/atoms/select/select.atom";
import TextAtom from "../../../../../../../../components/atoms/text/text";
import nextPageIC from "./assets/icons/nextPageIC.png";
import prevPageIC from "./assets/icons/prevPageIC.png";
import CSS from "./pagination.module.scss";

interface PaginationProps {
  id?: string;
  handleNextPage?: () => void;
  handleRowPerPage?: (pageSize: string) => void;
  paginationPageData: any;
  handlePrevPage: () => void;
}

const PaginationOrganism: React.FunctionComponent<PaginationProps> = ({
  id = "",
  handleRowPerPage = () => {},
  paginationPageData,
  handleNextPage = () => {},
  handlePrevPage = () => {},
}) => {
  const resultStart = useMemo(() => {
    return (
      paginationPageData.currentPage * paginationPageData.pageSize -
      paginationPageData.pageSize +
      1
    );
  }, [paginationPageData]);

  const resultEnd = useMemo(() => {
    return paginationPageData.currentPage * paginationPageData.pageSize <
      paginationPageData.itemTotal
      ? paginationPageData.currentPage * paginationPageData.pageSize
      : paginationPageData.itemTotal;
  }, [paginationPageData]);

  return (
    <div data-testid={id} className={CSS.cs_pagination_container}>
      <div className={`${CSS.cs_dis_flex} ${CSS.cs_jc_sb}`}>
        <div className={`${CSS.cs_center}`}>
          <TextAtom
            text={`Result ${resultStart}-${resultEnd} of ${paginationPageData.itemTotal} `}
            type="label"
          />
        </div>
        <div>
          <div className={`${CSS.cs_dis_flex}`}>
            <div className={`${CSS.cs_center}`}>
              <TextAtom
                className="cs-rm-10"
                text="Rows per page"
                type="label"
              />
            </div>
            <SelectAtom
              className="cs-rm-10"
              value={paginationPageData.pageList[0].value}
              items={paginationPageData.pageList}
              onChange={handleRowPerPage}
            />
            <div className={`${CSS.cs_center}`}>
              <img
                onClick={handlePrevPage}
                className={`${CSS.page_btn} cs-rm-24`}
                src={prevPageIC}
                alt="Prev page btn"
              />
              <img
                onClick={handleNextPage}
                className={CSS.page_btn}
                src={nextPageIC}
                alt="Next page btn"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PaginationOrganism);
