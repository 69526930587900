import axios from "axios";
import {
  IRewardConfig,
  IRewardPostConfig,
} from "../pages/rewards/rewards.component";
import { getApplicationInfo, getCompanyId } from "../utils";

interface IGetRewardsResponse {
  message: string;
  data: IRewardConfig;
}

interface IUpdateRewardsResponse {
  message: string;
  data: IRewardConfig;
}

export const getRewards = async () => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetRewardsResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/rewards?company_id=${getCompanyId()}`
  );
};

export const updateRewards = async (payload: IRewardPostConfig) => {
  const { applicationId } = getApplicationInfo();

  return axios.patch<IUpdateRewardsResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/rewards?company_id=${getCompanyId()}`,
    payload
  );
};
