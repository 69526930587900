import React, { memo } from "react";
import CSS from "./tags.container.module.scss";

interface ImageContainerProps {
  id?: string;
  tags: string[];
  className?: string;
}

const TagsContainer: React.FunctionComponent<ImageContainerProps> = ({
  id = "",
  tags = [],
  className = "",
}) => {
  return (
    <div
      id={"data-testid-tags-container" + id}
      className={`${CSS.tags_container}` + className}
    >
      {tags?.map((tag) => {
        return <div className={CSS.tag}>{tag}</div>;
      })}
    </div>
  );
};

export default memo(TagsContainer);
