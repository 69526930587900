import axios from "axios";
import axiosInstance from "../../../../../services/axios.service";
import { getApplicationInfo, getCompanyId } from "../../../../../utils";

export interface IProductReviewDetail {
  _id: string;
  vote_count: { upvote: number; downvote: number };
  verified: boolean;
  favourite: boolean;
  product_url: string;
  application: string;
  company: string;
  order_id: string;
  entity: { type: string; id: string };
  approval: {
    status: "published" | "rejected" | "unpublished" | "reported";
    reason: string;
  };
  review: { title: string; description: string };
  rating: { value: number; attributes: string[] };
  is_abusive: boolean;
  masked_review: {
    description: string;
    title: string;
  };
  created_at: string;
  features: string[];
  is_abuse_reportable: boolean;
  is_upvotable: boolean;
  is_downvotable: boolean;
  sentiment: {
    confidence: 0;
    class: string;
  };
}

export interface IProductDetailResponse {
  category_slug: string;
  tags: string[];
  name: string;
  no_of_boxes: number;
  is_image_less_product: boolean;
  is_dependent: boolean;
  description: string;
  item_type: string;
  slug: string;
  uid: number;
  media: [
    {
      url: string;
      type: string;
    }
  ];
  is_active: boolean;
  id: string;
  images: [
    {
      url: string;
      secure_url: string;
    }
  ];
  status: string;
  is_expirable: boolean;
  hsn_code: string;
  review_metric: {
    entity: {
      id: string;
      type: string;
    };
    created_at: string;
    rating_count: number;
    rating_metric: [
      {
        rating: number;
        count: number;
      }
    ];
    rating_sum: number;
    review_count: number;
    updated_at: string;
  };
  brand: {
    name: string;
  };
}

export interface IProductReviewMedia {
  _id: string;
  type: "image" | "video";
  status: string;
  product_id: string;
  url: string;
  id: string;
  is_nsfw: boolean;
}

export interface IProductReviewReply {
  comment: string;
  id: string;
}

export interface IUpdateReviewStatusResponse extends IProductReviewDetail {}

export interface IGetProductReviewDetail extends IProductReviewDetail {}

export interface IGetProductReviewReply {
  items: IProductReviewReply[];
}

export interface IGetProductReviewMedia {
  items: IProductReviewMedia[];
}

export interface IReplyReviewResponse {}

export const getProductDetails = async (productId: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IProductDetailResponse>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/products/?item_ids=${productId}&company_id=${getCompanyId()}`
  );
};

export const getProductReviewDetails = async (reviewId: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetProductReviewDetail>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/review-rating/${reviewId}?company_id=${getCompanyId()}`
  );
};

export const getProductReviewMedia = async (reviewId: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetProductReviewMedia>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/media/entity/review/entity-id/${reviewId}?company_id=${getCompanyId()}`
  );
};

export const updateReviewStatus = async (
  reviewId: string,
  status: "rejected" | "published"
) => {
  const { applicationId } = getApplicationInfo();

  return axios.patch<IUpdateReviewStatusResponse>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/review-rating/${reviewId}?company_id=${getCompanyId()}`,
    {
      approval: {
        status: status,
      },
    }
  );
};

export const replyReview = async (reviewId: string, comment: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.post<IReplyReviewResponse>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/comments/entity/review/entity-id/${reviewId}?company_id=${getCompanyId()}`,
    {
      comment,
    }
  );
};

export const getReviewReply = async (reviewId: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetProductReviewReply>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/comments/entity/review/entity-id/${reviewId}?company_id=${getCompanyId()}`
  );
};
