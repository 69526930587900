import axios from "axios";
import { getCompanyId, getApplicationInfo } from "../utils";

interface IGetFormConfigResponse<T> {
  message: string;
  data: [
    {
      application: string;
      config: T;
    }
  ];
}

interface IUpdateFormConfigResponse<T> {
  message: string;
  data: [
    {
      config: T;
    }
  ];
}

interface IGetStatusResponse {
  message: string;
  data: {
    _id: string;
    entity_type: string;
    config: {
      is_active: boolean;
    };
  }[];
}

export const getFormConfig = async <T>(widget: string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetFormConfigResponse<T>>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/configurations?company_id=${getCompanyId()}&widget=${widget}`
  );
};

export const updateFormConfig = async <T>({
  widget,
  payload,
}: {
  widget: string;
  payload: T;
}) => {
  const { applicationId } = getApplicationInfo();

  return axios.patch<IUpdateFormConfigResponse<T>>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/configurations?company_id=${getCompanyId()}&widget=${widget}`,
    {
      config: {
        ...payload,
      },
    }
  );
};

export const getStatus = () => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetStatusResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/configurations/status?company_id=${getCompanyId()}`
  );
};
