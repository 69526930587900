import React, { memo } from "react";

import TextAtom from "../../../../../atoms/text/text";

import CSS from "../../../card.molecule.module.scss";

export interface ICardBodyProps {
  id: string;
  description: string;
}

const CardBody: React.FunctionComponent<ICardBodyProps> = ({
  id,
  description,
}) => {
  return (
    <div data-testid={id}>
      <div className={CSS.card_description}>
        <TextAtom text={description} type="title" id={"card-description"} />
      </div>
    </div>
  );
};

export default memo(CardBody);
