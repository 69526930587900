import { useCallback, useEffect, useState } from "react";
import CSS from "./payment.module.scss";
import PricingCard from "../../components/molecules/pricing-card/pricing-card.component";
import { PricingPlan } from "./payment.types";
import { getRatingPlans } from "../../services/subscription.service";
import { useSubscriptionContext } from "../../context/subscription-context";

const PaymentPricingPage: React.FunctionComponent = () => {
  const [planList, setPlanList] = useState<PricingPlan[]>();
  const { subscription } = useSubscriptionContext();

  const getPlanData = useCallback(async () => {
    try {
      const res = await getRatingPlans();
      console.log("res", res);
      if (res?.data?.data) {
        setPlanList(res?.data?.data);
      }
    } catch (err) {
      /**
       * @todo handle when error
       */
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getPlanData();
  }, [getPlanData]);

  const getPlans = (planList: PricingPlan[]) => {
    let newPlans: PricingPlan[] = [];
    if (!Object.keys(subscription).length) {
      return planList;
    }
    const isYearly = subscription?.details?.name
      ?.toLowerCase()
      ?.includes("yearly");
    if (subscription.plan_type === "basic" && isYearly) {
      newPlans = planList.filter(
        (item) => item.plan_type === "pro" && item.yearly_plan
      );
    } else if (subscription.plan_type === "basic" && !isYearly) {
      newPlans = planList.filter(
        (item) => !(item.plan_type === "basic" && !item.yearly_plan)
      );
    }
    return newPlans;
  };

  return (
    <div className={CSS.container}>
      <div className={CSS.heading}>Pricing</div>
      <div className={CSS.description}>
        Select plan suitable for you and your business need.
      </div>
      <div className={CSS.card_list}>
        {planList &&
          getPlans(planList)?.map((plan: PricingPlan) => {
            return <PricingCard plan={plan} />;
          })}
      </div>
    </div>
  );
};

export default PaymentPricingPage;
