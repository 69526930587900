import React, { memo } from "react";
import TextAtom from "../../../../components/atoms/text/text";

import CSS from "./radio-block-buttons.module.scss";

type IRadioOption = {
  icon: string;
  text: string;
  key: string;
};

interface IRadioBlockButtonsProps {
  radioOptions: IRadioOption[];
  selectedValue: string;
  handleClick?: (key: string) => void;
}

const RadioBlockButtons: React.FunctionComponent<IRadioBlockButtonsProps> = ({
  radioOptions = [],
  selectedValue = "",
  handleClick = () => { },
}) => {
  return (
    <div className={CSS.radio_group}>
      {radioOptions?.map((radioOption: IRadioOption) => {
        const isSelected = radioOption?.key === selectedValue;

        return (
          <div
            className={`${CSS.radio_container} ${isSelected ? CSS.selected : ""
              }`}
            onClick={() => handleClick(radioOption?.key)}
          >
            {radioOption?.icon && (
              <>
                <button className={CSS.radio_button}>
                  {<img src={radioOption?.icon} alt="radio_image" />}
                </button>
              </>
            )}
            <TextAtom
              className="cs-heading"
              fontWeight={400}
              id="4"
              text={radioOption?.text}
              type="title"
              style={{
                color: isSelected ? "#2E31BE" : "#41434C",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default memo(RadioBlockButtons);
