import React from "react";
import TextAtom from "../components/atoms/text/text";
import CSS from "./label.hoc.module.scss";

interface LabelHocProps {
  label: string;
  textLength?: number;
  textLimit?: number;
  labelClassName?: string;
}

export function withLabel<T>(Component: React.ComponentType<T>) {
  return (props: T & LabelHocProps) => {
    return (
      <div className={CSS.label_container}>
        <div
          className={`cs-bm-4 ${
            props?.textLength !== undefined ? CSS.label_box : ""
          } ${props.labelClassName ?? ""}`}
        >
          <TextAtom type="label" text={props.label ?? ""} />
          {props.textLength !== undefined && (
            <TextAtom
              type="label"
              text={`${props.textLength}/${props.textLimit}`}
            />
          )}
        </div>
        <Component {...props} />
      </div>
    );
  };
}

export default withLabel;
