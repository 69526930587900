import { v4 as uuidv4 } from "uuid";
import { IProductFeature } from "../../../../../../services/features.service";
import { ITableData } from "./product-feature-table.molecule";

export const transformCategoryToTableData = (
  data: IProductFeature[]
): ITableData[] => {
  const tableData = data?.map((val) => {
    return {
      key: uuidv4(),
      _id: val?._id,
      rowData: [
        {
          value: val?.category,
        },
        {
          value: val?.features,
        },
      ],
    };
  });
  return tableData;
};

export const transformTableToCategoryData = (
  data: ITableData[]
): IProductFeature[] => {
  const categoryData: IProductFeature[] = [];
  data?.forEach((val) => {
    if (val?.rowData?.[0]?.value && val?.rowData?.[1]?.value?.length > 0) {
      categoryData.push({
        category: val?.rowData?.[0]?.value as string,
        features: val?.rowData?.[1]?.value as string[],
      });
    }
  });

  return categoryData;
};
