import React, { memo, useCallback } from "react";
import BadgeAtom from "../../../../../atoms/badge/badge.atom";

import { ReactComponent as ArrowSvg } from "../../../../../../assets/arrow.svg";

import CSS from "../../../card.molecule.module.scss";
import { useNavigate } from "react-router-dom";

export interface ICardFooterProps {
  id: string;
  path: string;
  isActive: boolean;
}

const CardFooter: React.FunctionComponent<ICardFooterProps> = ({
  id,
  path,
  isActive,
}) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(path);
  }, [path]);

  return (
    <div data-testid={id}>
      <div className={CSS.card_footer}>
        <div>
          <BadgeAtom
            label={isActive ? "ACTIVE" : "INACTIVE"}
            state={isActive ? "success" : "error"}
            size="medium"
            id="card-status"
          />
        </div>
        <button
          className={CSS.card_arrow_button}
          onClick={handleNavigate}
          data-testid="card-button"
        >
          <ArrowSvg />
        </button>
      </div>
    </div>
  );
};

export default memo(CardFooter);
