import React, { useEffect, useMemo, useState } from "react";
import TextAtom from "../../../../../../components/atoms/text/text";
import CSS from "./product.overview.module.scss";
import StarAtom from "../../../../../../components/atoms/star/star.atom";
import DefaultImage from "../../../../../../assets/default_icon.png";
import RightArrowIcon from "../../../../../../assets/chevron_right.svg";
import {
  getProductDetails,
  IProductDetailResponse,
} from "../../service/reviews.details.service";
import { getApplicationInfo, getCompanyId } from "../../../../../../utils";

interface IProductOverviewProps {
  productId: string;
  isVerified: boolean;
  orderId: string;
  fallbackProductDetails: {
    name: string;
    brandName: string;
    short_description: string;
    media: [
      {
        url: string;
        type: string;
      }
    ];
    slug: string;
    uid: number;
    review_metric: {
      rating_sum: number;
      rating_count: number;
      review_count: number;
    };
  };
}

const ProductOverview: React.FC<IProductOverviewProps> = ({
  productId,
  isVerified,
  orderId,
  fallbackProductDetails,
}) => {
  const [productDetails, setProductDetails] = useState<
    IProductDetailResponse | undefined
  >();
  const reviewMetric =
    productDetails?.review_metric ?? fallbackProductDetails?.review_metric;

  useEffect(() => {
    (async () => {
      if (productId) {
        try {
          const res = await getProductDetails(productId);

          if (res?.data) {
            setProductDetails(res?.data);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [productId]);

  const productRating = useMemo(() => {
    const reviewMetric =
      productDetails?.review_metric ?? fallbackProductDetails?.review_metric;
    return (reviewMetric?.rating_sum ?? 1) / (reviewMetric?.rating_count || 1);
  }, [productDetails]);

  const productImage = useMemo(() => {
    const media = productDetails?.media ?? fallbackProductDetails?.media;
    return media?.find((item) => item?.type === "image")?.url;
  }, [productDetails]);

  const truncatedDescription = useMemo(() => {
    const desc =
      productDetails?.description ?? fallbackProductDetails?.short_description;
    const description = desc?.replace(/(<([^>]+)>)/gi, "") ?? "";
    return (desc?.length ?? 0) < 80
      ? description
      : description?.slice(0, 80) + "...";
  }, [productDetails]);

  const handleCardClick = () => {
    const applicationData = getApplicationInfo();
    const slug = productDetails?.slug ?? fallbackProductDetails?.slug;

    if (slug) {
      const redirectUrl =
        `https://` + applicationData.domain?.name + `/product/` + slug;

      window.open(redirectUrl, "_blank");
    }
  };

  const handleViewOrderDetailsClick = () => {
    if (orderId) {
      const redirectUrl = `${
        document.location.ancestorOrigins[0]
      }/company/${getCompanyId()}/orders/${orderId}/details`;

      window.open(redirectUrl, "_blank");
    }
  };

  return (
    <div className={`${CSS.cs_prd_overview_card}`}>
      <div
        className={`${
          productDetails?.slug || fallbackProductDetails?.slug
            ? CSS.cs_cursor
            : ""
        }`}
        onClick={handleCardClick}
      >
        <img
          src={productImage ?? DefaultImage}
          className={CSS.cs_cs_prd_overview_image}
          alt="product"
        />

        <TextAtom
          text={
            productDetails?.brand?.name ?? fallbackProductDetails?.brandName
          }
          type="header"
          className="cs-tm-12"
        />
        <TextAtom
          text={productDetails?.name ?? fallbackProductDetails?.name}
          type="heading"
          className="cs-tm-4"
        />
        <TextAtom
          text={`Total Ratings (${reviewMetric?.review_count ?? ""}):`}
          type="label"
          className="cs-tm-8"
        />
        <StarAtom className="cs-tm-2" defaultRating={productRating} />
      </div>
      <div
        className={`${CSS.cs_order_details} cs-tm-8 ${
          orderId ? CSS.cs_cursor : ""
        }`}
        onClick={handleViewOrderDetailsClick}
      >
        <TextAtom
          text={orderId ? "View Order Details" : "Missing Order Details"}
          type="header"
        />
        {orderId && <img src={RightArrowIcon} alt="right_icon" />}
      </div>
    </div>
  );
};

export default ProductOverview;
