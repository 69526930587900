import { memo, useState } from "react";
import TextAtom from "../../../../../../../../components/atoms/text/text";
import TagsContainerAtom from "../../atoms/image-container/tags.container.atom";
import CSS from "./review.container.module.scss";

interface ReviewContainerProps {
  id?: string;
  tags: string[];
  reviewText: string;
  maxTextLength?: number;
}

const ReviewContainer: React.FunctionComponent<ReviewContainerProps> = ({
  id = "",
  tags = [],
  reviewText = "",
  maxTextLength = 40,
}) => {
  const [expandReview, setExpandReview] = useState(false);
  return (
    <div id={"data-testid-product-container" + id} className={CSS.cs_dis_flex}>
      <div className={`${CSS.cs_center}`}>
        <TagsContainerAtom tags={tags} id={id} />
      </div>
      <div>
        <TextAtom
          text={reviewText}
          type="label"
          fontWeight={400}
          ellipsis={true}
          webkitLineClamp={2}
          lineHeight={19.2}
          showReadMore={true}
          expand={expandReview}
          maxTextLength={maxTextLength}
          onClickReadMore={() => setExpandReview(!expandReview)}
        />
      </div>
    </div>
  );
};

export default memo(ReviewContainer);
