export const defaultStatusOptions = [
  {
    text: "Published",
    value: "published",
  },
  {
    text: "Unpublished",
    value: "unpublished",
  },
  {
    text: "Rejected",
    value: "rejected",
  },
];

export const defaultRatingOptions = [
  {
    text: "4 and above",
    value: 4,
  },
  {
    text: "3 and above",
    value: 3,
  },
  {
    text: "2 and above",
    value: 2,
  },
];
