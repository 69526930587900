import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import BorderAtom from "../../../../components/atoms/border/border.component";
import TextAtom from "../../../../components/atoms/text/text";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import FieldSelect from "../../../../components/molecules/field-control/select/select.molecule";
import RightPanel from "../../../../components/templates/right-panel/right-panel.template";
import ToggleBox from "../../../../components/molecules/toggle-box/toggle-box.molecule";
import SectionHeadingWithDescBox from "../../../../components/molecules/section-heading-description/section-header";
import ButtonAtom from "../../../../components/atoms/button/button.atom";
import Collapsible from "../../../../components/molecules/collapsible/collapsible.molecule";
import StarAtom from "../../../../components/atoms/star/star.atom";
import FieldInputWithTagsBox from "../../../../components/molecules/field-control/input-with-tags-box/input-with-tags.molecule";
import { useConfigContext } from "../../../../context/config-context";

import {
  defaultApplicationConfig,
  ratingTypeOptions,
} from "./config/application-review.constants";

import { setNestedProperty } from "./application-review.util";

import CSS from "./application-review.module.scss";
import {
  getFormConfig,
  updateFormConfig,
} from "../../../../services/config.service";
import {
  showDangerToast,
  showSuccessToast,
  TOAST_MESSAGES,
} from "../../../../services/toast.service";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import Modal from "../../../../components/molecules/modal/modal.molecule";
import { useSubscriptionContext } from "../../../../context/subscription-context";
import ConfirmBody from "../../../../components/templates/confirm-modal";

interface IApplicationReviewConfig {
  rating_type: string;
  title: string;
  description: string;
  button_text: string;
  has_description: boolean;
  has_map_validation: boolean;
  map_validation: {
    poor: string;
    bad: string;
    average: string;
    good: string;
    excellent: string;
  };
  has_map_tags: boolean;
  map_tags: {
    poor: string[];
    bad: string[];
    average: string[];
    good: string[];
    excellent: string[];
  };
  is_active: boolean;
}

const ApplicationReview = () => {
  const [applicationConfig, setApplicationConfig] =
    useState<IApplicationReviewConfig>(defaultApplicationConfig);
  const navigate = useNavigate();
  const { setConfig } = useConfigContext();
  const preventConfigUpdate = useRef<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const { subscription } = useSubscriptionContext()

  useEffect(() => {
    setConfig((prevData: any) => ({
      ...prevData,
      applicationReviewConfig: { ...applicationConfig },
      type: "APPLICATION_REVIEW",
    }));
  }, [applicationConfig]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getFormConfig<IApplicationReviewConfig>(
          "application_review"
        );
        if (res?.data?.data) {
          setApplicationConfig(res.data.data?.[0]?.config);

          if (!res?.data?.data?.[0]?.config?.is_active) {
            preventConfigUpdate.current = true;
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      (async () => {
        try {
          const res = await updateFormConfig<IApplicationReviewConfig>({
            widget: "application_review",
            payload: applicationConfig,
          });

          if (res?.data) {
            showSuccessToast(
              res?.data?.data?.[0]?.config?.is_active
                ? TOAST_MESSAGES.configEnabled
                : TOAST_MESSAGES.configDisabled
            );
          }
        } catch (err) {
          console.log(err);
          showDangerToast(
            applicationConfig?.is_active
              ? TOAST_MESSAGES.configEnabledError
              : TOAST_MESSAGES.configDisabledError
          );
          handleConfigChange("is_active", !applicationConfig?.is_active);
          preventConfigUpdate.current = true;
        }
      })();
    } else {
      preventConfigUpdate.current = false;
    }
  }, [applicationConfig.is_active]);

  const handleConfigChange = (
    configKey: string,
    value: string | boolean | number | string[]
  ) => {
    if (configKey.includes(".")) {
      setApplicationConfig((prevState) => {
        return setNestedProperty(prevState, configKey, value);
      });
    } else {
      setApplicationConfig((prevState) => {
        return { ...prevState, [configKey]: value };
      });
    }
  };

  const handleSaveClick = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event?.stopPropagation();
    try {
      const res = await updateFormConfig<IApplicationReviewConfig>({
        widget: "application_review",
        payload: applicationConfig,
      });
      if (res?.data) {
        showSuccessToast(TOAST_MESSAGES.configUpdated);
      }
    } catch (err) {
      console.log(err);
      showDangerToast(TOAST_MESSAGES.configUpdateError);
    }
  };

  const handleCloseModal = useCallback(() => {
    setShowUpgradeModal(false)
  }, [])

  const handleProModal = useCallback(() => {
    setShowUpgradeModal(true)
  }, [])


  return (
    <div>
      <div className="cs-bm-24">
        <SectionHeadingWithDescBox
          id="application_reviews"
          heading="Application Reviews"
          description="Select your design layout and customise the settings as per your needs"
          value={applicationConfig?.is_active}
          onToggle={() =>
            handleConfigChange("is_active", !applicationConfig?.is_active)
          }
          onBackClick={useCallback(() => navigate(-1), [])}
          isPro={true}
        />
      </div>
      <div
        className={`${CSS["cs-grid-container"]} ${CSS["cs-template-30-70"]} ${CSS["cs-column-gap-25"]}`}
      >
        <div>
          <div className="cs-bm-12">
            <TextAtom
              fontWeight={600}
              id="text-heading"
              text="Settings"
              type="header"
            />
          </div>

          <FieldSelect
            items={ratingTypeOptions}
            onChange={(value) => handleConfigChange("rating_type", value)}
            value={applicationConfig?.rating_type}
            placeholder="Select Rating Type"
            id="rating-type"
            label="Rating Type"
          />

          <div className="cs-bm-20 cs-tm-20">
            <BorderAtom borderColor="#ededed" borderSize="0.5px" />
          </div>
          {/* 
          <div className="cs-bm-8">
            <TextAtom fontWeight={500} id="title" text="Title" type="heading" />
          </div> */}
          <FieldInputBox
            placeholder="Enter Title"
            id="title"
            value={applicationConfig?.title}
            onChange={(text) => handleConfigChange("title", text)}
            label="Title Text"
          />

          <div className="cs-bm-20 cs-tm-20">
            <BorderAtom borderColor="#ededed" borderSize="0.5px" />
          </div>

          <ToggleBox
            id="description"
            value={applicationConfig?.has_description ?? false}
            onToggle={() =>
              handleConfigChange(
                "has_description",
                !applicationConfig?.has_description
              )
            }
          >
            <TextAtom id="description" text="Description Box" type="heading" />
          </ToggleBox>
          {applicationConfig?.has_description && (
            <div className="cs-tm-10">
              <FieldInputBox
                placeholder="Enter Description"
                id="description"
                value={applicationConfig?.description}
                onChange={(text) => handleConfigChange("description", text)}
                label="Description Text"
              />
            </div>
          )}

          <div className="cs-bm-20 cs-tm-20">
            <BorderAtom borderColor="#ededed" borderSize="0.5px" />
          </div>

          {/* <div className="cs-bm-8">
            <TextAtom
              fontWeight={500}
              id="button-text"
              text="Button Text"
              type="heading"
            />
          </div> */}
          <FieldInputBox
            placeholder="Enter Button Text"
            id="button-text"
            value={applicationConfig?.button_text}
            onChange={(text) => handleConfigChange("button_text", text)}
            label="Button Text"
          />
          <div className="cs-bm-20 cs-tm-20">
            <BorderAtom borderColor="#ededed" borderSize="0.5px" />
          </div>

          <Collapsible
            className="cs-bm-15 cs-tm-15"
            type={"heading"}
            title="Validation"
          >
            <div className="cs-bm-8 cs-tm-10">
              <ToggleBox
                id="mapProductFeatures"
                value={applicationConfig?.has_map_validation}
                onToggle={() =>
                  handleConfigChange(
                    "has_map_validation",
                    !applicationConfig?.has_map_validation
                  )
                }
              >
                <TextAtom
                  id="map-validation-title"
                  text="Add Validation"
                  type="title"
                />
                <TextAtom
                  id="map-validation-description"
                  text="Enable to set qualitative terms catering different to emotions."
                  type="label"
                />
              </ToggleBox>
              {applicationConfig?.has_map_validation && (
                <div
                  className={`${CSS["cs-grid-container"]} ${CSS["cs-template-1-0"]} ${CSS["cs-column-gap-10"]} ${CSS["cs-row-gap-10"]} ${CSS["cs-justify-content"]} ${CSS["cs-align-items"]} cs-tm-8`}
                >
                  <FieldInputBox
                    placeholder="Enter Validation"
                    id="poor"
                    value={applicationConfig?.map_validation?.poor}
                    onChange={(text) =>
                      handleConfigChange("map_validation.poor", text)
                    }
                    label="Poor"
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={1} />
                  </div>

                  <FieldInputBox
                    placeholder="Enter Validation"
                    id="bad"
                    value={applicationConfig?.map_validation?.bad}
                    onChange={(text) =>
                      handleConfigChange("map_validation.bad", text)
                    }
                    label="Bad"
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={2} />
                  </div>

                  <FieldInputBox
                    placeholder="Enter Validation"
                    id="average"
                    value={applicationConfig?.map_validation?.average}
                    onChange={(text) =>
                      handleConfigChange("map_validation.average", text)
                    }
                    label="Average"
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={3} />
                  </div>

                  <FieldInputBox
                    placeholder="Enter Validation"
                    id="good"
                    value={applicationConfig?.map_validation?.good}
                    onChange={(text) =>
                      handleConfigChange("map_validation.good", text)
                    }
                    label="Good"
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={4} />
                  </div>

                  <FieldInputBox
                    placeholder="Enter Validation"
                    id="excellent"
                    value={applicationConfig?.map_validation?.excellent}
                    onChange={(text) =>
                      handleConfigChange("map_validation.excellent", text)
                    }
                    label="Excellent"
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={5} />
                  </div>
                </div>
              )}
            </div>
          </Collapsible>

          <div className="cs-bm-20 cs-tm-20">
            <BorderAtom borderColor="#ededed" borderSize="0.5px" />
          </div>

          <Collapsible
            className="cs-bm-15 cs-tm-15"
            type="heading"
            title="Tags"
          >
            <div className="cs-bm-8 cs-tm-10">
              <ToggleBox
                id="map-tags"
                value={applicationConfig?.has_map_tags}
                onToggle={() =>
                  handleConfigChange(
                    "has_map_tags",
                    !applicationConfig?.has_map_tags
                  )
                }
              >
                <TextAtom id="map-tags-title" text="Add Tags" type="title" />
                <TextAtom
                  id="map-tags-description"
                  text="Customise review tags to categorise and analyse customer feedback effectively."
                  type="label"
                />
              </ToggleBox>
              {applicationConfig?.has_map_tags && (
                <div
                  className={`${CSS["cs-grid-container"]} ${CSS["cs-template-1-0"]} ${CSS["cs-column-gap-10"]} ${CSS["cs-row-gap-10"]} cs-tm-8`}
                >
                  <FieldInputWithTagsBox
                    tags={applicationConfig?.map_tags?.poor}
                    onChange={(tags) =>
                      handleConfigChange("map_tags.poor", tags)
                    }
                    placeholder="Add Tag"
                    label="Poor"
                    limit={5}
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={1} />
                  </div>

                  <FieldInputWithTagsBox
                    tags={applicationConfig?.map_tags?.bad}
                    onChange={(tags) =>
                      handleConfigChange("map_tags.bad", tags)
                    }
                    placeholder="Add Tag"
                    label="Bad"
                    limit={5}
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={2} />
                  </div>

                  <FieldInputWithTagsBox
                    tags={applicationConfig?.map_tags?.average}
                    onChange={(tags) =>
                      handleConfigChange("map_tags.average", tags)
                    }
                    placeholder="Add Tag"
                    label="Average"
                    limit={5}
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={3} />
                  </div>

                  <FieldInputWithTagsBox
                    tags={applicationConfig?.map_tags?.good}
                    onChange={(tags) =>
                      handleConfigChange("map_tags.good", tags)
                    }
                    placeholder="Add Tag"
                    label="Good"
                    limit={5}
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={4} />
                  </div>

                  <FieldInputWithTagsBox
                    tags={applicationConfig?.map_tags?.excellent}
                    onChange={(tags) =>
                      handleConfigChange("map_tags.excellent", tags)
                    }
                    placeholder="Add Tag"
                    label="Excellent"
                    limit={5}
                  />
                  <div className="cs-tm-16">
                    <StarAtom defaultRating={5} />
                  </div>
                </div>
              )}
            </div>
          </Collapsible>

          <div className="cs-tm-26">
            <ButtonAtom label="Save" onClick={subscription?.plan_type === "basic" ? handleProModal : handleSaveClick} />
          </div>
        </div>
        <div>
          <RightPanel />
        </div>
      </div>
      {showUpgradeModal && <div>
        <Modal
          showModal={showUpgradeModal}
          handleCloseModal={handleCloseModal}
          id="upgrade"
          kind="informational"
          title=""
          isClosable={false}
          style={{ "width": "22%" }}
        ><ConfirmBody
            onCancel={handleCloseModal}
            onSuccess={() => navigate('/payment')}
          />
        </Modal>
      </div>}
    </div >
  );
};

export default ApplicationReview;
