import React, { ReactNode, memo } from "react";
import ToggleTextAtom from "../../atoms/toggle/toggle";
import CSS from "./toggle-box.module.scss";

interface ToggleBoxProps {
  id: string;
  value: boolean;
  onToggle: Function;
  size?: string;
  children?: ReactNode;
  disabled?: boolean;
}

function Toggle({
  id,
  value,
  onToggle,
  size = "small",
  disabled = false,
  children,
}: ToggleBoxProps): JSX.Element {
  return (
    <div>
      <div className={CSS["cs-toggle-box"]}>
        <div>{children}</div>
        <ToggleTextAtom
          id={id}
          onToggle={onToggle}
          size={size}
          value={value}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

const ToggleBox = memo(Toggle);
export default ToggleBox;
