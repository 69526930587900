import ProductContainerOrganism from "../organism/question-container/question.container.organism";
import dropdownIc from "../assets/icons/dropdownIC.png";
import CSS from "../table.module.scss";
import RatingContainerOrganism from "../organism/rating-container/rating.container.organism";
import BadgeAtom from "../../../../../../../components/atoms/badge/badge.atom";
import ActionContainerOrganism from "../organism/action-container/action.container.organism";
import QuestionContainerOrganism from "../organism/question-container/question.container.organism";

const computeState = (value: string) => {
  switch (value) {
    case "PUBLISHED":
      return "success";
    case "REJECTED":
      return "error";
    case "unpublished":
      return "warning";
    default:
      return "success";
  }
};

export const tableColumnData = (
  handleOpenQuestionClick: (id: string) => void
) => [
  {
    field: "Question",
    width: 700,
    headerComponent: () => <div style={{ display: "flex" }}>Question</div>,
    // wrapText: true,
    autoHeight: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: (content: any) => {
      return (
        <QuestionContainerOrganism
          src={content.data?.product?.images?.[0]?.url}
          questionText={content?.data?.question?.text}
          id="main-table-vew"
        />
      );
    },
  },
  {
    field: "Date",
    width: 250,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
    },
    cellRenderer: (content: any) => {
      const formattedDate = new Date(content.data.created_at).toLocaleString(
        "en-US",
        {
          day: "2-digit",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
      );
      return <div>{formattedDate.replace(/,/g, "")}</div>;
    },
  },
  {
    field: "status",
    width: 150,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
    },
    cellRenderer: (content: any) => {
      return (
        <>
          {content.data.approval.status === "published" && (
            <BadgeAtom label="PUBLISHED" size="small" />
          )}
          {content.data.approval.status === "unpublished" && (
            <BadgeAtom label="UNPUBLISHED" size="small" state="disable" />
          )}
          {content.data.approval.status === "rejected" && (
            <BadgeAtom label="REJECTED" size="small" state="error" />
          )}
          {content.data.approval.status === "reported" && (
            <BadgeAtom label="REPORTED" size="small" state="error" />
          )}
        </>
      );
    },
  },
  {
    field: "Actions",
    width: 100,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
    },
    cellRenderer: (content: any) => {
      return (
        <ActionContainerOrganism
          handleOpenClick={() => handleOpenQuestionClick(content.data.id)}
          buttonDisable={content.data.approval.status === "REJECTED"}
        />
      );
    },
  },
];
