import React from "react";

import ProfileIcon from "../../../../assets/profile-icon.svg";
import TextAtom from "../../../../components/atoms/text/text";
import withLabel from "../../../../hoc/label.hoc";

import CSS from "./sender-email.molecule.module.scss";

export interface ISenderEmailContainerProps {
  name: string;
  email: string;
}

const SenderEmailContainer: React.FunctionComponent<
  ISenderEmailContainerProps
> = ({ name, email }) => {
  return (
    <div className={CSS.main_container} data-testid="sender-email">
      <div className={CSS.icon_container}>
        <img
          src={ProfileIcon}
          alt="profile-icon"
          data-testid="sender-email-icon"
        />
      </div>
      <div className={CSS.text_container}>
        <TextAtom text={name} type="heading" id="name" />
        <TextAtom text={email} type="label" id="email" />
      </div>
    </div>
  );
};

export default withLabel(SenderEmailContainer);
