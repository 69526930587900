import React, { memo } from "react";
import RadioInput from '../../atoms/radio/radio.atom';
import CSS from "./radio-box.module.scss";
import TextAtom from "../../atoms/text/text";

interface RadioBoxProps {
  radios: RadiosProps[],
  heading: string;
  layout?: string;
}

interface RadiosProps {
  labelText: string;
  name: string;
  id?: string;
  onChange: Function;
  value: string;
  radioValue: string;
}

function RadioBox({
  radios = [],
  heading = '',
  layout = "vertical"
}: RadioBoxProps): JSX.Element {
  const layoutClass = layout === "vertical" ? CSS['vertical-box'] : CSS['horizontal-box']
  return (
    <div className={layoutClass}>
      <TextAtom text={heading} type="heading" />
      <div className={CSS['radio-wrapper']}>
        {radios.map(({
          id,
          onChange,
          value,
          name,
          labelText,
          radioValue,
        }) => (
          <RadioInput
            key={id || labelText}
            id={id || labelText}
            labelText={labelText}
            name={name}
            onChange={onChange}
            radioValue={radioValue}
            value={value}
            className={CSS['radio-box']}
          />
        ))}
      </div>
    </div >
  );
}

const ToggleBox = memo(RadioBox);
export default ToggleBox;
