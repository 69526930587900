import React from "react";
import TextAtom from "../../../../../../components/atoms/text/text";
import verifiedPNG from "./assets/icons/pointer.png";
import CSS from "./verified.module.scss";

const VerifiedPurchase = () => {
  return (
    <div className="cs-dis-flex">
      <img alt="verified" className={CSS.cs_verified_png} src={verifiedPNG} />
      <TextAtom className="cs-lm-5" text="Verified Purchase" type="label" />
    </div>
  );
};

export default VerifiedPurchase;
