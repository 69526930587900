import {
  followUpEmailTemplate,
  submitReviewEmailTemplate,
} from "../../email-template/email-template.constants";

export const defaultProductDetailConfig = {
  first_email_subject: "",
  first_email_preheader_text: "",
  first_email_hours: 12,
  first_email_content: submitReviewEmailTemplate,
  provider: "default", // slug
  send_follow_up_mail: false,
  follow_email_subject: "",
  follow_email_preheader_text: "",
  follow_email_hours: 12,
  follow_email_content: followUpEmailTemplate,
  send_email_on: "",
  is_enabled: true,
};

export const defaultEmailOptions = [
  // {
  //   text: "Order Placed",
  //   value: "order_placed",
  // },
  {
    text: "Handed over to the customer",
    value: "handed_to_customer",
  },
  {
    text: "Delivery Done",
    value: "delivery_done",
  },
];

export const tabsItems = [
  {
    key: 1,
    label: `First Email Template`,
  },
  {
    key: 2,
    label: `Follow Up Email Template`,
  },
];
