import axios from "axios";

import { getBaseApiURL } from "../services/apiBaseUrl.util";
import { getCompanyId } from "../utils";

axios.defaults.baseURL = getBaseApiURL();

const axiosInstance = axios.create({
  headers: {
    "x-company-id": undefined,
  },
});

axiosInstance.interceptors.request.use((config) => {
  if (config && config.headers) {
    config.headers.set("x-company-id", getCompanyId());
  }
  return config;
});

export default axiosInstance;
