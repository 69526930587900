import React, { useState } from "react";
import classNames from "classnames";

import CSS from "./right-panel.template.module.scss";
import { ReactComponent as Web } from "../../../assets/web.svg";
import { ReactComponent as Phone } from "../../../assets/phone.svg";
import { ReactComponent as Browser } from "../../../assets/browser.svg";
import Preview from "../preview/preview.template";

interface IRightPanelProps {
  Component?: JSX.Element;
}

const RightPanel = ({ Component }: IRightPanelProps) => {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <div className={CSS["right-panel-wrapper"]}>
      <div className={CSS["right-panel-top-section"]}>
        {!Component && (
          <div className={CSS["icon-wrapper"]}>
            <>
              <div
                className={classNames(CSS.icon, {
                  [CSS.active]: !isMobile,
                })}
                onClick={() => setIsMobile(false)}
              >
                <Web />
              </div>
              <div
                className={classNames(CSS.icon, {
                  [CSS.active]: isMobile,
                })}
                onClick={() => setIsMobile(true)}
              >
                <Phone />
              </div>
            </>
          </div>
        )}
        <p>Preview</p>
      </div>
      {!Component && (
        <div className={CSS["browser-img"]}>
          <Browser />
        </div>
      )}
      <div
        className={
          isMobile
            ? `${CSS["preview-wrapper-mobile"]}`
            : `${CSS["preview-wrapper"]}`
        }
      >
        {Component ? Component : <Preview isMobile={isMobile} />}
      </div>
    </div>
  );
};

export default RightPanel;
