import { ToastManager } from "@gofynd/nitrozen-react";

export const TOP_CENTER = "top-center";

// export const TOAST_TYPE = {
//   success: "success",
//   danger: "danger",
// };

export const TOAST_MESSAGES = {
  //   extensionEnabled: "Extension Enabled",
  //   extensionDisabled: "Extension Disabled",
  //   extensionEnabledError: "Unable to Enable Extension",
  //   extensionDisabledError: "Unable to Disable Extension",
  //   nudgeActivated: "Nudge Activated Successfully",
  //   nudgeDeactivated: "Nudge Deactivated Successfully",
  //   nudgeActivatedError: "Unable to Activate Nudge",
  //   nudgeDeactivatedError: "Unable to Deactivate Nudge",
  //   nudgeUpdateSuccess: "Nudge updated successfully",
  //   nudgeUpdateError: "Failed to update the nudge",
  //   nudgeDeletedSuccess: "Nudge Deleted Successfully",
  //   nudgeDeletedError: "Failed to delete the nudge",
  configUpdated: "Config Saved Successfully",
  configUpdateError: "Failed to Save the Config",
  configEnabled: "Config Enabled Successfully",
  configDisabled: "Config Disabled Successfully",
  configEnabledError: "Failed to Enable Config",
  configDisabledError: "Failed to Disable Config",
};

const toast = new ToastManager(TOP_CENTER);

type ShowToast = (title: string, handleDestroyToaster?: () => void) => void;

export const showSuccessToast: ShowToast = (
  title,
  handleDestroyToaster = () => undefined
) => {
  toast.show({
    destroy: handleDestroyToaster,
    title: title,
    position: TOP_CENTER,
    leftIconPosition: "",
    rightIconPosition: "",
    leftImage: null,
    rightImage: null,
    content: undefined,
    shouldClose: false,
    duration: 2000,
  });
};

export const showDangerToast: ShowToast = (
  title,
  handleDestroyToaster = () => undefined
) => {
  toast.show({
    destroy: handleDestroyToaster,
    title: title,
    position: TOP_CENTER,
    leftIconPosition: "",
    rightIconPosition: "",
    leftImage: null,
    rightImage: null,
    content: undefined,
    shouldClose: false,
    duration: 2000,
  });
};
