import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import BorderAtom from "../../../../components/atoms/border/border.component";
import TextAtom from "../../../../components/atoms/text/text";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import FieldSelect from "../../../../components/molecules/field-control/select/select.molecule";
import RightPanel from "../../../../components/templates/right-panel/right-panel.template";
import ToggleBox from "../../../../components/molecules/toggle-box/toggle-box.molecule";
import SectionHeadingWithDescBox from "../../../../components/molecules/section-heading-description/section-header";
import ButtonAtom from "../../../../components/atoms/button/button.atom";
import { useConfigContext } from "../../../../context/config-context";

import {
  defaultQuestionAnswerConfig,
  whoCanAskQAndA,
} from "./config/question-answer.constants";

import CSS from "./question-answer.module.scss";
import {
  getFormConfig,
  updateFormConfig,
} from "../../../../services/config.service";

import { QUESTIONS, PRODUCT } from "./config/dummy-questions.constants";
import {
  showDangerToast,
  showSuccessToast,
  TOAST_MESSAGES,
} from "../../../../services/toast.service";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

interface IQuestionAnswerConfig {
  who_can_ask_q_and_a: string;
  section_heading: string;
  question_button_text: string;
  answered_by_title: string;
  display_user_avatar: boolean;
  show_like_and_dislike: boolean;
  is_active: boolean;
}

const QuestionAnswer = () => {
  const [questionAnswerConfig, setQuestionAnswerConfig] = useState(
    defaultQuestionAnswerConfig
  );
  const navigate = useNavigate();
  const { setConfig } = useConfigContext();
  const preventConfigUpdate = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await getFormConfig<IQuestionAnswerConfig>(
          "questions_answers"
        );
        if (res?.data?.data) {
          setQuestionAnswerConfig(res.data.data?.[0]?.config);

          if (!res?.data?.data?.[0]?.config?.is_active) {
            preventConfigUpdate.current = true;
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    setConfig((prevData: any) => ({
      ...prevData,
      questionAnswerConfig: { ...questionAnswerConfig },
      resources: QUESTIONS.items,
      productItem: PRODUCT,
      type: "QUESTION",
    }));
  }, [questionAnswerConfig]);

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      (async () => {
        try {
          const res = await updateFormConfig<IQuestionAnswerConfig>({
            widget: "questions_answers",
            payload: questionAnswerConfig,
          });

          if (res?.data) {
            showSuccessToast(
              res?.data?.data?.[0]?.config?.is_active
                ? TOAST_MESSAGES.configEnabled
                : TOAST_MESSAGES.configDisabled
            );
          }
        } catch (err) {
          console.log(err);
          showDangerToast(
            questionAnswerConfig?.is_active
              ? TOAST_MESSAGES.configEnabledError
              : TOAST_MESSAGES.configDisabledError
          );
          handleConfigChange("is_active", !questionAnswerConfig?.is_active);
          preventConfigUpdate.current = true;
        }
      })();
    } else {
      preventConfigUpdate.current = false;
    }
  }, [questionAnswerConfig.is_active]);

  const handleConfigChange = (
    configKey: string,
    value: string | boolean | string[]
  ) => {
    setQuestionAnswerConfig((prevState) => {
      return { ...prevState, [configKey]: value };
    });
  };

  const onSaveClick = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();

    try {
      const res = await updateFormConfig<IQuestionAnswerConfig>({
        widget: "questions_answers",
        payload: questionAnswerConfig,
      });
      if (res?.data) {
        showSuccessToast(TOAST_MESSAGES.configUpdated);
      }
    } catch (err) {
      console.log(err);
      showDangerToast(TOAST_MESSAGES.configUpdateError);
    }
  };

  return (
    <div>
      <div className="cs-bm-24">
        <SectionHeadingWithDescBox
          id="1"
          heading="Questions And Answer"
          description="Select your design layout and customize the settings as per your needs"
          value={questionAnswerConfig?.is_active}
          onToggle={() =>
            handleConfigChange("is_active", !questionAnswerConfig?.is_active)
          }
          onBackClick={useCallback(() => navigate(-1), [])}
          isPro={false}
        />
      </div>

      <div
        className={`${CSS["cs-grid-container"]} ${CSS["cs-template-30-70"]} ${CSS["cs-column-gap-25"]}`}
      >
        <form>
          <div className="cs-bm-12">
            <TextAtom
              fontWeight={600}
              id="text-heading"
              text="Settings"
              type="header"
            />
          </div>
          <div
            className={`${CSS["cs-grid-container"]} ${CSS["cs-grid-of-two"]} ${CSS["cs-column-gap-16"]}`}
          >
            <FieldSelect
              items={whoCanAskQAndA}
              onChange={(value) =>
                handleConfigChange("who_can_ask_q_and_a", value)
              }
              value={questionAnswerConfig?.who_can_ask_q_and_a}
              placeholder="Select User Type"
              id="who-can-review"
              label="Who Can Ask Q&A"
            />

            <FieldInputBox
              placeholder="Enter Section Heading"
              id="section-heading"
              value={questionAnswerConfig?.section_heading}
              onChange={(text) => handleConfigChange("section_heading", text)}
              label="Section Heading"
            />

            <FieldInputBox
              placeholder="Ask A Question"
              id="question-button-text"
              value={questionAnswerConfig?.question_button_text}
              onChange={(text) =>
                handleConfigChange("question_button_text", text)
              }
              label="Question Button Text"
            />

            <FieldInputBox
              placeholder="Enter Answered By Title"
              id="answered-by-title"
              value={questionAnswerConfig?.answered_by_title}
              onChange={(text) => handleConfigChange("answered_by_title", text)}
              label="Answered By Title"
            />
          </div>
          <div>
            <div className="cs-tm-24">
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-12 cs-bm-12">
              <ToggleBox
                id="display-user-avatar"
                value={questionAnswerConfig?.display_user_avatar}
                onToggle={() =>
                  handleConfigChange(
                    "display_user_avatar",
                    !questionAnswerConfig?.display_user_avatar
                  )
                }
              >
                <TextAtom
                  id="display-user-avatar"
                  text="Display User Avatar"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <div>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-12 cs-bm-12">
              <ToggleBox
                id="show-like-and-dislike"
                value={questionAnswerConfig?.show_like_and_dislike}
                onToggle={() =>
                  handleConfigChange(
                    "show_like_and_dislike",
                    !questionAnswerConfig?.show_like_and_dislike
                  )
                }
              >
                <TextAtom
                  id="show-like-and-dislike"
                  text="Show Like & Dislike"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <div>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
          </div>
          <div className="cs-tm-26">
            <ButtonAtom label="Save" onClick={onSaveClick} />
          </div>
        </form>
        <div>
          <RightPanel />
        </div>
      </div>
    </div>
  );
};

export default QuestionAnswer;
