import React, { useEffect, useMemo, useState } from "react";
import StarAtom from "../../../../../../../../components/atoms/star/star.atom";
import TextAtom from "../../../../../../../../components/atoms/text/text";
import { getApplicationInfo } from "../../../../../../../../utils";
import { IQuestionAnswerData } from "../../../../service/question-answer.service";
import DefaultImage from "../../../../../../../../assets/default_icon.png";
import RightIcon from "../../../../../../../../assets/chevron_right.svg";
import {
  getProductDetails,
  IProductDetailResponse,
} from "../../service/question.details.service";
import CSS from "./product.overview.module.scss";

interface IProductOverviewProps {
  productId: string;
  fallbackProductDetails: IQuestionAnswerData["product"];
}

const ProductOverview: React.FC<IProductOverviewProps> = ({
  productId,
  fallbackProductDetails,
}) => {
  const [productDetails, setProductDetails] = useState<
    IProductDetailResponse | undefined
  >();
  const reviewMetric =
    productDetails?.review_metric ?? fallbackProductDetails?.review_metric;

  useEffect(() => {
    (async () => {
      if (productId) {
        try {
          const res = await getProductDetails(productId);

          if (res?.data) {
            setProductDetails(res?.data);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [productId]);

  const productRating = useMemo(() => {
    const reviewMetric =
      productDetails?.review_metric ?? fallbackProductDetails?.review_metric;
    return (reviewMetric?.rating_sum ?? 1) / (reviewMetric?.rating_count || 1);
  }, [productDetails]);

  const productImage = useMemo(() => {
    const imageUrl = productDetails?.media?.find(
      (item) => item?.type === "image"
    )?.url;
    return imageUrl ?? fallbackProductDetails?.images?.[0]?.url;
  }, [productDetails]);

  const handleCardClick = () => {
    const applicationData = getApplicationInfo();
    const slug = productDetails?.slug ?? fallbackProductDetails?.slug;

    if (slug) {
      const redirectUrl =
        `https://` + applicationData.domain?.name + `/product/` + slug;

      window.open(redirectUrl, "_blank");
    }
  };

  const truncatedDescription = useMemo(() => {
    const desc =
      productDetails?.description ?? fallbackProductDetails?.short_description;
    const description = desc?.replace(/(<([^>]+)>)/gi, "") ?? "";
    return (desc?.length ?? 0) < 80
      ? description
      : description?.slice(0, 80) + "...";
  }, [productDetails]);

  return (
    <div
      className={`${CSS.cs_prd_overview_card} ${
        productDetails?.slug || fallbackProductDetails?.slug
          ? CSS.cs_pointer
          : ""
      }`}
      onClick={handleCardClick}
    >
      <img
        src={productImage ?? DefaultImage}
        className={CSS.cs_cs_prd_overview_image}
        alt="product"
      />

      <TextAtom
        text={productDetails?.brand?.name ?? fallbackProductDetails?.brand_name}
        type="header"
        className="cs-tm-12"
      />
      <TextAtom
        text={productDetails?.name ?? fallbackProductDetails?.name ?? ""}
        type="heading"
        className="cs-tm-4"
      />
      <TextAtom
        text={`Total Ratings (${reviewMetric?.review_count ?? ""}):`}
        type="label"
        className="cs-tm-8"
      />
      <StarAtom className="cs-tm-2" defaultRating={productRating} />
      {(productDetails?.slug || fallbackProductDetails?.slug) && (
        <div className={`${CSS.cs_product_details} cs-tm-8`}>
          <TextAtom text="View Product" type="header" />
          <img src={RightIcon} alt="right_icon" />
        </div>
      )}
    </div>
  );
};

export default ProductOverview;
