import { useEffect, useMemo, useState } from "react";
import { DonutChart } from "./components/atoms/donut/donut-chart.component";
import { LineGraph } from "./components/atoms/line/line-graph.component";
import upArrow from "./assets/svgs/up_green_arrow.svg";
import downArrow from "./assets/svgs/down_arrow.svg";
import emptyStateOne from "../../assets/empty-state-1.svg";
import emptyStateTwo from "../../assets/empty-state-2.svg";
import CSS from "./index.module.scss";
import { Card } from "./components/atoms/card/card.component";
import { headers } from "./data";
import { Table } from "./components/atoms/table/table.component";
import { ChartInfo } from "./components/molecules/chart-info/chart-info.component";
import {
  getAnalyticsCount,
  getAverageRatings,
  getCustomerSentiment,
  getNpsScore,
  getTopProducts,
  getTotalReviews,
  IAnalyticsCountData,
  IAverageRatingsData,
  ICustomerSentimentData,
  INpsData,
  ITopProductData,
  ITotalReviews,
} from "./service/dashboard.service";
import SelectAtom from "../../components/atoms/select/select.atom";
import {
  analyticsFilterDropdownOptions,
  filterDropdownOptions,
} from "./dashboard.constants";

type INpsGraphData = {
  label: string;
  percentage: number;
  color: string;
  value: number;
  is_increased: number;
}[];

type IRatingsGraphData = {
  label: string;
  percentage: number;
  color: string;
  value: number;
  is_increased: number;
}[];

type ICustomerGraphData = {
  label: string;
  percentage: number;
  color: string;
  value: number;
  is_increased: number;
}[];
const Dashboard = () => {
  const [topProducts, setTopProducts] = useState<ITopProductData[]>();
  const [customerSentiment, setCustomerSentiment] =
    useState<ICustomerSentimentData>();
  const [averageRatings, setAverageRatings] = useState<IAverageRatingsData>();
  const [nps, setNps] = useState<INpsData>();
  const [totalReviews, setTotalReviews] = useState<ITotalReviews>();
  const [analyticsCount, setAnalyticsCount] = useState<IAnalyticsCountData>();

  const [analyticsFilter, setAnalyticsFilter] = useState<number | string>();
  const [npsFilter, setNpsFilter] = useState<number | string>();
  const [sentimentFilter, setSentimentFilter] = useState<number | string>();
  const [ratingsFilter, setRatingsFilter] = useState<number | string>();

  useEffect(() => {
    (async () => {
      try {
        const res = await getTopProducts(0);

        if (res?.data?.data) {
          setTopProducts(res?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }

      try {
        const res = await getTotalReviews(31);

        if (res?.data?.data) {
          setTotalReviews(res?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }

      try {
        const res = await getAnalyticsCount(7);

        if (res?.data?.data) {
          console.log(res?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }

      setNpsFilter(7);
      setRatingsFilter(7);
      setSentimentFilter(7);
      setAnalyticsFilter("all");
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const params = (npsFilter ?? "all") === "all" ? 0 : npsFilter;
        const res = await getNpsScore(params ? params : 0);

        if (res?.data?.data) {
          setNps(res?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [npsFilter]);

  useEffect(() => {
    (async () => {
      try {
        const params =
          (analyticsFilter ?? "all") === "all" ? 0 : analyticsFilter;
        const res = await getAnalyticsCount(params ? params : 0);

        if (res?.data?.data) {
          setAnalyticsCount(res?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [analyticsFilter]);

  useEffect(() => {
    (async () => {
      try {
        const params =
          (sentimentFilter ?? "all") === "all" ? 0 : sentimentFilter;
        const res = await getCustomerSentiment(params ? params : 0);

        if (res?.data?.data) {
          setCustomerSentiment(res?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [sentimentFilter]);

  useEffect(() => {
    (async () => {
      try {
        const params = (ratingsFilter ?? "all") === "all" ? 0 : ratingsFilter;
        const res = await getAverageRatings(params ? params : 0);

        if (res?.data?.data) {
          setAverageRatings(res?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [ratingsFilter]);

  const analyticsCountData = useMemo(() => {
    const reviewCountChange =
      (((analyticsCount?.analyticsInRange?.reviewCount ?? 0) -
        (analyticsCount?.analyticsCompared?.reviewCount ?? 0)) /
        (analyticsCount?.analyticsCompared?.reviewCount || 1)) *
      100;

    const totalCouponSendChange =
      (((analyticsCount?.analyticsInRange?.totalCouponSentCount ?? 0) -
        (analyticsCount?.analyticsCompared?.totalCouponSentCount ?? 0)) /
        (analyticsCount?.analyticsCompared?.totalCouponSentCount || 1)) *
      100;

    const totalEmailSentChange =
      (((analyticsCount?.analyticsInRange?.totalEmailSentCount ?? 0) -
        (analyticsCount?.analyticsCompared?.totalEmailSentCount ?? 0)) /
        (analyticsCount?.analyticsCompared?.totalEmailSentCount || 1)) *
      100;

    const totalCouponAppliedChange =
      (((analyticsCount?.analyticsInRange?.totalCouponAppliedCount ?? 0) -
        (analyticsCount?.analyticsCompared?.totalCouponAppliedCount ?? 0)) /
        (analyticsCount?.analyticsCompared?.totalCouponAppliedCount || 1)) *
      100;

    return [
      {
        name: "Total Reviews Received",
        value:
          analyticsFilter === "all"
            ? analyticsCount?.analyticsTillRange?.reviewCount ?? 0
            : analyticsCount?.analyticsInRange?.reviewCount ?? 0,
        percentage: reviewCountChange,
        is_increased: reviewCountChange >= 0,
      },
      {
        name: "Total Coupons Sent",
        value:
          analyticsFilter === "all"
            ? analyticsCount?.analyticsTillRange?.totalCouponSentCount ?? 0
            : analyticsCount?.analyticsInRange?.totalCouponSentCount ?? 0,
        percentage: totalCouponSendChange,
        is_increased: totalCouponSendChange >= 0,
      },
      {
        name: "Total Coupons Applied",
        value:
          analyticsFilter === "all"
            ? analyticsCount?.analyticsTillRange?.totalCouponAppliedCount ?? 0
            : analyticsCount?.analyticsInRange?.totalCouponAppliedCount ?? 0,
        percentage: totalCouponAppliedChange,
        is_increased: totalCouponAppliedChange >= 0,
      },
      {
        name: "Total Review Email Sent",
        value:
          analyticsFilter === "all"
            ? analyticsCount?.analyticsTillRange?.totalEmailSentCount ?? 0
            : analyticsCount?.analyticsInRange?.totalEmailSentCount ?? 0,
        percentage: totalEmailSentChange,
        is_increased: totalEmailSentChange >= 0,
      },
    ];
  }, [analyticsCount]);

  const customerGraphData = useMemo(() => {
    if (sentimentFilter === "all") {
      const newDen =
        (customerSentiment?.analyticsTillRange?.happy ?? 0) +
        (customerSentiment?.analyticsTillRange?.neutral ?? 0) +
        (customerSentiment?.analyticsTillRange?.sad ?? 0);

      const totalHappyPercentage =
        ((customerSentiment?.analyticsTillRange?.happy ?? 0) / (newDen || 1)) *
        100;

      const totalNeutralPercentage =
        ((customerSentiment?.analyticsTillRange?.neutral ?? 0) /
          (newDen || 1)) *
        100;

      const totalSadPercentage =
        ((customerSentiment?.analyticsTillRange?.sad ?? 0) / (newDen || 1)) *
        100;

      return [
        {
          label: "Happy",
          percentage: Math.round(totalHappyPercentage),
          color: "#2e31be",
          value: 0,
          is_increased: 0,
        },
        {
          label: "Neutral",
          percentage: Math.round(totalNeutralPercentage),
          color: "#6162d2",
          value: 0,
          is_increased: 0,
        },
        {
          label: "Sad",
          percentage: Math.round(totalSadPercentage),
          color: "#a0a1e3",
          value: 0,
          is_increased: 0,
        },
      ];
    } else {
      const newDen =
        (customerSentiment?.analyticsInRange?.happy ?? 0) +
        (customerSentiment?.analyticsInRange?.neutral ?? 0) +
        (customerSentiment?.analyticsInRange?.sad ?? 0);

      const totalHappyPercentage =
        ((customerSentiment?.analyticsInRange?.happy ?? 0) / (newDen || 1)) *
        100;

      const totalNeutralPercentage =
        ((customerSentiment?.analyticsInRange?.neutral ?? 0) / (newDen || 1)) *
        100;

      const totalSadPercentage =
        ((customerSentiment?.analyticsInRange?.sad ?? 0) / (newDen || 1)) * 100;

      const happyChangePercentage =
        (((customerSentiment?.analyticsInRange?.happy ?? 0) -
          (customerSentiment?.analyticsCompared?.happy ?? 0)) /
          (customerSentiment?.analyticsCompared?.happy || 1)) *
        100;

      const neutralChangePercentage =
        (((customerSentiment?.analyticsInRange?.neutral ?? 0) -
          (customerSentiment?.analyticsCompared?.neutral ?? 0)) /
          (customerSentiment?.analyticsCompared?.neutral || 1)) *
        100;

      const sadChangePercentage =
        (((customerSentiment?.analyticsInRange?.sad ?? 0) -
          (customerSentiment?.analyticsCompared?.sad ?? 0)) /
          (customerSentiment?.analyticsCompared?.sad || 1)) *
        100;
      return [
        {
          label: "Happy",
          percentage: Math.round(totalHappyPercentage),
          color: "#2e31be",
          value: Math.round(happyChangePercentage),
          is_increased: happyChangePercentage >= 0,
        },
        {
          label: "Neutral",
          percentage: Math.round(totalNeutralPercentage),
          color: "#6162d2",
          value: Math.round(neutralChangePercentage),
          is_increased: neutralChangePercentage >= 0,
        },
        {
          label: "Sad",
          percentage: Math.round(totalSadPercentage),
          color: "#a0a1e3",
          value: Math.round(sadChangePercentage),
          is_increased: sadChangePercentage >= 0,
        },
      ];
    }
  }, [customerSentiment]);

  const npsGraphData = useMemo(() => {
    if (npsFilter === "all") {
      const newDen =
        (nps?.analyticsTillRange?.[0]?.promoters ?? 0) +
        (nps?.analyticsTillRange?.[0]?.passives ?? 0) +
        (nps?.analyticsTillRange?.[0]?.detractors ?? 0);

      const promotersPercentage =
        ((nps?.analyticsTillRange?.[0]?.promoters ?? 0) / (newDen || 1)) * 100;

      const detractorsPercentage =
        ((nps?.analyticsTillRange?.[0]?.detractors ?? 0) / (newDen || 1)) * 100;

      const passivesPercentage =
        ((nps?.analyticsTillRange?.[0]?.passives ?? 0) / (newDen || 1)) * 100;

      return [
        {
          label: "Promotors",
          percentage: Math.round(promotersPercentage),
          color: "#2e31be",
          value: 0,
          is_increased: 0,
        },
        {
          label: "Passivists",
          percentage: Math.round(passivesPercentage),
          color: "#6162d2",
          value: 0,
          is_increased: 0,
        },
        {
          label: "Detractors",
          percentage: Math.round(detractorsPercentage),
          color: "#a0a1e3",
          value: 0,
          is_increased: 0,
        },
      ];
    } else {
      const newDen =
        (nps?.analyticsInRange?.[0]?.promoters ?? 0) +
        (nps?.analyticsInRange?.[0]?.passives ?? 0) +
        (nps?.analyticsInRange?.[0]?.detractors ?? 0);

      const promotersPercentage =
        ((nps?.analyticsInRange?.[0]?.promoters ?? 0) / (newDen || 1)) * 100;

      const detractorsPercentage =
        ((nps?.analyticsInRange?.[0]?.detractors ?? 0) / (newDen || 1)) * 100;

      const passivesPercentage =
        ((nps?.analyticsInRange?.[0]?.passives ?? 0) / (newDen || 1)) * 100;

      const promotersPercentageChange =
        (((nps?.analyticsInRange?.[0]?.promoters ?? 0) -
          (nps?.analyticsCompared?.[0]?.promoters ?? 0)) /
          (nps?.analyticsCompared?.[0]?.promoters || 1)) *
        100;

      const detractersPercentageChange =
        (((nps?.analyticsInRange?.[0]?.detractors ?? 0) -
          (nps?.analyticsCompared?.[0]?.detractors ?? 0)) /
          (nps?.analyticsCompared?.[0]?.detractors || 1)) *
        100;

      const passivesPercentageChange =
        (((nps?.analyticsInRange?.[0]?.passives ?? 0) -
          (nps?.analyticsCompared?.[0]?.passives ?? 0)) /
          (nps?.analyticsTillRange?.[0]?.passives || 1)) *
        100;

      return [
        {
          label: "Promotors",
          percentage: Math.round(promotersPercentage),
          color: "#2e31be",
          value: Math.round(promotersPercentageChange),
          is_increased: promotersPercentageChange >= 0,
        },
        {
          label: "Passivists",
          percentage: Math.round(passivesPercentage),
          color: "#6162d2",
          value: Math.round(passivesPercentageChange),
          is_increased: passivesPercentageChange >= 0,
        },
        {
          label: "Detractors",
          percentage: Math.round(detractorsPercentage),
          color: "#a0a1e3",
          value: Math.round(detractersPercentageChange),
          is_increased: detractersPercentageChange >= 0,
        },
      ];
    }
  }, [nps]);

  const ratingsGraphData = useMemo(() => {
    if (ratingsFilter === "all") {
      const fiveStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 5
        )?.count ?? 0;
      const fourStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 4
        )?.count ?? 0;
      const threeStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 3
        )?.count ?? 0;
      const twoStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 2
        )?.count ?? 0;
      const oneStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 1
        )?.count ?? 0;

      const newDen =
        fiveStarTillRange +
        fourStarTillRange +
        threeStarTillRange +
        twoStarTillRange +
        oneStarTillRange;

      const fiveStarPercentage = (fiveStarTillRange / (newDen || 1)) * 100;
      const fourStarPercentage = (fourStarTillRange / (newDen || 1)) * 100;
      const threeStarPercentage = (threeStarTillRange / (newDen || 1)) * 100;
      const twoStarPercentage = (twoStarTillRange / (newDen || 1)) * 100;
      const oneStarPercentage = (oneStarTillRange / (newDen || 1)) * 100;

      return [
        {
          label: "5 Star",
          percentage: Math.round(fiveStarPercentage),
          color: "#2e31be",
          value: 0,
          is_increased: 0,
        },
        {
          label: "4 Star",
          percentage: Math.round(fourStarPercentage),
          color: "#6162d2",
          value: 0,
          is_increased: 0,
        },
        {
          label: "3 Star",
          percentage: Math.round(threeStarPercentage),
          color: "#a0a1e3",
          value: 0,
          is_increased: 0,
        },
        {
          label: "2 Star",
          percentage: Math.round(twoStarPercentage),
          color: "#cccff0",
          value: 0,
          is_increased: 0,
        },
        {
          label: "1 Star",
          percentage: Math.round(oneStarPercentage),
          color: "#e3e3fd",
          value: 0,
          is_increased: 0,
        },
      ];
    } else {
      const fiveStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 5
        )?.count ?? 0;
      const fourStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 4
        )?.count ?? 0;
      const threeStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 3
        )?.count ?? 0;
      const twoStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 2
        )?.count ?? 0;
      const oneStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 1
        )?.count ?? 0;

      const fiveStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 5
        )?.count ?? 0;
      const fourStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 4
        )?.count ?? 0;
      const threeStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 3
        )?.count ?? 0;
      const twoStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 2
        )?.count ?? 0;
      const oneStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 1
        )?.count ?? 0;

      const fiveStarCompared =
        averageRatings?.analyticsCompared?.totalPercentageCount?.find(
          (el) => el?.star === 5
        )?.count ?? 0;
      const fourStarCompared =
        averageRatings?.analyticsCompared?.totalPercentageCount?.find(
          (el) => el?.star === 4
        )?.count ?? 0;
      const threeStarCompared =
        averageRatings?.analyticsCompared?.totalPercentageCount?.find(
          (el) => el?.star === 3
        )?.count ?? 0;
      const twoStarCompared =
        averageRatings?.analyticsCompared?.totalPercentageCount?.find(
          (el) => el?.star === 2
        )?.count ?? 0;
      const oneStarCompared =
        averageRatings?.analyticsCompared?.totalPercentageCount?.find(
          (el) => el?.star === 1
        )?.count ?? 0;

      const den =
        fiveStarTillRange +
        fiveStarInRange +
        fourStarInRange +
        fourStarTillRange +
        threeStarInRange +
        threeStarTillRange +
        twoStarInRange +
        twoStarTillRange +
        oneStarInRange +
        oneStarTillRange;

      const newDen =
        fiveStarInRange +
        fourStarInRange +
        threeStarInRange +
        twoStarInRange +
        oneStarInRange;

      const fixedDen = den ? den : 1;

      const fiveStarPercentage = (fiveStarInRange / (newDen || 1)) * 100;
      const fourStarPercentage = (fourStarInRange / (newDen || 1)) * 100;
      const threeStarPercentage = (threeStarInRange / (newDen || 1)) * 100;
      const twoStarPercentage = (twoStarInRange / (newDen || 1)) * 100;
      const oneStarPercentage = (oneStarInRange / (newDen || 1)) * 100;

      const fiveStarPercentageIncrease =
        ((fiveStarInRange - fiveStarCompared) / (fiveStarCompared || 1)) * 100;
      const fourStarPercentageIncrease =
        ((fourStarInRange - fourStarCompared) / (fourStarCompared || 1)) * 100;
      const threeStarPercentageIncrease =
        ((threeStarInRange - threeStarCompared) / (threeStarCompared || 1)) *
        100;
      const twoStarPercentageIncrease =
        ((twoStarInRange - twoStarCompared) / (twoStarCompared || 1)) * 100;
      const oneStarPercentageIncrease =
        ((oneStarInRange - oneStarCompared) / (oneStarCompared || 1)) * 100;

      return [
        {
          label: "5 Star",
          percentage: Math.round(fiveStarPercentage),
          color: "#2e31be",
          value: Math.round(fiveStarPercentageIncrease),
          is_increased: fiveStarPercentageIncrease >= 0,
        },
        {
          label: "4 Star",
          percentage: Math.round(fourStarPercentage),
          color: "#6162d2",
          value: Math.round(fourStarPercentageIncrease),
          is_increased: fourStarPercentageIncrease >= 0,
        },
        {
          label: "3 Star",
          percentage: Math.round(threeStarPercentage),
          color: "#a0a1e3",
          value: Math.round(threeStarPercentageIncrease),
          is_increased: threeStarPercentageIncrease >= 0,
        },
        {
          label: "2 Star",
          percentage: Math.round(twoStarPercentage),
          color: "#cccff0",
          value: Math.round(twoStarPercentageIncrease),
          is_increased: twoStarPercentageIncrease >= 0,
        },
        {
          label: "1 Star",
          percentage: Math.round(oneStarPercentage),
          color: "#e3e3fd",
          value: Math.round(oneStarPercentageIncrease),
          is_increased: oneStarPercentageIncrease >= 0,
        },
      ];
    }
  }, [averageRatings]);

  const ratingsBarData = useMemo(() => {
    if (ratingsFilter === "all") {
      const fiveStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 5
        )?.count ?? 0;
      const fourStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 4
        )?.count ?? 0;
      const threeStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 3
        )?.count ?? 0;
      const twoStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 2
        )?.count ?? 0;
      const oneStarTillRange =
        averageRatings?.analyticsTillRange?.totalPercentageCount?.find(
          (el) => el?.star === 1
        )?.count ?? 0;

      const totalReviews =
        fiveStarTillRange +
        fourStarTillRange +
        threeStarTillRange +
        twoStarTillRange +
        oneStarTillRange;

      const excellentReviewCount =
        ((fiveStarTillRange + fourStarTillRange) / (totalReviews || 1)) * 100;

      return {
        excellentReviewCount,
        totalReviews,
        totalResponses: averageRatings?.totalResponsesCountForReviews ?? 0,
      };
    } else {
      const fiveStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 5
        )?.count ?? 0;
      const fourStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 4
        )?.count ?? 0;
      const threeStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 3
        )?.count ?? 0;
      const twoStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 2
        )?.count ?? 0;
      const oneStarInRange =
        averageRatings?.analyticsInRange?.totalPercentageCount?.find(
          (el) => el?.star === 1
        )?.count ?? 0;

      const totalReviews =
        fiveStarInRange +
        fourStarInRange +
        threeStarInRange +
        twoStarInRange +
        oneStarInRange;

      const excellentReviewCount =
        ((fiveStarInRange + fourStarInRange) / (totalReviews || 1)) * 100;

      return {
        excellentReviewCount,
        totalReviews,
        totalResponses: averageRatings?.totalResponsesCountForReviews ?? 0,
      };
    }
  }, [averageRatings]);

  const diffBetweenPromotersAndDetracters = useMemo(() => {
    return Math.abs(
      ((npsGraphData as INpsGraphData)?.find((el) => el?.label === "Promotors")
        ?.percentage ?? 0) -
        ((npsGraphData as INpsGraphData)?.find(
          (el) => el?.label === "Detractors"
        )?.percentage ?? 0)
    );
  }, [npsGraphData]);

  const percentageChangeInGraph = useMemo(() => {
    let inRangeTotal = 0;
    let compareTotal = 0;
    totalReviews?.reviewsInRange?.forEach((review) => {
      inRangeTotal =
        inRangeTotal +
        (review?.negativeCount ?? 0) +
        (review?.positiveCount ?? 0);
    });

    totalReviews?.analyticsCompared?.forEach((review) => {
      compareTotal =
        compareTotal +
        (review?.positiveCount ?? 0) +
        (review?.negativeCount ?? 0);
    });

    return ((inRangeTotal - compareTotal) / (compareTotal || 1)) * 100;
  }, [totalReviews]);

  const showNoRatingsData = useMemo(() => {
    const isDataPresent = (ratingsGraphData as IRatingsGraphData)?.reduce(
      (acc, data) => {
        return acc && data?.percentage === 0;
      },
      true
    );
    return isDataPresent ?? false;
  }, [ratingsGraphData]);

  const showNoNpsData = useMemo(() => {
    const isDataPresent = (npsGraphData as INpsGraphData)?.reduce(
      (acc, data) => {
        return acc && data?.percentage === 0;
      },
      true
    );
    return isDataPresent ?? false;
  }, [npsGraphData]);

  const showNoCustomerData = useMemo(() => {
    const isDataPresent = (customerGraphData as ICustomerGraphData)?.reduce(
      (acc, data) => {
        return acc && data?.percentage === 0;
      },
      true
    );
    return isDataPresent ?? false;
  }, [customerGraphData]);

  return (
    <div className={CSS.dashboard_container}>
      <div className={CSS.heading}>
        <div className={CSS.analytics_text}>Analytics</div>
        <SelectAtom
          value={analyticsFilter ?? 0}
          items={analyticsFilterDropdownOptions}
          onChange={(e) => {
            setAnalyticsFilter(e);
          }}
          placeholder="Filter Dropdown"
          id="field-control-filter-ratings"
        />
      </div>
      <div className={CSS.analytics_container}>
        <div className={CSS.card_main_container}>
          {analyticsCountData.map((item) => {
            return <Card item={item} analyticsFilter={analyticsFilter} />;
          })}
        </div>
      </div>
      <div className={CSS.line_graph_container}>
        <div className={CSS.vertical_center}>
          <div className={CSS.chart_heading}>Total Number of Reviews</div>
          {(totalReviews?.reviewsInRange?.length ?? 0) > 0 && (
            <div
              className={
                percentageChangeInGraph >= 0
                  ? CSS.increased_value
                  : CSS.decreased_value
              }
            >
              <img
                src={percentageChangeInGraph >= 0 ? upArrow : downArrow}
                alt={percentageChangeInGraph >= 0 ? "up" : "down"}
              />{" "}
              {Math.abs(percentageChangeInGraph).toFixed(2)}
              {"% "}
            </div>
          )}
        </div>
        {(totalReviews?.reviewsInRange?.length ?? 0) > 0 ? (
          <div className={CSS.line_graph}>
            <LineGraph
              totalReviews={totalReviews?.reviewsInRange ?? []}
              totalReviewsCompared={totalReviews?.analyticsCompared ?? []}
            />
          </div>
        ) : (
          <div className={CSS.empty_state}>
            <div>
              <img src={emptyStateOne} alt="empty-state-1" />
            </div>
            <div>No Analytics Found</div>
          </div>
        )}
      </div>
      <div className={CSS.four_box_container}>
        <div className={CSS.top_rated_container}>
          <div className={CSS.chart_heading}>Top Rated Products</div>
          <div style={{ width: "100%", height: "100%" }}>
            {(topProducts?.length ?? 0) > 0 ? (
              <div>
                <Table headers={headers} data={topProducts ?? []} />
              </div>
            ) : (
              <div className={CSS.empty_state}>
                <div>
                  <img src={emptyStateTwo} alt="empty-state-2" />
                </div>
                <div>No Top Rated Products Found</div>
              </div>
            )}
          </div>
        </div>
        <div className={CSS.top_rated_container}>
          <div className={CSS.vertical_center}>
            <div className={CSS.chart_heading}>Average Ratings</div>
            <SelectAtom
              value={ratingsFilter ?? 0}
              items={filterDropdownOptions}
              onChange={(e) => {
                setRatingsFilter(e);
              }}
              placeholder="Filter Dropdown"
              id="field-control-filter-ratings"
            />
          </div>
          {showNoRatingsData ? (
            <div className={CSS.empty_state}>
              <div>
                <img src={emptyStateTwo} alt="empty-state-2" />
              </div>
              <div>No Average Ratings Found</div>
            </div>
          ) : (
            <div
              className={`${CSS.dis_flex} ${CSS.h_65} ${CSS.margin_auto} ${CSS.flex_gap}`}
            >
              <div className={CSS.donut_chart_container}>
                <DonutChart
                  label={ratingsGraphData?.map((el) => el?.label)}
                  percentages={ratingsGraphData?.map((el) => el?.percentage)}
                />
              </div>
              <div className={CSS.chart_info_container}>
                <ChartInfo data={ratingsGraphData} filter={ratingsFilter} />
              </div>
            </div>
          )}
          <div className={`${CSS.chart_info_bottom} ${CSS.border_1}`}>
            <div className={CSS.highlighted_text}>
              <div className={CSS.highlighted_percentage}>
                {ratingsBarData?.excellentReviewCount.toFixed(2)}%
              </div>
              <div className={CSS.highlight_title}>Excellent</div>
            </div>
            <div className={`${CSS.dis_flex} ${CSS.flex_dir_column}`}>
              <div
                className={`${CSS.dis_flex} ${CSS.space_between} ${CSS.border_bottom_1} ${CSS.total_reviews_title}`}
              >
                <div className={CSS.ratings_text}>Total Reviews</div>
                <div className={CSS.number_ratings}>
                  {ratingsBarData?.totalReviews}
                </div>
              </div>
              <div
                className={`${CSS.dis_flex} ${CSS.space_between} ${CSS.total_reviews_title}`}
              >
                <div className={CSS.ratings_text}>Total Reviews Responded</div>
                <div className={CSS.number_ratings}>
                  {ratingsBarData?.totalResponses}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={CSS.top_rated_container}>
          <div className={CSS.vertical_center}>
            <div className={CSS.chart_heading}>Net Promoter Score (NPS)</div>
            <SelectAtom
              value={npsFilter ?? 0}
              items={filterDropdownOptions}
              onChange={(e) => {
                setNpsFilter(e);
              }}
              placeholder="Filter Dropdown"
              id="field-control-filter-nps"
            />
          </div>
          {showNoNpsData ? (
            <div className={CSS.empty_state}>
              <div>
                <img src={emptyStateTwo} alt="empty-state-2" />
              </div>
              <div>No NPS Record Found</div>
            </div>
          ) : (
            <div
              className={`${CSS.dis_flex} ${CSS.h_65} ${CSS.margin_auto} ${CSS.flex_gap}`}
            >
              <div className={CSS.donut_chart_container}>
                <DonutChart
                  label={npsGraphData?.map((el) => el?.label)}
                  percentages={npsGraphData?.map((el) => el?.percentage)}
                />
              </div>
              <div className={CSS.chart_info_container}>
                <ChartInfo data={npsGraphData} filter={npsFilter} />
              </div>
            </div>
          )}
          {!showNoNpsData && (
            <div
              className={`${CSS.chart_info_bottom} ${CSS.nps_text_container}`}
            >
              <div className={`${CSS.highlight_text} ${CSS.promotors_arrange}`}>
                <div className={CSS.highlight_text}>% Promoters</div>
                <div className={CSS.highlight_text}> - </div>
                <div className={CSS.highlight_text}> % Detractors </div>
                <div className={CSS.highlight_text}> = </div>
                <div>
                  <button className={CSS.promotors_btn}>
                    {diffBetweenPromotersAndDetracters}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={CSS.top_rated_container}>
          <div className={CSS.vertical_center}>
            <div className={CSS.chart_heading}>Customer Sentiment</div>
            <SelectAtom
              value={sentimentFilter ?? 0}
              items={filterDropdownOptions}
              onChange={(e) => {
                setSentimentFilter(e);
              }}
              placeholder="Filter Dropdown"
              id="field-control-filter-sentiment"
            />
          </div>
          {showNoCustomerData ? (
            <div className={CSS.empty_state}>
              <div>
                <img src={emptyStateTwo} alt="empty-state-2" />
              </div>
              <div>No Customer Sentiment Found</div>
            </div>
          ) : (
            <div
              className={`${CSS.dis_flex} ${CSS.h_65} ${CSS.margin_auto} ${CSS.flex_gap}`}
            >
              <div className={CSS.donut_chart_container}>
                <DonutChart
                  label={customerGraphData?.map((el) => el?.label)}
                  percentages={customerGraphData?.map((el) => el?.percentage)}
                />
              </div>
              <div className={CSS.chart_info_container}>
                <ChartInfo data={customerGraphData} filter={sentimentFilter} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
