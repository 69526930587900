import React, { memo } from "react";
import CSS from "./image.container.module.scss";

interface ImageContainerProps {
  id?: string;
  src?: string;
  className?: string;
}

const ImageContainerAtom: React.FunctionComponent<ImageContainerProps> = ({
  id = "",
  src = "",
  className = "",
}) => {
  return (
    <div
      id={"data-testid-image-container" + id}
      className={`${CSS.cs_image_size}` + className}
    >
      <img src={src} alt="table-img" className={CSS.cs_image_size} />
    </div>
  );
};

export default memo(ImageContainerAtom);
