import { sellerRepliedEmailTemplate } from "../../email-template/email-template.constants";

export const defaultQuestionAnswerConfig = {
  subject: "Your Query Has Been Answered!",
  preheader_text: "Seller Replied!",
  provider: "",
  seller_reply_email_content: sellerRepliedEmailTemplate,
  is_enabled: true,
};

export const defaultProviderOptions = [
  {
    text: "Default",
    value: "default",
  },
  {
    text: "SMTP",
    value: "smtp",
  },
];
