import React, { useState } from "react";
import TextAtom from "../../atoms/text/text";
import CollapseImageMinus from "../../../assets/collapse-btn.png";
import CollapseImagePlus from "../../../assets/collapse-btn-plus.png";
import CSS from "./collapsible.molecule.module.scss";

interface CollapsibleProps {
  title: string;
  subtitle?: string;
  subTitleType?: string;
  id?: string;
  type?: string;
  padding?: string;
  width?: string;
  height?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  className?: string;
}

const Collapsible: React.FC<React.PropsWithChildren<CollapsibleProps>> = ({
  title,
  subtitle = "",
  subTitleType,
  id,
  children,
  type = "label",
  padding,
  width = "30px",
  height = "30px",
  className = "",
  titleClassName = "",
  subTitleClassName = "",
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div data-testid={"text" + id} className={CSS["cs-pointer"]}>
      <div
        style={{ padding: padding }}
        className={`${CSS["cs-dis-flex"]} ${CSS["cs-jc-sb"]} ${
          className ? className : ""
        }`}
        onClick={handleToggle}
      >
        <div>
          <TextAtom
            className={titleClassName}
            text={title}
            type={type as string}
            id="collapsible"
          />
          <TextAtom
            className={subTitleClassName}
            text={subtitle}
            type={subTitleType as string}
            id="collapsible"
          />
        </div>
        {!isCollapsed && (
          <img
            src={CollapseImageMinus}
            style={{ width: width, height: height }}
          />
        )}
        {isCollapsed && (
          <img
            src={CollapseImagePlus}
            style={{ width: width, height: height }}
          />
        )}
      </div>

      {!isCollapsed && children}
    </div>
  );
};

export default Collapsible;
