import axios from "axios";
import {
  ISmartControlsConfig,
  ISmartControlsPostConfig,
} from "../pages/smart-controls/smart-controls.component";
import { getApplicationInfo, getCompanyId } from "../utils";

interface IGetSmartControlsResponse {
  message: string;
  data: ISmartControlsConfig;
}

interface IUpdateSmartControlsResponse {
  message: string;
  data: ISmartControlsPostConfig[];
}

export const getSmartControls = async () => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetSmartControlsResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/smart-controls/configs?company_id=${getCompanyId()}`
  );
};

export const updateSmartControls = async (
  payload: ISmartControlsPostConfig
) => {
  const { applicationId } = getApplicationInfo();

  return axios.patch<IUpdateSmartControlsResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/smart-controls/configs?company_id=${getCompanyId()}`,
    payload
  );
};
