import CSS from "./pricing-card.module.scss";
import { PricingPlan } from "../../../pages/payment/payment.types";
import { createSubscription } from "../../../services/subscription.service";

interface IPricingCardProps {
  plan: PricingPlan;
}

const PricingCard: React.FunctionComponent<IPricingCardProps> = ({ plan }) => {
  const handleSelectPlan = async () => {
    try {
      const res = await createSubscription({
        yearly_plan: plan.yearly_plan,
        name: plan.name,
        plan_type: plan.plan_type,
        currency: plan.price.currency,
        frequency: plan.yearly_plan ? "year" : "month",
        amount: plan.price.amount,
      });
      if (res?.data) {
        window.location.href = res?.data?.data?.confirmUrl;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={`${CSS.card} ${plan?.is_most_popular ? CSS.popular : ""}`}
      key={plan?._id}
    >
      <div className={CSS.card_heading}>
        {plan?.display_name}
      </div>
      <div className={CSS.card_tagline}>{plan?.tagline}</div>
      <div className={CSS.card_text}>
        <span className={CSS.card_pricing}>{"₹" + plan?.price?.amount}</span>/
        {plan?.yearly_plan ? 'year' : 'month'}
      </div>
      <div className={CSS.card_details_list}>
        {plan?.features?.map((feature: string, index: number) => {
          return (
            <div key={feature}>
              <div className={CSS.card_text}>{feature}</div>
              {index !== plan?.features?.length - 1 && (
                <div className={`${CSS.card_text} mt-10`}>+</div>
              )}
            </div>
          );
        })}
      </div>
      <div className={CSS.card_button}>
        <button
          onClick={handleSelectPlan}
          className={`${CSS.buttonStyle} ${plan?.is_most_popular ? CSS.popularPlanButtonStyles : CSS.selectPlanButtonStyles}`}
        >
          Select Plan
        </button>
      </div>
    </div>
  );
};

export default PricingCard;
