import { useState, useEffect, useRef } from "react";
import TextAtom from "../../components/atoms/text/text";
import ToggleBox from "../../components/molecules/toggle-box/toggle-box.molecule";
import {
  getSmartControls,
  updateSmartControls,
} from "../../services/smart-controls.service";

import CSS from "./smart-controls.module.scss";
import { useSubscriptionContext } from "../../context/subscription-context";
import { useNavigate } from "react-router-dom";
import useDidMountEffect from "../../hooks/useDidMountEffect";

export interface ISmartControlsConfig {
  text_moderation: boolean;
  image_moderation: boolean;
  sentimental_analysis: boolean;
}

export interface ISmartControlsPostConfig {
  text_moderation: boolean;
  image_moderation: boolean;
  sentimental_analysis: boolean;
}

const SmartControls = () => {
  const [textModeration, setTextModeration] = useState<boolean>(false);
  const [imageModeration, setImageModeration] = useState<boolean>(false);
  const [sentimental, setSentimental] = useState<boolean>(false);
  const { subscription } = useSubscriptionContext();
  const navigate = useNavigate();
  const isInitialStateLoaded = useRef<boolean>(true);

  const onUpgradeClick = () => {
    navigate("/payment");
  };

  useEffect(() => {
    (async () => {
      if (subscription?.plan_type === "basic") {
        await setImageModeration(false);
        await setSentimental(false);
        await setTextModeration(false);

        isInitialStateLoaded.current = false;
      } else if (subscription?.plan_type !== "basic") {
        (async () => {
          try {
            const res = await getSmartControls();
            if (res?.data?.data) {
              const {
                text_moderation,
                image_moderation,
                sentimental_analysis,
              } = res.data.data;
              setTextModeration(text_moderation);
              setImageModeration(image_moderation);
              setSentimental(sentimental_analysis);
            }
          } catch (err) {
            console.log(err);
          } finally {
            isInitialStateLoaded.current = false;
          }
        })();
      }
    })();
  }, [subscription]);

  useDidMountEffect(() => {
    if (!isInitialStateLoaded.current) {
      (async () => {
        try {
          const smartControlObj: ISmartControlsPostConfig = {
            text_moderation: textModeration,
            image_moderation: imageModeration,
            sentimental_analysis: sentimental,
          };
          const res = await updateSmartControls(smartControlObj);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [textModeration, imageModeration, sentimental]);

  return (
    <div>
      <div className={`cs-bm-24 ${CSS.heading}`}>
        <TextAtom
          text={"Smart Controls"}
          id={"section heading"}
          type="section-heading"
        />
        {subscription?.plan_type === "basic" && (
          <div>
            <button className={CSS.upgrade} onClick={onUpgradeClick}>
              Upgrade to PRO
            </button>
          </div>
        )}
      </div>
      <div className={CSS.cards}>
        <div className={CSS.card}>
          <div className="cs-tp-4 cs-bp-4">
            <ToggleBox
              id="toggle-text-moderation"
              value={textModeration}
              onToggle={() => setTextModeration(!textModeration)}
              disabled={subscription?.plan_type === "basic"}
            >
              <TextAtom
                id="text-moderation-heading"
                text="Text Moderation"
                type="heading"
              />
            </ToggleBox>
          </div>
          <div className={CSS.description}>
            Our Text Moderation feature ensures a safe and positive environment
            for your reviews. Any abusive or inappropriate content submitted by
            customers will be automatically marked as ***** and marked as
            Inappropriate, and customers will be reported to the seller even if
            all the reviews are enabled for auto publish.
          </div>
        </div>
        <div className={CSS.card}>
          <div className="cs-tp-4 cs-bp-4">
            <ToggleBox
              id="toggle-image-moderation"
              value={imageModeration}
              onToggle={() => setImageModeration(!imageModeration)}
              disabled={subscription?.plan_type === "basic"}
            >
              <TextAtom
                id="image-moderation-heading"
                text="Image Moderation"
                type="heading"
              />
            </ToggleBox>
          </div>
          <div className={CSS.description}>
            With Image Moderation, rest assured that only appropriate and
            relevant images will be shown on the marketplace. Our system flags
            and filters out any unsuitable images, ensuring a visually appealing
            and trustworthy experience for your customers. Reviews will be
            marked as Inappropriate, and customers will be reported to the
            seller even if all the reviews are enabled for auto publish
          </div>
        </div>
        <div className={CSS.card}>
          <div className="cs-tp-4 cs-bp-4">
            <ToggleBox
              id="Sentimental Analysis"
              value={sentimental}
              onToggle={() => setSentimental(!sentimental)}
              disabled={subscription?.plan_type === "basic"}
            >
              <TextAtom
                id="image-moderation-heading"
                text="Sentimental Analysis"
                type="heading"
              />
            </ToggleBox>
          </div>
          <div className={CSS.description}>
            Unlock the true sentiment of your customers with Sentimental
            Analysis. Our AI-driven technology categorises reviews as positive,
            neutral or negative, helping you understand customer feedback at a
            glance. Say goodbye to unstructured text - sentiment analysis
            automatically tags and processes vast amounts of data in an
            efficient and cost-effective way.
          </div>
        </div>
        {/* <div className={CSS.card}>
          <div className="cs-tp-4 cs-bp-4">
            <ToggleBox
              id="Smart Assist"
              value={smartAssist}
              onToggle={() => setSmartAssist(!smartAssist)}
              disabled={true}
            >
              <TextAtom
                id="image-moderation-heading"
                text="Smart Assist"
                type="heading"
              />
            </ToggleBox>
          </div>
          <div className={CSS.description}>
            Empower your store with Smart Assist! This cutting-edge feature
            reveals trending topics mentioned by other customers for specific
            products while customers write a product review. This enables to
            write quality reviews designed to attract new customers using A.I.
            and machine learning algorithms.
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SmartControls;
