export const TableGridColumn = [
  {
    render: "",
  },
  {
    title: "Review",
    dataIndex: "review",
    key: "review",
  },
  {
    title: "Order ID",
    dataIndex: "order_id",
    key: "order_id",
  },
  {
    title: "Rating",
    dataIndex: "rating",
    key: "rating",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
];

export const RowPerPageLimit = [
  {
    text: "10",
    value: 10,
  },
  {
    text: "20",
    value: 20,
  },
  {
    text: "30",
    value: 30,
  },
  {
    text: "40",
    value: 40,
  },
  {
    text: "50",
    value: 50,
  },
];
