import CSS from "./progress-bar.module.scss";

export const ProgressBar = (props) => {
  return (
    <div
      className={CSS.progress_container}
      style={{ backgroundColor: props.bgColor }}
    >
      <div
        style={{ width: `${100 - props.percentage}%` }}
        className={CSS.progress}
      ></div>
    </div>
  );
};
