import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";


interface IConfigContextProvider {
    children: React.ReactNode;
}

interface IConfigContext {
    config: any;
    setConfig: React.Dispatch<React.SetStateAction<any>>
}

const ConfigContext = createContext<IConfigContext>({
    config: {},
    setConfig: () => {
    }
});

export function ConfigContextProvider({ children }: IConfigContextProvider) {
    const [config, setConfig] = useState<any>();
    return <ConfigContext.Provider value={{ config, setConfig }}>
        {children}
    </ConfigContext.Provider>
}

export function useConfigContext() {
    return useContext<IConfigContext>(ConfigContext);
}