import { Dialog } from "@gofynd/nitrozen-react";

interface ModalProps {
  id: string;
  showModal: boolean;
  children: JSX.Element;
  title: string;
  kind: "dialog" | "acknowledgement" | "informational";
  positiveButtonLabel?: string;
  negativeButtonLabel?: string;
  handlePositiveClick?: () => void;
  handleNegativeClick?: () => void;
  handleCloseModal: () => void;
  isClosable?: boolean;
  style?: Object
}

const Modal = ({
  id,
  showModal,
  children,
  title,
  kind,
  positiveButtonLabel,
  negativeButtonLabel,
  handlePositiveClick,
  handleNegativeClick,
  handleCloseModal,
  isClosable,
  style
}: ModalProps): JSX.Element => {
  return (
    <div className="custom-modal">
      <Dialog
        isVisible={showModal}
        closeHandle={handleCloseModal}
        id={"modal" + id}
        isClosable={isClosable}
        kind={kind}
        negativeButtonLabel={negativeButtonLabel}
        onNegativeResponse={handleNegativeClick}
        onPositiveResponse={handlePositiveClick}
        positiveButtonLabel={positiveButtonLabel}
        size="s"
        title={title}
        style={style}
      >
        {children}
      </Dialog>
    </div>
  );
};

export default Modal;
