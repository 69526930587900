import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigContextProvider } from "./context/config-context";
import { EmailContextProvider } from "./pages/manage-emails/context/email-context";
import { SubscriptionContextProvider } from "./context/subscription-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SubscriptionContextProvider>
        <ConfigContextProvider>
          <EmailContextProvider>
            <App />
          </EmailContextProvider>
        </ConfigContextProvider>
      </SubscriptionContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
