import axios from "axios";
import {
  ApplicationClient,
  ApplicationConfig,
} from "@gofynd/fdk-client-javascript";

import { getApplicationInfo, getCompanyId } from "../utils";

export type ICatalogCategory = {
  slug: string;
  name: string;
  _id: string;
  level: number;
  is_active: boolean;
  uid: number;
};

interface IGetCatalogResponse {
  message: string;
  ok: boolean;
  data: [
    {
      categories: ICatalogCategory[];
    }
  ];
}

interface IGetCatalogParams {
  pageSize: number;
  pageNo: number;
}

export const getCatalogCategories = async ({
  pageSize,
  pageNo,
}: IGetCatalogParams) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetCatalogResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/catalogs/categories?company_id=${getCompanyId()}&page_size=${pageSize}&page_no=${pageNo}`
  );
};
