export const submitReviewEmailTemplate = `
<!DOCTYPE html>
<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
    <meta http-equiv="refresh" content="time; URL=new_url" />
    <title>{{title}}</title>
    <style>
      :root {
        /* Font/text values */
        --unnamed-font-family-nitrozentype-medium: NitrozenType-Medium;
        --unnamed-font-family-nitrozentype-bold: NitrozenType-Bold;
        --unnamed-font-family-nitrozentype-light: NitrozenType-Light;
      }

      @font-face {
        font-family: "NitrozenType-Light";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-Light_0.ttf)
          format("truetype");
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: "NitrozenType-Medium";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-Medium_0.ttf)
          format("truetype");
        font-style: normal;
      }

      @font-face {
        font-family: "NitrozenType-Bold";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-Bold_0.ttf)
          format("truetype");
        font-weight: bold;
        font-style: normal;
      }

      @font-face {
        font-family: "NitrozenType";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-MediumItalic_0.ttf)
          format("truetype");
        font-weight: bold;
        font-style: italic;
      }

      @font-face {
        font-family: "NitrozenType";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-LightItalic_0.ttf)
          format("truetype");
        font-weight: normal;
        font-style: italic;
      }

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }
    </style>
  </head>

  <body
    class="order_cnf_bg"
    style="
      background: #f4f4f4;
      font-family: var(--unnamed-font-family-nitrozentype-light), sans-serif;
      padding: 0px;
      -webkit-font-smoothing: antialiased;
      color: rgba(0, 0, 0, 0.65);
    "
  >
    <table
      border="0"
      cellpadding="0"
      cellspacing="0"
      height="100%"
      width="600px"
      align="center"
      style="
        letter-spacing: 0px;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        margin: auto;
        max-width: 640px;
        width: 100%;
        background: rgb(256, 256, 256);
      "
    >
      <tr class="header">
        <td align="center" valign="top" class="border">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            height="100%"
            width="100%"
            align="center"
          >
            <tr>
              <td align="center" style="padding: 60px 0; text-align: center">
                <a href="{{domain}}" target="_blank">
                  <img
                    src="{{logo_url}}"
                    alt="{{application_name}} logo"
                    style="max-height: 50px"
                  />
                </a>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="order-status">
        <td align="center" valign="top">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="main-border-tb"
          >
            <tbody>
              <tr>
                <td style="text-align: center">
                  <div style="margin-bottom: 24px; text-align: center">
                    <img
                      src="https://cdn.pixelbin.io/v2/zenitsu/original/Icon-yuFTqXj8n.png"
                      alt="status"
                      style="width: 72px; height: 72px"
                    />
                    <h1
                      style="
                        font-family: var(
                            --unnamed-font-family-nitrozentype-medium
                          ),
                          sans-serif;
                        font-weight: 900;
                        letter-spacing: -0.03em;
                        margin-top: 24px;
                        color: #000000;
                        font-size: 32px;
                        line-height: 42px;
                      "
                    >
                      Rate Your Purchase!
                    </h1>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr class="order-text">
        <td style="width: 100%; text-align: center">
          <div
            style="
              font-family: var(--unnamed-font-family-nitrozentype-light),
                sans-serif;
              text-align: center;
              font-size: 18px;
              font-weight: 500;
            "
          >
            <p
              style="
                font-family: var(--unnamed-font-family-nitrozentype-medium),
                  sans-serif;
                margin: 0px;
                font-size: 18px;
                line-height: 25.2px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.65);
              "
            >
              Dear {{name}},
            </p>
            <p
              style="
                font-family: var(--unnamed-font-family-nitrozentype-light);
                margin: 0px;
                line-height: 25.2px;
                padding: 24px 48px;
                font-weight: 300;
                font-size: 18px;
                line-height: 25.2px;
              "
            >
              Thanks for your recent purchase. We hope you’re enjoying the product and can take a moment to share your experiences with it.
            </p>
            <p
              style="
                font-family: var(--unnamed-font-family-nitrozentype-light);
                margin: 0px;
                line-height: 25.2px;
                font-weight: 300;
                font-size: 18px;
                line-height: 25.2px;
              "
            >
              Your feedback matters greatly to us.
            </p>
          </div>
        </td>
      </tr>
      <tr class="order-details">
        <td>
          <div
            style="
              box-sizing: border-box;
              padding: 24px;
              width: 100%;
              max-width: 544px;
              margin: 0 auto;
              border: 1px solid #b5b5b5;
              border-radius: 12px;
              margin-top: 24px;
            "
          >
            <div
              style="
                width: 100%;
                max-width: 496px;
              "
            >
              <div
                style="
                  width: 70px;
                  height: 70px;
                  display: inline-block;
                  margin-right: 12px;
                "
              >
                <img
                  src="{{product_image}}"
                  style="width: 100%; height: 100%; border-radius: 12px"
                />
              </div>
              <div
                style="
                  width: calc(100% - 87px);
                  padding: 0px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 18px;
                "
              >
                <div style="padding: 0px; margin-bottom: 4px; overflow: hidden">
                  <h3
                    style="
                      float: left;
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      margin: 0;
                      font-weight: 700;
                      font-size: 14px;
                      text-transform: uppercase;
                      line-height: normal;
                    "
                  >
                    {{product_brand}}
                  </h3>
                </div>
                <div style="padding: 0px">
                  <div style="margin-top: 4px">
                    <p
                      style="
                        display: inline;
                        font-family: var(
                            --unnamed-font-family-nitrozentype-medium
                          ),
                          sans-serif;
                        padding-right: 14px;
                        font-weight: 500;
                        font-size: 14px;
                      "
                    >
                      {{product_name}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr class="submit-review">
        <td>
          <div
            style="
              box-sizing: border-box;
              padding: 24px;
              width: 100%;
              height: 100%;
              max-width: 544px;
              margin: 32px auto;
              border: 1px solid #b5b5b5;
              border-radius: 12px;
            "
          >
            <div style="text-align: center">
              <p
                style="
                  font-family: var(--unnamed-font-family-nitrozentype-medium),
                    sans-serif;
                  margin: 0px;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  color: #333333;
                "
              >
                Rate Your Purchase!
              </p>
            </div>
            <div
              style="
                width: 100%;
                height: 100%;
                margin-top: 16px;
                margin-bottom: 32px;
                text-align: center;
              "
            >
              {% for i in range(0, overall_rating) -%}
              <div style="width: 36px; height: 36px; display: inline-block">
                <img
                  src="https://cdn.pixelbin.io/v2/zenitsu/original/starRating-lzcxDjgXo.png"
                  alt="star"
                  style="width: 100%; height: 100%; border-radius: 24px"
                />
              </div>
              {%- endfor %}
            </div>
            <div
              style="
                text-align: center;
                width: 100%;
                height: 100%;
                margin-bottom: 24px;
              "
            >
              <a
                style="
                  width: 100%;
                  height: 100%;
                  padding: 12px 32px;
                  background: #000;
                  border-radius: 4px;
                  text-transform: uppercase;
                  font-family: var(--unnamed-font-family-nitrozentype-medium);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: -0.005em;
                  color: #fff;
                  text-decoration: none;
                  cursor: pointer;
                "
                href="{{submit_review_link}}"
                target="_blank"
              >
                Submit Review
              </a>
            </div>
          </div>
        </td>
      </tr>
      <tr class="footer">
        <td>
          <div
            style="
              color: rgba(0, 0, 0, 0.65);
              font-size: 16px;
              margin-top: 12px;
              width: 100%;
              text-align: center;
            "
          >
            <div
              style="
                padding: 24px;
                background: rgba(46, 49, 190, 0.1);
                width: 100%;
                box-sizing: border-box;
              "
            >
              <h2
                style="
                  color: #141414;
                  margin: 0;
                  letter-spacing: -0.005em;
                  font-family: var(--unnamed-font-family-nitrozentype-medium),
                    sans-serif;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 24px;
                "
              >
                Need Help?
              </h2>
              <p
                style="
                  color: #130028;
                  margin: 12px 0 0 0;
                  letter-spacing: -0.005em;
                  font-family: var(--unnamed-font-family-nitrozentype-medium),
                    sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  transform: matrix(1, 0, 0, 1, 0, 0);
                "
              >
                For queries or any assistance, mail us at
                <a
                  style="color: #2e31be; text-decoration: none"
                  href="mailto:{{support_email}}"
                  >{{support_email}}
                </a>
              </p>
            </div>
            <div
              style="
                box-sizing: border-box;
                text-align: center;
                padding: 24px 0px 0px;
              "
            >
              <div
                style="
                  width: 100%;
                  max-width: 564px;
                  padding: 0px;
                  margin: 0 auto;
                "
              >
                <div class="social-urls" style="margin: 16px 0">
                  {%if twitter_url!= null and twitter_url.length>0%}
                  <div style="display: inline-block; padding: 0 10px">
                    <a href="{{twitter_url}}" target="_blank">
                      <img
                        src="https://cdn.pixelbin.io/v2/aged-sea-29a207/original/Twitter24.png"
                        style="width: 24px; height: 24px"
                      />
                    </a>
                  </div>
                  {%endif%} {%if facebook_url!= null and
                  facebook_url.length>0%}
                  <div style="display: inline-block; padding: 0 10px">
                    <a href="{{facebook_url}}" target="_blank">
                      <img
                        src="https://cdn.pixelbin.io/v2/aged-sea-29a207/original/Facebook24.png"
                        style="width: 24px; height: 24px"
                      />
                    </a>
                  </div>
                  {%endif%} {%if insta_url!= null and
                  insta_url.length>0%}
                  <div style="display: inline-block; padding: 0 10px">
                    <a href="{{insta_url}}" target="_blank">
                      <img
                        src="https://cdn.pixelbin.io/v2/aged-sea-29a207/original/Instagram24.png"
                        style="width: 24px; height: 24px"
                      />
                    </a>
                  </div>
                  {%endif%}
                </div>
                <div style="text-align: center">
                  {%if copyright_text!=null%}
                  <p
                    class="copyright-text"
                    style="
                      letter-spacing: -0.005em;
                      font-weight: 700;
                      font-size: 12px;
                      line-height: 16px;
                      margin: 0;
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                    "
                  >
                    {{copyright_text}}
                  </p>
                  {%endif%}
                  <p
                    style="
                      letter-spacing: -0.005em;
                      margin: 0;
                      font-family: var(--unnamed-font-family-nitrozentype-light),
                        sans-serif;
                      font-weight: 300;
                      font-size: 12px;
                      line-height: 16px;
                    "
                  >
                    {{application_name}}, {{address_line}},
                    {{city_pincode}}
                  </p>
                </div>
                <div
                  style="
                    overflow: hidden;
                    text-align: center;
                    width: 100%;
                    font-weight: 700;
                    font-size: 12px;
                    margin: 16px 0 24px 0;
                  "
                >
                  <div
                    style="
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      float: left;
                      width: 32.33%;
                      line-height: 16px;
                    "
                  >
                    <a
                      style="color: rgba(0, 0, 0, 0.65); text-decoration: none"
                      href="{{privacy_policy}}"
                      target="_blank"
                      >Privacy</a
                    >
                  </div>
                  <div
                    style="
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      font-weight: 700;
                      float: left;
                      width: 33.33%;
                      line-height: 16px;
                      border: 1px solid #e0e0e0;
                      border-top: none;
                      border-bottom: none;
                    "
                  >
                    <a
                      style="color: rgba(0, 0, 0, 0.65); text-decoration: none"
                      href="{{shipping_policy}}"
                      target="_blank"
                      >Shipping</a
                    >
                  </div>
                  <div
                    style="
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      font-weight: 700;
                      float: left;
                      width: 33.33%;
                      line-height: 16px;
                    "
                  >
                    <a
                      style="color: rgba(0, 0, 0, 0.65); text-decoration: none"
                      href="{{returns_policy}}"
                      target="_blank"
                      >Returns</a
                    >
                  </div>
                </div>
              </div>
              <div
                style="
                  font-weight: 500;
                  padding: 12px;
                  width: 100%;
                  box-sizing: border-box;
                  border-top: 1px solid #e0e0e0;
                "
              >
                <p
                  style="
                    font-family: var(--unnamed-font-family-nitrozentype-light),
                      sans-serif;
                    margin: 0;
                    text-align: center;
                    font-size: 12px;
                    line-height: 24px;
                  "
                >
                  <span
                    style="
                      font-weight: 700;
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                    "
                    >Note:</span
                  >
                  This is a system generated email, please do not reply
                </p>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </body>
</html>`;

export const followUpEmailTemplate = `<!DOCTYPE html>
<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
    <title>{{title}}</title>
    <style>
      :root {
        /* Font/text values */
        --unnamed-font-family-nitrozentype-medium: NitrozenType-Medium;
        --unnamed-font-family-nitrozentype-bold: NitrozenType-Bold;
        --unnamed-font-family-nitrozentype-light: NitrozenType-Light;
      }

      @font-face {
        font-family: "NitrozenType-Light";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-Light_0.ttf)
          format("truetype");
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: "NitrozenType-Medium";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-Medium_0.ttf)
          format("truetype");
        font-style: normal;
      }

      @font-face {
        font-family: "NitrozenType-Bold";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-Bold_0.ttf)
          format("truetype");
        font-weight: bold;
        font-style: normal;
      }

      @font-face {
        font-family: "NitrozenType";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-MediumItalic_0.ttf)
          format("truetype");
        font-weight: bold;
        font-style: italic;
      }

      @font-face {
        font-family: "NitrozenType";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-LightItalic_0.ttf)
          format("truetype");
        font-weight: normal;
        font-style: italic;
      }

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }
    </style>
  </head>

  <body
    class="order_cnf_bg"
    style="
      background: #f4f4f4;
      font-family: var(--unnamed-font-family-nitrozentype-light), sans-serif;
      padding: 0px;
      -webkit-font-smoothing: antialiased;
      color: rgba(0, 0, 0, 0.65);
    "
  >
    <table
      border="0"
      cellpadding="0"
      cellspacing="0"
      height="100%"
      width="600px"
      align="center"
      style="
        letter-spacing: 0px;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        margin: auto;
        max-width: 640px;
        width: 100%;
        background: rgb(256, 256, 256);
      "
    >
      <tr class="header">
        <td align="center" valign="top" class="border">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            height="100%"
            width="100%"
            align="center"
          >
            <tr>
              <td align="center" style="padding: 60px 0; text-align: center">
                <a href="{{domain}}" target="_blank">
                  <img
                    src="{{logo_url}}"
                    alt="{{application_name}} logo"
                    style="max-height: 50px"
                  />
                </a>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="order-status">
        <td align="center" valign="top">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="main-border-tb"
          >
            <tbody>
              <tr>
                <td style="text-align: center">
                  <div style="margin-bottom: 24px; text-align: center">
                    <img
                      src="https://cdn.pixelbin.io/v2/zenitsu/original/Icon-yuFTqXj8n.png"
                      alt="status"
                      style="width: 72px; height: 72px"
                    />
                    <h1
                      style="
                        font-family: var(
                            --unnamed-font-family-nitrozentype-medium
                          ),
                          sans-serif;
                        font-weight: 900;
                        letter-spacing: -0.03em;
                        margin-top: 24px;
                        color: #000000;
                        font-size: 32px;
                        line-height: 42px;
                      "
                    >
                      Your Review Matters!
                    </h1>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr class="order-text">
        <td style="width: 100%; text-align: center">
          <div
            style="
              font-family: var(--unnamed-font-family-nitrozentype-light),
                sans-serif;
              text-align: center;
              font-size: 18px;
              font-weight: 500;
            "
          >
            <p
              style="
                font-family: var(--unnamed-font-family-nitrozentype-medium),
                  sans-serif;
                margin: 0px;
                font-size: 18px;
                line-height: 25.2px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.65);
              "
            >
              Dear {{name}},
            </p>
            <p
              style="
                font-family: var(--unnamed-font-family-nitrozentype-light);
                margin: 0px;
                line-height: 25.2px;
                padding: 24px 48px;
                font-weight: 300;
                font-size: 18px;
                line-height: 25.2px;
              "
            >
              Thank you for shopping with us recently - we hope you were happy
              with your purchase. We value your feedback and would love to hear
              from you!
            </p>
            <p
              style="
                font-family: var(--unnamed-font-family-nitrozentype-light);
                margin: 0px;
                line-height: 25.2px;
                font-weight: 300;
                font-size: 18px;
                line-height: 25.2px;
              "
            >
              Please take a moment to rate us.
            </p>
          </div>
        </td>
      </tr>
      <tr class="order-details">
        <td>
          <div
            style="
              box-sizing: border-box;
              padding: 24px;
              width: 100%;
              max-width: 544px;
              margin: 0 auto;
              border: 1px solid #b5b5b5;
              border-radius: 12px;
              margin-top: 24px;
            "
          >
            <div style="width: 100%; max-width: 496px">
              <div
                style="
                  width: 70px;
                  height: 70px;
                  display: inline-block;
                  margin-right: 12px;
                "
              >
                <img
                  src="{{product_image}}"
                  style="width: 100%; height: 100%; border-radius: 12px"
                />
              </div>
              <div
                style="
                  width: calc(100% - 87px);
                  padding: 0px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 18px;
                "
              >
                <div style="padding: 0px; margin-bottom: 4px; overflow: hidden">
                  <h3
                    style="
                      float: left;
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      margin: 0;
                      font-weight: 700;
                      font-size: 14px;
                      text-transform: uppercase;
                      line-height: normal;
                    "
                  >
                    {{product_brand}}
                  </h3>
                </div>
                <div style="padding: 0px">
                  <div style="margin-top: 4px">
                    <p
                      style="
                        display: inline;
                        font-family: var(
                            --unnamed-font-family-nitrozentype-medium
                          ),
                          sans-serif;
                        padding-right: 14px;
                        font-weight: 500;
                        font-size: 14px;
                      "
                    >
                      {{product_name}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr class="submit-review">
        <td>
          <div
            style="
              box-sizing: border-box;
              padding: 24px;
              width: 100%;
              height: 100%;
              max-width: 544px;
              margin: 32px auto;
              border: 1px solid #b5b5b5;
              border-radius: 12px;
            "
          >
            <div style="text-align: center">
              <p
                style="
                  font-family: var(--unnamed-font-family-nitrozentype-medium),
                    sans-serif;
                  margin: 0px;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  color: #333333;
                "
              >
                Rate Your Purchase!
              </p>
            </div>
            <div
              style="
                width: 100%;
                height: 100%;
                margin-top: 16px;
                margin-bottom: 32px;
                text-align: center;
              "
            >
              {% for i in range(0, overall_rating) -%}
              <div style="width: 36px; height: 36px; display: inline-block">
                <img
                  src="https://cdn.pixelbin.io/v2/zenitsu/original/starRating-lzcxDjgXo.png"
                  alt="star"
                  style="width: 100%; height: 100%; border-radius: 24px"
                />
              </div>
              {%- endfor %}
            </div>
            <div
              style="
                text-align: center;
                width: 100%;
                height: 100%;
                margin-bottom: 24px;
              "
            >
              <a
                style="
                  width: 100%;
                  height: 100%;
                  padding: 12px 32px;
                  background: #000;
                  border-radius: 4px;
                  text-transform: uppercase;
                  font-family: var(--unnamed-font-family-nitrozentype-medium);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: -0.005em;
                  color: #fff;
                  text-decoration: none;
                  cursor: pointer;
                "
                href="{{submit_review_link}}"
                target="_blank"
              >
                Submit Review
              </a>
            </div>
          </div>
        </td>
      </tr>
      <tr class="footer">
        <td>
          <div
            style="
              color: rgba(0, 0, 0, 0.65);
              font-size: 16px;
              margin-top: 12px;
              width: 100%;
              text-align: center;
            "
          >
            <div
              style="
                padding: 24px;
                background: rgba(46, 49, 190, 0.1);
                width: 100%;
                box-sizing: border-box;
              "
            >
              <h2
                style="
                  color: #141414;
                  margin: 0;
                  letter-spacing: -0.005em;
                  font-family: var(--unnamed-font-family-nitrozentype-medium),
                    sans-serif;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 24px;
                "
              >
                Need Help?
              </h2>
              <p
                style="
                  color: #130028;
                  margin: 12px 0 0 0;
                  letter-spacing: -0.005em;
                  font-family: var(--unnamed-font-family-nitrozentype-medium),
                    sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  transform: matrix(1, 0, 0, 1, 0, 0);
                "
              >
                For queries or any assistance, mail us at
                <a
                  style="color: #2e31be; text-decoration: none"
                  href="mailto:{{support_email}}"
                  >{{support_email}}
                </a>
              </p>
            </div>
            <div
              style="
                box-sizing: border-box;
                text-align: center;
                padding: 24px 0px 0px;
              "
            >
              <div
                style="
                  width: 100%;
                  max-width: 564px;
                  padding: 0px;
                  margin: 0 auto;
                "
              >
                <div class="social-urls" style="margin: 16px 0">
                  {%if twitter_url!== null and twitter_url.length>0%}
                  <div style="display: inline-block; padding: 0 10px">
                    <a href="{{twitter_url}}" target="_blank">
                      <img
                        src="https://cdn.pixelbin.io/v2/aged-sea-29a207/original/Twitter24.png"
                        style="width: 24px; height: 24px"
                      />
                    </a>
                  </div>
                  {%endif%} {%if facebook_url!== null and facebook_url.length>0%}
                  <div style="display: inline-block; padding: 0 10px">
                    <a href="{{facebook_url}}" target="_blank">
                      <img
                        src="https://cdn.pixelbin.io/v2/aged-sea-29a207/original/Facebook24.png"
                        style="width: 24px; height: 24px"
                      />
                    </a>
                  </div>
                  {%endif%} {%if insta_url!== null and insta_url.length>0%}
                  <div style="display: inline-block; padding: 0 10px">
                    <a href="{{insta_url}}" target="_blank">
                      <img
                        src="https://cdn.pixelbin.io/v2/aged-sea-29a207/original/Instagram24.png"
                        style="width: 24px; height: 24px"
                      />
                    </a>
                  </div>
                  {%endif%}
                </div>
                <div style="text-align: center">
                  {%if copyright_text!=null%}
                  <p
                    class="copyright-text"
                    style="
                      letter-spacing: -0.005em;
                      font-weight: 700;
                      font-size: 12px;
                      line-height: 16px;
                      margin: 0;
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                    "
                  >
                    {{copyright_text}}
                  </p>
                  {%endif%}
                  <p
                    style="
                      letter-spacing: -0.005em;
                      margin: 0;
                      font-family: var(--unnamed-font-family-nitrozentype-light),
                        sans-serif;
                      font-weight: 300;
                      font-size: 12px;
                      line-height: 16px;
                    "
                  >
                    {{application_name}}, {{address_line}}, {{city_pincode}}
                  </p>
                </div>
                <div
                  style="
                    overflow: hidden;
                    text-align: center;
                    width: 100%;
                    font-weight: 700;
                    font-size: 12px;
                    margin: 16px 0 24px 0;
                  "
                >
                  <div
                    style="
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      float: left;
                      width: 32.33%;
                      line-height: 16px;
                    "
                  >
                    <a
                      style="color: rgba(0, 0, 0, 0.65); text-decoration: none"
                      href="{{privacy_policy}}"
                      target="_blank"
                      >Privacy</a
                    >
                  </div>
                  <div
                    style="
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      font-weight: 700;
                      float: left;
                      width: 33.33%;
                      line-height: 16px;
                      border: 1px solid #e0e0e0;
                      border-top: none;
                      border-bottom: none;
                    "
                  >
                    <a
                      style="color: rgba(0, 0, 0, 0.65); text-decoration: none"
                      href="{{shipping_policy}}"
                      target="_blank"
                      >Shipping</a
                    >
                  </div>
                  <div
                    style="
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      font-weight: 700;
                      float: left;
                      width: 33.33%;
                      line-height: 16px;
                    "
                  >
                    <a
                      style="color: rgba(0, 0, 0, 0.65); text-decoration: none"
                      href="{{returns_policy}}"
                      target="_blank"
                      >Returns</a
                    >
                  </div>
                </div>
              </div>
              <div
                style="
                  font-weight: 500;
                  padding: 12px;
                  width: 100%;
                  box-sizing: border-box;
                  border-top: 1px solid #e0e0e0;
                "
              >
                <p
                  style="
                    font-family: var(--unnamed-font-family-nitrozentype-light),
                      sans-serif;
                    margin: 0;
                    text-align: center;
                    font-size: 12px;
                    line-height: 24px;
                  "
                >
                  <span
                    style="
                      font-weight: 700;
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                    "
                    >Note:</span
                  >
                  This is a system generated email, please do not reply
                </p>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </body>
</html>
`;

export const sellerRepliedEmailTemplate = `<!DOCTYPE html>
<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
    <title>{{title}}</title>
    <style>
      :root {
        /* Font/text values */
        --unnamed-font-family-nitrozentype-medium: NitrozenType-Medium;
        --unnamed-font-family-nitrozentype-bold: NitrozenType-Bold;
        --unnamed-font-family-nitrozentype-light: NitrozenType-Light;
      }

      @font-face {
        font-family: "NitrozenType-Light";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-Light_0.ttf)
          format("truetype");
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: "NitrozenType-Medium";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-Medium_0.ttf)
          format("truetype");
        font-style: normal;
      }

      @font-face {
        font-family: "NitrozenType-Bold";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-Bold_0.ttf)
          format("truetype");
        font-weight: bold;
        font-style: normal;
      }

      @font-face {
        font-family: "NitrozenType";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-MediumItalic_0.ttf)
          format("truetype");
        font-weight: bold;
        font-style: italic;
      }

      @font-face {
        font-family: "NitrozenType";
        src: url(https://cdn.pixelbin.io/v2/falling-surf-7c8bb8/fyprod/wrkr/misc/general/free/original/NitrozenType-LightItalic_0.ttf)
          format("truetype");
        font-weight: normal;
        font-style: italic;
      }

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }
    </style>
  </head>

  <body
    class="order_cnf_bg"
    style="
      background: #f4f4f4;
      font-family: var(--unnamed-font-family-nitrozentype-light), sans-serif;
      padding: 0px;
      -webkit-font-smoothing: antialiased;
      color: rgba(0, 0, 0, 0.65);
    "
  >
    <table
      border="0"
      cellpadding="0"
      cellspacing="0"
      height="100%"
      width="600px"
      align="center"
      style="
        letter-spacing: 0px;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        margin: auto;
        max-width: 640px;
        width: 100%;
        background: rgb(256, 256, 256);
      "
    >
      <tr class="header">
        <td align="center" valign="top" class="border">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            height="100%"
            width="100%"
            align="center"
          >
            <tr>
              <td align="center" style="padding: 60px 0; text-align: center">
                <a href="{{domain}}" target="_blank">
                  <img
                    src="{{logo_url}}"
                    alt="{{application_name}} logo"
                    style="max-height: 50px"
                  />
                </a>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="order-status">
        <td align="center" valign="top">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="main-border-tb"
          >
            <tbody>
              <tr>
                <td style="text-align: center">
                  <div style="margin-bottom: 24px; text-align: center">
                    <img
                      src="https://cdn.pixelbin.io/v2/zenitsu/original/Icon-yuFTqXj8n.png"
                      alt="status"
                      style="width: 72px; height: 72px"
                    />
                    <h1
                      style="
                        font-family: var(
                            --unnamed-font-family-nitrozentype-medium
                          ),
                          sans-serif;
                        font-weight: 900;
                        letter-spacing: -0.03em;
                        margin-top: 24px;
                        color: #000000;
                        font-size: 32px;
                        line-height: 42px;
                      "
                    >
                      Seller Replied!
                    </h1>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr class="order-text">
        <td style="width: 100%; text-align: center">
          <div
            style="
              font-family: var(--unnamed-font-family-nitrozentype-light),
                sans-serif;
              margin-bottom: 0px;
              text-align: center;
              font-size: 18px;
              font-weight: 500;
            "
          >
            <p
              style="
                font-family: var(--unnamed-font-family-nitrozentype-medium),
                  sans-serif;
                margin: 0px;
                font-size: 18px;
                line-height: 25.2px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.65);
              "
            >
              Dear {{name}},
            </p>
            <p
              style="
                font-family: var(--unnamed-font-family-nitrozentype-light);
                margin: 0px;
                line-height: 25.2px;
                padding: 24px 48px;
                font-weight: 300;
                font-size: 18px;
                line-height: 25.2px;
              "
            >
              You have got a reply of your review from seller
            </p>
            <p
              style="
                font-family: var(--unnamed-font-family-nitrozentype-medium),
                  sans-serif;
                margin: 0;
                font-size: 18px;
                line-height: 25.2px;
                margin: 0;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.65);
              "
            >
              Thank You for shopping with us.
            </p>
          </div>
        </td>
      </tr>
      <tr class="order-track" style="padding-top: 32px">
        <td style="width: 100%; text-align: center">
          <div style="margin: 32px">
            <a
              style="
                padding: 12px 32px;
                background: none;
                border-radius: 4px;
                border: 1px solid #8b8374;
                text-transform: uppercase;
                font-family: var(--unnamed-font-family-nitrozentype-medium);
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: normal;
                letter-spacing: -0.005em;
                color: #26201a;
                text-decoration: none;
                cursor: pointer;
              "
              href="{{view_more_link}}"
              target="_blank"
            >
              View Seller Reply
            </a>
          </div>
        </td>
      </tr>
      <tr class="footer">
        <td>
          <div
            style="
              color: rgba(0, 0, 0, 0.65);
              font-size: 16px;
              margin-top: 12px;
              width: 100%;
              text-align: center;
            "
          >
            <div
              style="
                padding: 24px;
                background: rgba(46, 49, 190, 0.1);
                width: 100%;
                box-sizing: border-box;
              "
            >
              <h2
                style="
                  color: #141414;
                  margin: 0;
                  letter-spacing: -0.005em;
                  font-family: var(--unnamed-font-family-nitrozentype-medium),
                    sans-serif;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 24px;
                "
              >
                Need Help?
              </h2>
              <p
                style="
                  color: #130028;
                  margin: 12px 0 0 0;
                  letter-spacing: -0.005em;
                  font-family: var(--unnamed-font-family-nitrozentype-medium),
                    sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  transform: matrix(1, 0, 0, 1, 0, 0);
                "
              >
                For queries or any assistance, mail us at
                <a
                  style="color: #2e31be; text-decoration: none"
                  href="mailto:{{support_email}}"
                  >{{support_email}}
                </a>
              </p>
            </div>
            <div
              style="
                box-sizing: border-box;
                text-align: center;
                padding: 24px 0px 0px;
              "
            >
              <div
                style="
                  width: 100%;
                  max-width: 564px;
                  padding: 0px;
                  margin: 0 auto;
                "
              >
                <div class="social-urls" style="margin: 16px 0">
                  {%if twitter_url!== null and twitter_url.length>0%}
                  <div style="display: inline-block; padding: 0 10px">
                    <a href="{{twitter_url}}" target="_blank">
                      <img
                        src="https://cdn.pixelbin.io/v2/aged-sea-29a207/original/Twitter24.png"
                        style="width: 24px; height: 24px"
                      />
                    </a>
                  </div>
                  {%endif%} {%if facebook_url!== null and
                  facebook_url.length>0%}
                  <div style="display: inline-block; padding: 0 10px">
                    <a href="{{facebook_url}}" target="_blank">
                      <img
                        src="https://cdn.pixelbin.io/v2/aged-sea-29a207/original/Facebook24.png"
                        style="width: 24px; height: 24px"
                      />
                    </a>
                  </div>
                  {%endif%} {%if insta_url!== null and
                  insta_url.length>0%}
                  <div style="display: inline-block; padding: 0 10px">
                    <a href="{{insta_url}}" target="_blank">
                      <img
                        src="https://cdn.pixelbin.io/v2/aged-sea-29a207/original/Instagram24.png"
                        style="width: 24px; height: 24px"
                      />
                    </a>
                  </div>
                  {%endif%}
                </div>
                <div style="text-align: center">
                  {%if copyright_text!=null%}
                  <p
                    class="copyright-text"
                    style="
                      letter-spacing: -0.005em;
                      font-weight: 700;
                      font-size: 12px;
                      line-height: 16px;
                      margin: 0;
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                    "
                  >
                    {{copyright_text}}
                  </p>
                  {%endif%}
                  <p
                    style="
                      letter-spacing: -0.005em;
                      margin: 0;
                      font-family: var(--unnamed-font-family-nitrozentype-light),
                        sans-serif;
                      font-weight: 300;
                      font-size: 12px;
                      line-height: 16px;
                    "
                  >
                    {{application_name}}, {{address_line}},
                    {{city_pincode}}
                  </p>
                </div>
                <div
                  style="
                    overflow: hidden;
                    text-align: center;
                    width: 100%;
                    font-weight: 700;
                    font-size: 12px;
                    margin: 16px 0 24px 0;
                  "
                >
                  <div
                    style="
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      float: left;
                      width: 32.33%;
                      line-height: 16px;
                    "
                  >
                    <a
                      style="color: rgba(0, 0, 0, 0.65); text-decoration: none"
                      href="{{privacy_policy}}"
                      target="_blank"
                      >Privacy</a
                    >
                  </div>
                  <div
                    style="
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      font-weight: 700;
                      float: left;
                      width: 33.33%;
                      line-height: 16px;
                      border: 1px solid #e0e0e0;
                      border-top: none;
                      border-bottom: none;
                    "
                  >
                    <a
                      style="color: rgba(0, 0, 0, 0.65); text-decoration: none"
                      href="{{shipping_policy}}"
                      target="_blank"
                      >Shipping</a
                    >
                  </div>
                  <div
                    style="
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                      font-weight: 700;
                      float: left;
                      width: 33.33%;
                      line-height: 16px;
                    "
                  >
                    <a
                      style="color: rgba(0, 0, 0, 0.65); text-decoration: none"
                      href="{{returns_policy}}"
                      target="_blank"
                      >Returns</a
                    >
                  </div>
                </div>
              </div>
              <div
                style="
                  font-weight: 500;
                  padding: 12px;
                  width: 100%;
                  box-sizing: border-box;
                  border-top: 1px solid #e0e0e0;
                "
              >
                <p
                  style="
                    font-family: var(--unnamed-font-family-nitrozentype-light),
                      sans-serif;
                    margin: 0;
                    text-align: center;
                    font-size: 12px;
                    line-height: 24px;
                  "
                >
                  <span
                    style="
                      font-weight: 700;
                      font-family: var(
                          --unnamed-font-family-nitrozentype-medium
                        ),
                        sans-serif;
                    "
                    >Note:</span
                  >
                  This is a system generated email, please do not reply
                </p>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </body>
</html>`;

export const defaultSubmitReviewEmailValues = {
  title: "Submit Review Email",
  overall_rating: 5,
  name: "Prashant",
  order_no: "FYFY5EAFFA099115C235",
  product_image: "https://picsum.photos/100",
  product_name: "Sample",
  product_brand: "Spykar",
  view_more_link: "link",
  submit_review_link: "link",
};

export const defaultFollowUpEmailValues = {
  title: "Follow Up Email",
  overall_rating: 5,
  name: "Prashant",
  order_no: "FYFY5EAFFA099115C235",
  product_image: "https://picsum.photos/100",
  product_name: "Sample",
  product_brand: "Spykar",
  view_more_link: "link",
  submit_review_link: "link",
};

export const defaultSellerRepliedEmailValues = {
  title: "Seller Reply Email",
  name: "Prashant",
  view_more_link: "link",
};
