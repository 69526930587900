import "./styles/index.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Router from "./routes/routes";
import { BreadCrumb } from "nitrozen-react-extension";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (link: string) => {
    navigate(link);
  };

  return (
    <div>
      {location.pathname.includes("features") && (
        <div className="bread-crumbs">
          <BreadCrumb handleClick={handleClick} />
        </div>
      )}
      <div>
        <Router />
      </div>
    </div>
  );
}

export default App;
