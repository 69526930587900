export const defaultTableData = [
  {
    key: "1",
    rowData: [
      {
        value: "What is your age?",
      },
    ],
  },
  {
    key: "2",
    rowData: [
      {
        value: "What is your skin type?",
      },
    ],
  },
];

export const defaultReviewFormConfig = {
  heading: "How would you rate this product?",
  review_heading: "Add a headline",
  review_body_heading: "Add a written review",
  ratings_by_features: true,
  smart_assist: true,
  show_additional_questions: false,
  show_sharing_link: true,
  submit_button_text: "Submit Your Review",
  additional_questions: [],
  is_active: true
};

