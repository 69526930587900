import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);

export const DonutChart = (props: any) => {
  const options = {
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 10,
      },
    },
    cutoutPercentage: 10, // Increase the value to create more spacing
  };
  const data = {
    responsive: true,
    datasets: [
      {
        data: props.percentages,
        label: ' ',
        backgroundColor: [
          "#2e31be",
          "#6162d2",
          "#a0a1e3",
          "#cccff0",
          "#e3e3fd",
        ],
        hoverBorderColor: "#fff",
      },
    ],
    interaction: {
      // intersect: true // Enable hovering interactions
    },
  };
  return <Doughnut data={data} options={options} width={200} height={300} />;
};
