import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";

export interface IEmailConfig {
  application_name: string;
  domain: string;
  logo_url: string;
  support_email: string;
  twitter_url: string;
  facebook_url: string;
  insta_url: string;
  copyright_text: string;
  address_line: string;
  city_pincode: string;
  privacy_policy: string;
  shipping_policy: string;
  returns_policy: string;
}

export interface IEmailProvider {
  items: [
    {
      from_address: [
        {
          name: string;
          email: string;
          is_default: boolean;
        }
      ];
      name: string;
      description: string;
      provider: string;
      slug: string;
      _id: string;
    }
  ];
}

export interface IEmailData {
  emailConfig: IEmailConfig;
  emailProviders: IEmailProvider;
}

interface IEmailContextProvider {
  children: React.ReactNode;
}

interface IEmailContext {
  emailData: IEmailData | {};
  setEmailData: React.Dispatch<React.SetStateAction<IEmailData>>;
}

const EmailContext = createContext<IEmailContext>({
  emailData: {},
  setEmailData: () => {},
});

export function EmailContextProvider({ children }: IEmailContextProvider) {
  const [emailData, setEmailData] = useState<IEmailData | {}>({});

  return (
    <EmailContext.Provider value={{ emailData, setEmailData }}>
      {children}
    </EmailContext.Provider>
  );
}

export function useEmailContext() {
  return useContext<IEmailContext>(EmailContext);
}
