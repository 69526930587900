import { useMemo } from "react";
import TextAtom from "../../../../../../../../components/atoms/text/text";
import { IProductReviewMedia } from "../../../../service/reviews.details.service";
import NsfwImage from "../../../../../../../../assets/nsfw.svg";
import CSS from "./media.container.module.scss";

interface IMediaContainer {
  reviewMedia: IProductReviewMedia[] | undefined;
}

const MediaContainer = ({ reviewMedia }: IMediaContainer) => {
  const images = useMemo(() => {
    return reviewMedia?.filter((media) => media?.type === "image") ?? [];
  }, [reviewMedia]);

  const videos = useMemo(() => {
    return reviewMedia?.filter((media) => media?.type === "video") ?? [];
  }, [reviewMedia]);

  return (
    <div className={`${CSS.cs_prd_card} cs-tm-15`}>
      {images?.length ? (
        <div>
          <TextAtom text="Images" type="header" className="cs-bm-10" />
          <div className={CSS.cs_prd_image_list_container}>
            {images?.map((image) => {
              return (
                <div key={image?._id}>
                  <img
                    className={CSS.cs_prd_image_list}
                    src={image?.is_nsfw ? NsfwImage : image?.url}
                    alt={`${"Product image" + image?._id}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}

      {videos?.length ? (
        <div className={`${images?.length ? "cs-tm-15" : ""}`}>
          <TextAtom text="Videos" type="header" className="cs-bm-10" />
          <div className={CSS.cs_prd_image_list_container}>
            {videos.map((video) => {
              return (
                <div key={video?._id}>
                  <video className={CSS.cs_prd_image_list} preload="metadata">
                    <source src={video?.url} type="video/mp4" />
                  </video>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MediaContainer;
