import axios from "axios";
import { getApplicationInfo, getCompanyId } from "../../../../../utils";

interface IGetQuestionAnswerProps {
  page_size: number;
  page_no: number;
  search?: string;
  status?: string;
}

export type IQuestionAnswerData = {
  _id: string;
  vote_count: {
    upvote: number;
    downvote: number;
  };
  question: {
    text: string;
  };
  entity: {
    type: string;
    id: string;
  };
  approval: {
    status: string;
  };
  created_by: {
    id: string;
    first_name: string;
    last_name: string;
    account_type: string;
  };
  application: string;
  created_at: string;
  updated_at: string;
  id: string;
  is_upvotable: boolean;
  is_downvotable: boolean;
  product: {
    slug: string;
    images: [
      {
        url: string;
      }
    ];
    uid: number;
    name: string;
    brand_name: string;
    review_metric: {
      rating_sum: number;
      rating_count: number;
      review_count: number;
    };
    short_description: string;
  };
};

export type IQuestionAnswerPage = {
  type: string;
  current: number;
  size: number;
  item_total: number;
  has_next: boolean;
};

export interface IGetQuestionAnswerResponse {
  items: IQuestionAnswerData[];
  page: IQuestionAnswerPage;
}

export const getQuestions = async (props: IGetQuestionAnswerProps) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetQuestionAnswerResponse>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/questions?company_id=${getCompanyId()}`,
    { params: props }
  );
};
