import React from "react";

import TextAtom from "../../atoms/text/text";
import CrossIcon from "./../../../assets/cross.svg";
import BorderAtom from "../../atoms/border/border.component";

import CSS from "./side-drawer.template.module.scss";

interface ISideDrawer {
  heading: string;
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  component: JSX.Element;
}

const SideDrawer: React.FunctionComponent<ISideDrawer> = ({
  heading = "",
  showDrawer = false,
  setShowDrawer,
  component = <></>,
}) => {
  let drawerClasses = CSS.side_drawer;

  if (showDrawer) {
    drawerClasses = `${CSS.side_drawer} ${CSS.open}`;
  }

  const handleCrossClick = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <>
      <div className={drawerClasses}>
        <div className={CSS.header}>
          <TextAtom
            fontWeight={700}
            id="text-heading"
            text={heading}
            type="heading-3"
          />
          <button className={CSS.cross_button} onClick={handleCrossClick}>
            <img src={CrossIcon} alt="cross-icon" />
          </button>
        </div>
        <div className={`cs-bm-24 cs-tm-24 ${CSS.w_full}`}>
          <BorderAtom borderColor="#ededed" borderSize="0.5px" />
        </div>
        {component}
      </div>
    </>
  );
};

export default React.memo(SideDrawer);
