import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import StarAtom from "../../../../components/atoms/star/star.atom";
import ActionBar from "./components/action-bar";
import CSS from "./review.detail.module.scss";
import VerifiedPurchase from "./components/verified";
import BadgeAtom from "../../../../components/atoms/badge/badge.atom";
import ProductReviewDetail from "./components/product-review-detail";
import ButtonAtom from "../../../../components/atoms/button/button.atom";
import BorderAtom from "../../../../components/atoms/border/border.component";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import generateIC from "./assets/icons/generate.png";
import ProductOverview from "./components/product-overview";
import {
  getProductReviewDetails,
  getReviewReply,
  IProductReviewDetail,
  IProductReviewReply,
  replyReview,
  updateReviewStatus,
} from "./service/reviews.details.service";
import { getGeneratedReply } from "../product-reviews/service/product.reviews.service";
import {
  showDangerToast,
  showSuccessToast,
} from "../../../../services/toast.service";

const ReviewDetails = () => {
  const [productReview, setProductReview] = useState<IProductReviewDetail>();
  const [reviewComment, setReviewComment] = useState<IProductReviewReply>();
  const [comment, setComment] = useState<string>("");
  const [sellerReply, setSellerReply] = useState<string>("");
  const { review_id } = useParams();

  const location = useLocation();

  useEffect(() => {
    getReviewDetail();
    getReply();
  }, []);

  useEffect(() => {
    if (reviewComment?.comment) {
      setSellerReply(reviewComment?.comment);
    }
  }, [reviewComment]);

  const getReviewDetail = async () => {
    try {
      const res = await getProductReviewDetails(review_id ?? "");

      if (res?.data) {
        setProductReview(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getReply = async () => {
    try {
      const res = await getReviewReply(review_id ?? "");

      if (res?.data?.items) {
        setReviewComment(res?.data?.items?.[0]);
      }

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRejectClick = async () => {
    try {
      const res = await updateReviewStatus(review_id ?? "", "rejected");
      if (res?.data) {
        showSuccessToast("Review Rejected Successfully");
        getReviewDetail();
      }
    } catch (err) {
      showDangerToast("Error in Rejecting Review");
      console.log(err);
    }
  };

  const handlePublishClick = async () => {
    try {
      const res = await updateReviewStatus(review_id ?? "", "published");
      if (res?.data) {
        showSuccessToast("Review Published Successfully");
        getReviewDetail();
        setComment("");
      }
    } catch (err) {
      showDangerToast("Error in Publishing Review");
      console.log(err);
    }
  };

  const handleReplyClick = async () => {
    if (comment) {
      try {
        const res = await replyReview(review_id ?? "", comment);
        if (res?.data) {
          showSuccessToast("Review Replied Successfully");
          getReply();
        }
      } catch (err) {
        showDangerToast("Error in Replying Review");
        console.log(err);
      }
    }
  };

  const handleGenerateAutoReply = async () => {
    try {
      const res = await getGeneratedReply(review_id ?? "");

      if (res?.data?.data) {
        setComment(res?.data?.data?.autoReply);
        showSuccessToast("Reply Generated Successfully");
      }
    } catch (err) {
      console.log(err);
      showDangerToast("Error Generating Reply");
    }
  };

  return (
    <div>
      <ActionBar />
      <div className={`${CSS.cs_review_detail_layout} cs-tm-35`}>
        <div>
          <div className="cs-dis-flex cs-vt-center">
            <StarAtom defaultRating={productReview?.rating?.value} />
            <div
              className={`${CSS.cs_vt_division} cs-dis-flex cs-center`}
            ></div>
            <div
              className={`${CSS.cs_review_detail_badge} cs-dis-flex cs-center`}
            >
              {productReview?.approval?.status === "published" && (
                <BadgeAtom label="PUBLISHED" size="small" />
              )}
              {productReview?.approval?.status === "unpublished" && (
                <BadgeAtom label="UNPUBLISHED" size="small" state="disable" />
              )}
              {productReview?.approval?.status === "rejected" && (
                <BadgeAtom label="REJECTED" size="small" state="error" />
              )}
              {productReview?.approval?.status === "reported" && (
                <BadgeAtom label="REPORTED" size="small" state="error" />
              )}
            </div>
            <div
              className={`${CSS.cs_vt_division} cs-dis-flex cs-center`}
            ></div>
            <div className={`cs-dis-flex cs-center`}>
              {productReview?.sentiment?.class === "happy" && (
                <BadgeAtom label="HAPPY" size="small" />
              )}
              {productReview?.sentiment?.class === "neutral" && (
                <BadgeAtom label="NEUTRAL" size="small" state="disable" />
              )}
              {productReview?.sentiment?.class === "sad" && (
                <BadgeAtom label="SAD" size="small" state="error" />
              )}
            </div>
          </div>

          {productReview?.verified && (
            <div className="cs-tm-15">
              <VerifiedPurchase />
            </div>
          )}

          <ProductReviewDetail
            reviewDetail={productReview}
            sellerReply={sellerReply}
          />

          {/* Actions */}
          <div className="cs-tm-20 cs-dis-flex">
            {productReview?.approval?.status !== "rejected" && (
              <ButtonAtom
                className="cs-rm-20"
                theme={"secondary"}
                borderColor="#7D7676"
                backgroundColor="#fff"
                color="#7D7676"
                label="Reject"
                onClick={handleRejectClick}
              />
            )}
            {productReview?.approval?.status !== "published" && (
              <ButtonAtom
                theme="primary"
                label="Publish"
                onClick={handlePublishClick}
              />
            )}
          </div>
          <BorderAtom className="cs-tm-24" />
          {/* UI ACTIONS */}
          <FieldInputBox
            labelClassName="cs-tm-20"
            inputType="textarea"
            label="Reply To Buyer"
            placeholder="Write reply"
            value={comment}
            onChange={(text: string) => setComment(text)}
          />
          <div className="cs-dis-flex cs-tm-20">
            {reviewComment?.comment ? (
              <ButtonAtom
                theme="primary"
                label="Update"
                onClick={handleReplyClick}
              />
            ) : (
              <ButtonAtom
                theme="primary"
                label="Reply"
                onClick={handleReplyClick}
              />
            )}

            <div
              className="cs-dis-flex cs-center cs-lm-20 cs-cursor-pointer"
              onClick={handleGenerateAutoReply}
            >
              <img
                src={generateIC}
                className={CSS.cs_generate_btn_ic}
                alt="generate-btn"
              />

              <div className={`${CSS.cs_generate_btn} cs-lm-8`}>Generate</div>
            </div>
          </div>
        </div>

        {/** todo once we get product id  */}
        <div>
          <ProductOverview
            productId={productReview?.entity?.id ?? ""}
            isVerified={productReview?.verified ?? false}
            orderId={productReview?.order_id ?? ""}
            fallbackProductDetails={location?.state}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewDetails;
