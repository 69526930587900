import React, { memo, useMemo } from "react";
import CSS from "./layout.atom.module.scss";
import classNames from "classnames/bind";
import LayoutAtom from "../../../atoms/layout/layout.atom";

interface LayoutMoleculeProps {
  id: string;
  items: { id: number; name: string; src?: string, isPro?: boolean }[];
  selected?: number;
  onClick: (id: number) => void;
}

function Layout({
  id,
  items,
  selected = items[0].id,
  onClick,
}: LayoutMoleculeProps): JSX.Element {
  const cx = useMemo(() => classNames.bind(CSS), []);

  return (
    <>
      <div data-testid={id} className={CSS["cs-grid-container"]}>
        {items.map((item) => {
          return (
            <div
              key={item.id}
              className={cx("cs-box-border", {
                selected: item.id === selected,
                "not-selected": item.id !== selected,
              })}
            >
              <LayoutAtom item={item} onClick={onClick} />
            </div>
          );
        })}
      </div>
    </>
  );
}

const LayoutMolecule = memo(Layout);
export default LayoutMolecule;
