import { memo, useEffect, useState } from "react";
import classNames from "classnames";

import Star from "../../../assets/star.svg";
import EmptyStar from "../../../assets/empty-star.svg";

import CSS from "./star.atom.module.scss";

interface IStarRatingProps {
  defaultRating?: number;
  hoverEffect?: boolean;
  clickEffect?: boolean;
  starColor?: string;
  handleRatingClick?: (rating: number) => void;
  className?: string;
}

const StarRating: React.FunctionComponent<IStarRatingProps> = ({
  defaultRating = 0,
  hoverEffect = false,
  clickEffect = false,
  starColor = "",
  className = "",
  handleRatingClick = (rating: number) => {},
}) => {
  const [rating, setRating] = useState(defaultRating);
  const [hover, setHover] = useState(0);

  useEffect(() => {
    setRating(defaultRating ?? 0);
  }, [defaultRating]);

  const handleClick = (starIndex: number) => {
    if (clickEffect) {
      setRating(starIndex);
      handleRatingClick && handleRatingClick(starIndex);
    }
  };

  const handleMouseEnterHover = (starIndex: number) => {
    if (hoverEffect) {
      setHover(starIndex);
    }
  };

  const handleMouseLeaveHover = () => {
    if (hoverEffect) {
      setHover(rating);
    }
  };

  return (
    <div className={`${CSS.star_rating_wrapper} ${className}`}>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={CSS.star_button}
            onClick={() => handleClick(index)}
            onMouseEnter={() => handleMouseEnterHover(index)}
            onMouseLeave={() => handleMouseLeaveHover()}
          >
            {index <= rating || (hoverEffect && index <= hover) ? (
              <img
                src={Star}
                className={classNames({
                  // For adding a different color refer to star.atom.module.scss and add as below
                  [CSS.filter_green]: starColor === "green",
                })}
                alt="star"
              />
            ) : (
              <img src={EmptyStar} alt="empty-star" />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default memo(StarRating);
