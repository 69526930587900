import React, { memo, useState } from "react";
import { Dropdown } from "@gofynd/nitrozen-react";
import CSS from "./select.atom.module.scss";

interface SelectAtomProps {
  placeholder?: string;
  id?: string;
  items: any[];
  onChange: (value: string) => void;
  value: string | string[] | number;
  className?: string;
  isSearchable?: boolean;
}

function Select({
  placeholder,
  id,
  onChange,
  items,
  value,
  className = "",
  isSearchable = false,
}: SelectAtomProps): JSX.Element {
  const [searchData, setSearchData] = useState<
    Array<{ type: string; value: Number; icon?: string }> | any
  >([...items]);

  const onSearchInputHandler = (obj: {
    text: string;
    value: Number;
    icon?: string;
    isGroupLabel?: Boolean;
  }) => {
    if (obj.text === "") {
      setSearchData([...items]);
    } else {
      const isAvailable = items.filter((item: any) =>
        item.text.toLowerCase().includes(obj.text.toLowerCase())
      );
      if (isAvailable.length > 0) {
        setSearchData([...isAvailable]);
      } else {
        setSearchData([]);
      }
    }
  };

  return (
    <>
      <div className={CSS["select-wrapper"] + " " + className}>
        <Dropdown
          value={value}
          // items={items}
          items={isSearchable ? searchData : items}
          searchable={isSearchable}
          onSearchInputChange={isSearchable ? onSearchInputHandler : undefined}
          //   className={CSS["select-font-size"]}
          placeholder={placeholder}
          data-testid={id}
          onChange={onChange}
        />
      </div>
    </>
  );
}

const SelectAtom = memo(Select);
export default SelectAtom;
