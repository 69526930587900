import CSS from "./table.module.scss";
import goldenStart from "./../../../assets/svgs/golden_star.svg";
import { ITopProductData } from "../../../service/dashboard.service";
import { getFormattedDate } from "../../../../../utils";
import DefaultImage from "../../../../../assets/default_icon.png";

interface ITableProps {
  headers: string[];
  data: ITopProductData[];
}
export const Table = (props: ITableProps) => {
  const newSortedData = props?.data?.sort(
    (a, b) => b?.review_count - a.review_count
  );

  return (
    <>
      <div>
        <div className={CSS.table_header_container}>
          {props.headers.map((heading: String) => {
            return <div>{heading}</div>;
          })}
        </div>
        <div>
          {newSortedData?.map((el: ITopProductData) => {
            const rating = Math.round(
              (el?.review_metric?.rating_sum ?? 0) /
                (el?.review_metric?.rating_count || 1)
            );
            return (
              <div className={CSS.table_data_row}>
                <div className={`${CSS.dis_flex} ${CSS.font_size_larger}`}>
                  <div className={CSS.product_info}>
                    <img
                      src={el?.media?.[0]?.url ?? DefaultImage}
                      alt="image_dummy"
                    />
                  </div>
                  <div className={`${CSS.m_l_8} ${CSS.wrapper}`}>
                    <div className={CSS.fw_600}>
                      {el?.name?.length < 20
                        ? el?.name
                        : el?.name?.slice(0, 20) + "..."}
                    </div>
                    <div className={CSS.fw_400}>{el?.brand}</div>
                    <div className={CSS.ratings}>
                      <div>{rating}</div>
                      {Array.from(Array(rating), (e, i) => {
                        return (
                          <span key={i}>
                            <img src={goldenStart} alt="stars" />
                          </span>
                        );
                      })}
                      <div className={CSS.review_count}>
                        ({el?.review_count})
                      </div>
                    </div>
                  </div>
                </div>
                <div className={CSS.date_row}>
                  <div>{getFormattedDate(el?.last_reviewed, true)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
