import axios from "./http.service";
import {
  CreateSubscriptionResponse,
  SubscriptionStatusResponse,
  PricingPlanRes,
  CreateSubscription,
} from "../pages/payment/payment.types";
import { getCompanyId, setCompanyId } from "../utils";

export const getRatingPlans = async () => {
  const companyId = getCompanyId();
  return axios.get<PricingPlanRes>(
    `/api/subscription/v2/plans?company_id=${companyId}`
  );
};

export const createSubscription = async (payload: CreateSubscription) => {
  const companyId = getCompanyId();

  return axios.post<CreateSubscriptionResponse>(
    `/api/subscription/v2/subscriptions?company_id=${companyId}`,
    payload
  );
};

export const getSubscriptionStatus = () => {
  const params = new URLSearchParams(window.location.search);

  let companyId: string | null | undefined = null;
  if (!companyId && params.get("company_id")) {
    companyId = params.get("company_id");
    setCompanyId(companyId);
  }
  return axios
    .get<any>(
      `/api/subscription/v2/subscriptions/status?company_id=${companyId}`
    )
    .then((res) => res.data);
};

/**
 * @param {string} platformSubscriptionId stringified ObjectId
 * @param {string} approved stringified boolean
 */
export async function updateSubscriptionStatus(
  platformSubscriptionId: string,
  status: string
) {
  const companyId = getCompanyId();
  const response = await axios.patch<SubscriptionStatusResponse>(
    `/api/subscription/v2/subscriptions?company_id=${companyId}&approved=${status}&subscription_id=${platformSubscriptionId}`,
    {
      platformSubscriptionId,
      status,
    }
  );
  return response.data;
}
