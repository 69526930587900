import axios from "axios";
import { getApplicationInfo, getCompanyId } from "../../../../../utils";

export interface IGetApplicationReviewProps {
  page_size?: number;
  page_no?: number;
  search?: string;
  rating?: number;
}

export type IApplicationReviewData = {
  _id: string;
  tags: string[];
  status: string;
  description: string;
  rating: number;
  company: string;
  application: string;
  order_id: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  id: string;
  application_name: string;
};

export type IApplicationReviewPage = {
  current: number;
  size: number;
  item_total: number;
  has_next: boolean;
};

export interface IGetApplicationReviewResponse {
  items: IApplicationReviewData[];
  page: IApplicationReviewPage;
}

export const getApplications = async (props: IGetApplicationReviewProps) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetApplicationReviewResponse>(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/review-rating/entity/application?company_id=${getCompanyId()}`,
    { params: props }
  );
};
