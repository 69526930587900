import React, { memo } from "react";

import { IDisplayWidgetType } from "../../../pages/display-widgets/display-widgets.types";
import { IManageEmailsType } from "../../../pages/manage-emails/manage-emails.types";

import CardHeader from "./components/molecules/card-header/card-header.molecule";
import CardBody from "./components/molecules/card-body/card-body.molecule";
import CardFooter from "./components/molecules/card-footer/card-footer.molecule";

import CSS from "./card.molecule.module.scss";
import withSubscription from "../../../hoc/subscription-hoc/subscription.hoc";

export interface ICardProps {
  cardData: IDisplayWidgetType | IManageEmailsType;
  id: string;
}

const Card: React.FunctionComponent<ICardProps> = ({ cardData, id }) => {
  return (
    <div className={CSS.card} data-testid={id}>
      <CardHeader name={cardData?.name} icon={cardData?.icon} id={id} />
      <CardBody description={cardData?.description} id={id} />
      <CardFooter
        path={cardData?.path}
        isActive={cardData?.is_active}
        id={id}
      />
    </div>
  );
};

export default memo(withSubscription(Card));
