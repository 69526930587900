export const TOP_REVIEW = {
  items: [
    {
      _id: "6463176aaa9e52e3227645b2",
      vote_count: {
        upvote: 0,
        downvote: 0,
      },
      verified: false,
      review: {
        title: "White Solid Tee 7",
        description:
          "(Note: This review is based on my personal experience with the product and may vary for different individuals.)",
      },
      rating: {
        value: 5,
        attributes: [],
      },
      application: "62f35968d101a6d38c886d85",
      company: "2",
      entity: {
        type: "product",
        id: "7505870",
      },
      approval: {
        status: "published",
        reason: "",
      },
      created_by: {
        id: "63ae984c800eab22d848175c",
        first_name: "Prathamesh",
        last_name: "More",
        account_type: "user",
      },
      created_at: "2023-05-16T05:40:58.388Z",
      updated_at: "2023-05-16T05:40:58.388Z",
      id: "6463176aaa9e52e3227645b2",
      is_abuse_reportable: true,
      is_upvotable: true,
      is_downvotable: true,
    },
    {
      _id: "645200e1617d70ec0c6b1119",
      vote_count: {
        upvote: 0,
        downvote: 0,
      },
      verified: false,
      review: {
        title: "White Solid Tee 6",
        description:
          "In conclusion, the White Solid Tee is a well-made, comfortable, and versatile garment that is sure to become a wardrobe staple. Despite the slightly higher price, the quality and timeless design make it a worthwhile investment. Whether you need a reliable go-to piece or a blank canvas for your style, this tee delivers on all fronts.\n\n",
      },
      rating: {
        value: 4,
        attributes: [],
      },
      application: "62f35968d101a6d38c886d85",
      company: "2",
      entity: {
        type: "product",
        id: "7505870",
      },
      approval: {
        status: "published",
        reason: "",
      },
      created_by: {
        id: "63eb64d62cb2782ff83f5313",
        first_name: "Mahip",
        last_name: "Modh",
        account_type: "user",
      },
      created_at: "2023-05-03T06:36:17.361Z",
      updated_at: "2023-05-04T09:43:58.352Z",
      id: "645200e1617d70ec0c6b1119",
      is_abuse_reportable: true,
      is_upvotable: true,
      is_downvotable: true,
      media: {
        total_count: 1,
        items: [
          {
            url: "https://cdn.tirabeauty.com/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/34XRiYQf2-batman_logo-wallpaper-240x320.jpg",
            type: "image",
          },
        ],
      },
    },
    {
      _id: "63fc53d21ab09236cb7f809c",
      vote_count: {
        upvote: 0,
        downvote: 0,
      },
      verified: false,
      rating: {
        attributes: [],
        value: 5,
      },
      review: {
        title: "White Solid Tee 5",
        description:
          "The only reason I'm not giving it a full five-star rating is the price. While the quality justifies a higher price point, I believe it could be more affordable for a basic white tee. However, if you're willing to invest in a durable and versatile piece that will last you a long time, it's worth the splurge.",
      },
      application: "62f35968d101a6d38c886d85",
      company: "2",
      entity: {
        type: "product",
        id: "7505870",
      },
      approval: {
        status: "published",
        reason: "",
      },
      created_by: {
        id: "63357c39731bb23c291da52e",
        first_name: "adi",
        last_name: "Shinde",
        account_type: "user",
      },
      created_at: "2023-02-27T06:55:14.339Z",
      updated_at: "2023-02-27T06:57:01.423Z",
      id: "63fc53d21ab09236cb7f809c",
      is_abuse_reportable: true,
      is_upvotable: true,
      is_downvotable: true,
    },
    {
      _id: "63ca7c616920224830a50d81",
      vote_count: {
        upvote: 0,
        downvote: 0,
      },
      verified: false,
      rating: {
        attributes: [],
        value: 4,
      },
      review: {
        title: " White Solid Tee 4",
        description:
          "In terms of care, the White Solid Tee is easy to maintain. It withstands regular machine washing without any significant color fading or shrinking. I haven't experienced any noticeable wrinkling either, which is a plus for those who prefer low-maintenance clothing.",
      },
      application: "62f35968d101a6d38c886d85",
      company: "2",
      entity: {
        type: "product",
        id: "7505870",
      },
      approval: {
        status: "published",
        reason: "",
      },
      created_at: "2023-01-20T11:34:57.786Z",
      updated_at: "2023-01-20T11:34:57.786Z",
      id: "63ca7c616920224830a50d81",
      created_by: {
        first_name: "Guest",
        last_name: "User",
        account_type: "guest",
      },
      is_abuse_reportable: true,
      is_upvotable: true,
      is_downvotable: true,
      media: {
        total_count: 1,
        items: [
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/review-rating/free/original/bAGu6ypIR-IMG-4895289486601790776.jpg",
            type: "image",
          },
        ],
      },
    },
    {
      _id: "63a956a79144a1682dcd99a3",
      vote_count: {
        upvote: 0,
        downvote: 0,
      },
      verified: false,
      review: {
        title: "White Solid Tee 3",
        description:
          "One of the highlights of this product is its simplicity and versatility. The white color is clean and timeless, making it easy to pair with different outfits and accessories. It's a wardrobe staple that can be dressed up or down, whether for a casual day out or a more formal occasion. The solid design allows for effortless styling, and it serves as an excellent base for layering or accessorizing with statement pieces.",
      },
      rating: {
        value: 5,
        attributes: [],
      },
      application: "62f35968d101a6d38c886d85",
      company: "2",
      entity: {
        type: "product",
        id: "7505870",
      },
      order_id: "",
      approval: {
        status: "published",
        reason: "",
      },
      created_by: {
        id: "63a9457a800eab068c481083",
        first_name: "Nikhar",
        last_name: "Garg",
        account_type: "user",
      },
      created_at: "2022-12-26T08:09:11.243Z",
      updated_at: "2022-12-26T08:09:11.243Z",
      id: "63a956a79144a1682dcd99a3",
      is_abuse_reportable: true,
      is_upvotable: true,
      is_downvotable: true,
      comment: {
        _id: "63a9a88128e679e4ebb119fb",
        application: "62f35968d101a6d38c886d85",
        archived: false,
        comment: "hello",
        created_at: "2022-12-26T13:58:25.484Z",
        entity: {
          type: "review",
          id: "63a956a79144a1682dcd99a3",
        },
        source: "platform",
        updated_at: "2022-12-26T13:58:25.484Z",
      },
    },
    {
      _id: "63a2bd2fe700cd3352208287",
      vote_count: {
        upvote: 0,
        downvote: 0,
      },
      verified: false,
      review: {
        title: "White Solid Tee 1",
        description:
          "The fit of the White Solid Tee is another aspect I appreciate. It has a classic regular fit, neither too tight nor too loose, which makes it versatile and suitable for various body types. The length is just right, falling at the hip, and the sleeves have a comfortable length that provides coverage without feeling restrictive. The tee maintains its shape well, even after wearing it for extended periods.",
      },
      rating: {
        value: 1,
        attributes: [],
      },
      application: "62f35968d101a6d38c886d85",
      company: "2",
      entity: {
        type: "product",
        id: "7505870",
      },
      order_id: "",
      approval: {
        status: "published",
        reason: "",
      },
      created_by: {
        id: "6307459eb4d03402d2c1d4e3",
        first_name: "Nitish",
        last_name: "Sharma",
        account_type: "user",
      },
      created_at: "2022-12-21T08:00:47.884Z",
      updated_at: "2022-12-21T08:02:12.272Z",
      id: "63a2bd2fe700cd3352208287",
      is_abuse_reportable: true,
      is_upvotable: true,
      is_downvotable: true,
      media: {
        total_count: 1,
        items: [
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/GNnPivzK4-tira-vto-0834b65e-d332-4ec8-abcb-2133e864266f.png",
            type: "image",
          },
        ],
      },
    },
    {
      _id: "63a162481b41ad40744f0d35",
      vote_count: {
        upvote: 0,
        downvote: 0,
      },
      verified: false,
      review: {
        title: "White Solid Tee 07",
        description:
          "First and foremost, the quality of the White Solid Tee is excellent. The fabric feels soft and comfortable against the skin, and it's evident that the material is of high quality. The tee has a nice weight to it, striking a balance between being too heavy or too light. The stitching is well done, and I haven't noticed any loose threads or fraying even after several washes.\n\n",
      },
      rating: {
        value: 1,
        attributes: [],
      },
      application: "62f35968d101a6d38c886d85",
      company: "2",
      entity: {
        type: "product",
        id: "7505870",
      },
      order_id: "",
      approval: {
        status: "published",
        reason: "",
      },
      created_by: {
        id: "6307459eb4d03402d2c1d4e3",
        first_name: "Nitish",
        last_name: "Sharma",
        account_type: "user",
      },
      created_at: "2022-12-20T07:20:40.910Z",
      updated_at: "2022-12-20T07:21:06.656Z",
      id: "63a162481b41ad40744f0d35",
      is_abuse_reportable: true,
      is_upvotable: true,
      is_downvotable: true,
      media: {
        total_count: 1,
        items: [
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/9x2SE9DbU-tira-vto-946dadf2-314c-46d1-8120-ddeb4cd26404.png",
            type: "image",
          },
        ],
      },
    },
    {
      _id: "63a066bf1b41ad6e984ef5c8",
      vote_count: {
        upvote: 0,
        downvote: 0,
      },
      verified: false,
      review: {
        title: "White Solid Tee 1212",
        description:
          "First and foremost, the quality of the White Solid Tee is excellent. The fabric feels soft and comfortable against the skin, and it's evident that the material is of high quality. The tee has a nice weight to it, striking a balance between being too heavy or too light. The stitching is well done, and I haven't noticed any loose threads or fraying even after several washes.\n\n",
      },
      rating: {
        value: 1,
        attributes: [],
      },
      application: "62f35968d101a6d38c886d85",
      company: "2",
      entity: {
        type: "product",
        id: "7505870",
      },
      order_id: "",
      approval: {
        status: "published",
        reason: "",
      },
      created_by: {
        id: "6307459eb4d03402d2c1d4e3",
        first_name: "Nitish",
        last_name: "Sharma",
        account_type: "user",
      },
      created_at: "2022-12-19T13:27:27.385Z",
      updated_at: "2022-12-19T13:28:08.924Z",
      id: "63a066bf1b41ad6e984ef5c8",
      is_abuse_reportable: true,
      is_upvotable: true,
      is_downvotable: true,
      media: {
        total_count: 1,
        items: [
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/kwavQmRLu-stefan-stefancik-QXevDflbl8A-unsplash-(1).jpg",
            type: "image",
          },
        ],
      },
    },
    {
      _id: "639872453fb989da270567da",
      vote_count: {
        upvote: 0,
        downvote: 0,
      },
      verified: false,
      review: {
        title: "White Solid Tee233",
        description:
          "First and foremost, the quality of the White Solid Tee is excellent. The fabric feels soft and comfortable against the skin, and it's evident that the material is of high quality. The tee has a nice weight to it, striking a balance between being too heavy or too light. The stitching is well done, and I haven't noticed any loose threads or fraying even after several washes.\n\n",
      },
      rating: {
        value: 3,
        attributes: [],
      },
      application: "62f35968d101a6d38c886d85",
      company: "2",
      entity: {
        type: "product",
        id: "7505870",
      },
      order_id: "",
      approval: {
        status: "published",
        reason: "",
      },
      created_by: {
        id: "6307459eb4d03402d2c1d4e3",
        first_name: "Nitish",
        last_name: "Sharma",
        account_type: "user",
      },
      created_at: "2022-12-13T12:38:29.439Z",
      updated_at: "2022-12-14T14:06:50.685Z",
      id: "639872453fb989da270567da",
      is_abuse_reportable: true,
      is_upvotable: true,
      is_downvotable: true,
      media: {
        total_count: 10,
        items: [
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/8yMi7_mWC-stefan-stefancik-QXevDflbl8A-unsplash.jpg",
            type: "image",
          },
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/xy4RoR03G-michael-dam-mEZ3PoFGs_k-unsplash.jpg",
            type: "image",
          },
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/F4E4Uqf69-philip-martin-XFLW_bXjZVE-unsplash.jpg",
            type: "image",
          },
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/EsEoRQK3c-ayo-ogunseinde-6W4F62sN_yI-unsplash.jpg",
            type: "image",
          },
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/pk1F68bae-jeffery-erhunse-Z9lbmEjyYjU-unsplash.jpg",
            type: "image",
          },
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/9Lz_30A1l-christina-wocintechchat-com-0Zx1bDv5BNY-unsplash.jpg",
            type: "image",
          },
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/CSLKJX9a3-aiony-haust-3TLl_97HNJo-unsplash.jpg",
            type: "image",
          },
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/LuO6lfIPl-matheus-ferrero-pg_WCHWSdT8-unsplash.jpg",
            type: "image",
          },
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/yv-G5IHmg-christopher-campbell-rDEOVtE7vOs-unsplash.jpg",
            type: "image",
          },
          {
            url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/3bIVvvvd5-when-second-user-tried-to-add-same-product-.webm",
            type: "video",
          },
        ],
      },
    },
  ],
  page: {
    type: "number",
    current: 1,
    size: 10,
    item_total: 27,
    has_next: true,
  },
};

export const REVIEW_WITH_IMAGES = {
  items: [
    {
      _id: "645200e1617d706dd26b111d",
      type: "image",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "645200e1617d70ec0c6b1119",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.tirabeauty.com/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/34XRiYQf2-batman_logo-wallpaper-240x320.jpg",
      created_at: "2023-05-03T06:36:17.376Z",
      updated_at: "2023-05-04T09:43:58.357Z",
      id: "645200e1617d706dd26b111d",
    },
    {
      _id: "63a2bd2fe700cd242d20828b",
      type: "image",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "63a2bd2fe700cd3352208287",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/GNnPivzK4-tira-vto-0834b65e-d332-4ec8-abcb-2133e864266f.png",
      created_at: "2022-12-21T08:00:47.892Z",
      updated_at: "2022-12-21T08:02:12.284Z",
      id: "63a2bd2fe700cd242d20828b",
    },
    {
      _id: "63a162481b41ad77244f0d39",
      type: "image",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "63a162481b41ad40744f0d35",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/9x2SE9DbU-tira-vto-946dadf2-314c-46d1-8120-ddeb4cd26404.png",
      created_at: "2022-12-20T07:20:40.918Z",
      updated_at: "2022-12-20T07:21:06.663Z",
      id: "63a162481b41ad77244f0d39",
    },
    {
      _id: "63a066bf1b41add76d4ef5cc",
      type: "image",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "63a066bf1b41ad6e984ef5c8",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/kwavQmRLu-stefan-stefancik-QXevDflbl8A-unsplash-(1).jpg",
      created_at: "2022-12-19T13:27:27.394Z",
      updated_at: "2022-12-19T13:28:08.933Z",
      id: "63a066bf1b41add76d4ef5cc",
    },
    {
      _id: "639872453fb989688e0567e4",
      type: "image",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "639872453fb989da270567da",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/CSLKJX9a3-aiony-haust-3TLl_97HNJo-unsplash.jpg",
      created_at: "2022-12-13T12:38:29.455Z",
      updated_at: "2022-12-14T14:06:50.687Z",
      id: "639872453fb989688e0567e4",
    },
    {
      _id: "639872453fb9898b250567e5",
      type: "image",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "639872453fb989da270567da",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/LuO6lfIPl-matheus-ferrero-pg_WCHWSdT8-unsplash.jpg",
      created_at: "2022-12-13T12:38:29.455Z",
      updated_at: "2022-12-14T14:06:50.687Z",
      id: "639872453fb9898b250567e5",
    },
    {
      _id: "639872453fb989c3550567e6",
      type: "image",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "639872453fb989da270567da",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/yv-G5IHmg-christopher-campbell-rDEOVtE7vOs-unsplash.jpg",
      created_at: "2022-12-13T12:38:29.455Z",
      updated_at: "2022-12-14T14:06:50.687Z",
      id: "639872453fb989c3550567e6",
    },
    {
      _id: "639872453fb9897e980567e7",
      type: "video",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "639872453fb989da270567da",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/3bIVvvvd5-when-second-user-tried-to-add-same-product-.webm",
      created_at: "2022-12-13T12:38:29.455Z",
      updated_at: "2022-12-14T14:06:50.687Z",
      id: "639872453fb9897e980567e7",
    },
    {
      _id: "639872453fb98908280567de",
      type: "image",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "639872453fb989da270567da",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/8yMi7_mWC-stefan-stefancik-QXevDflbl8A-unsplash.jpg",
      created_at: "2022-12-13T12:38:29.454Z",
      updated_at: "2022-12-14T14:06:50.687Z",
      id: "639872453fb98908280567de",
    },
    {
      _id: "639872453fb989e2600567df",
      type: "image",
      status: "active",
      application: "62f35968d101a6d38c886d85",
      entity: {
        id: "639872453fb989da270567da",
        type: "review",
      },
      product_id: "7505870",
      url: "https://cdn.pixelbin.io/v2/super-fire-62c344/tirabz/wrkr/tiraz0/platform/extensions/reviews/free/original/xy4RoR03G-michael-dam-mEZ3PoFGs_k-unsplash.jpg",
      created_at: "2022-12-13T12:38:29.454Z",
      updated_at: "2022-12-14T14:06:50.687Z",
      id: "639872453fb989e2600567df",
    },
  ],
  page: {
    type: "number",
    current: 1,
    size: 10,
    item_total: 40,
    has_next: true,
  },
};

export const REVIEW_METRICS = {
  _id: "642ed3ebc5e28c6c3d23afd8",
  application: "62f35968d101a6d38c886d85",
  company: "2",
  entity: {
    id: "7502634",
    type: "product",
  },
  created_at: "2023-04-06T14:15:07.149Z",
  rating_count: 14,
  rating_metric: [
    {
      rating: 1,
      count: 0,
    },
    {
      rating: 2,
      count: 2,
    },
    {
      rating: 3,
      count: 1,
    },
    {
      rating: 4,
      count: 6,
    },
    {
      rating: 5,
      count: 5,
    },
  ],
  rating_sum: 56,
  review_count: 14,
  updated_at: "2023-05-05T13:45:07.540Z",
};
