import React, { memo } from "react";
import { Badge } from "@gofynd/nitrozen-react";
import CSS from "./badge.atom.module.scss";

interface BadgeAtomProps {
  id?: string;
  state?: string;
  size?: string;
  label: string;
}

const BadgeAtom: React.FunctionComponent<BadgeAtomProps> = ({
  id = "",
  size = "small",
  state = "success",
  label = "",
}) => {
  return (
    <div className={CSS["badge_wrapper"]}>
      <Badge
        data-testid={"badge-" + id}
        size={size}
        state={state}
        labelText={label}
      />
    </div>
  );
};

export default memo(BadgeAtom);
