import LayoutOne from "../../../../../assets/review-home-layout-1.svg";
import LayoutTwo from "../../../../../assets/review-home-layout-2.svg";
import LayoutThree from "../../../../../assets/review-home-layout-3.svg";

export const layoutItems: { id: number; name: string; src?: string, isPro: boolean }[] = [
  { id: 1, name: "V1", src: LayoutOne, isPro: true },
  { id: 2, name: "V2", src: LayoutTwo, isPro: true },
  { id: 3, name: "V3", src: LayoutThree, isPro: true },
];

export const ratingTypeOptions = [
  {
    text: "Star Rating",
    value: "star_rating",
  },
  {
    text: "Number Rating",
    value: "number_rating",
  },
  {
    text: "Number & Star Rating",
    value: "both_star_and_number_rating",
  },
];

export const defaultReviewHomeRating = {
  selected_layout: 1,
  section_heading: "Home Review Carousel",
  rating_type: "both_star_and_number_rating",
  slide_grids: "masonry",
  review_media: true,
  display_user_avatar: true,
  view_product_link: true,
  review_date: true,
  slider_navigation: true,
  slider_pagination: true,
  slider_overflow: true,
  slider_pause_on_hover: true,
  is_active: true,
};
