import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getSubscriptionStatus,
  updateSubscriptionStatus,
} from "../../services/subscription.service";
import { useSubscriptionContext } from "../../context/subscription-context";
import { getCompanyId } from "../../utils";

export default function SubscriptionStatus(): JSX.Element {
  const [searchParams] = useSearchParams();
  const { subscription, setSubscription } = useSubscriptionContext();
  const navigate = useNavigate();
  const platformSubscriptionId = searchParams.get("subscription_id");
  const subscriptionApproved = searchParams.get("approved");
  const redirect = searchParams.get("redirect");

  useEffect(() => {
    if (!platformSubscriptionId) {
      return;
    }
    console.log("subscription", subscriptionApproved);
    updateSubscriptionStatus(platformSubscriptionId, subscriptionApproved ?? "")
      .then(async ({ data }) => {
        if (data.approved === "true") {
          setSubscription(data);
          const companyId = getCompanyId();
          try {
            const { data } = await getSubscriptionStatus();
            if (data) {
              setSubscription(data);
              navigate(`/company/${companyId}`);
            }
          } catch (ex) {
            console.log(ex);
          }
        }

        if (subscriptionApproved === "false") {
          setSubscription({});
          navigate("/payment");
        }
      })
      .catch(() => {
        navigate("/payment");
      });
  }, []);

  return <div></div>;
}
