import React, { useEffect, useMemo, useState } from "react";
import TextAtom from "../../../../../../components/atoms/text/text";
import { getFormattedDate } from "../../../../../../utils";
import {
  getProductReviewMedia,
  IProductReviewDetail,
  IProductReviewMedia,
} from "../../service/reviews.details.service";
import dummyProduct from "./assets/images/dummy_product.png";
import MediaContainer from "./molecules/image-container";
import CSS from "./product-review-detail.module.scss";

interface IProductReviewDetailProps {
  reviewDetail: IProductReviewDetail | undefined;
  sellerReply?: string;
}

const ProductReviewDetail: React.FC<IProductReviewDetailProps> = ({
  reviewDetail,
  sellerReply = undefined,
}) => {
  const [reviewMedia, setReviewMedia] = useState<IProductReviewMedia[]>();

  useEffect(() => {
    (async () => {
      try {
        const res = await getProductReviewMedia(reviewDetail?._id ?? "");
        if (res?.data?.items) {
          setReviewMedia(res?.data?.items);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [reviewDetail]);

  const reviewDescription = useMemo(() => {
    return reviewDetail?.is_abusive ?? false
      ? reviewDetail?.masked_review?.description
      : reviewDetail?.review?.description;
  }, [reviewDetail]);

  const reviewTitle = useMemo(() => {
    return reviewDetail?.is_abusive ?? false
      ? reviewDetail?.masked_review?.title
      : reviewDetail?.review?.title;
  }, [reviewDetail]);

  return (
    <div>
      <TextAtom className="cs-tm-15" text={reviewTitle ?? ""} type="header" />

      <TextAtom
        className="cs-tm-10"
        text={`Reviewed on ${getFormattedDate(reviewDetail?.created_at ?? "")}`}
        type="label"
      />

      <TextAtom
        className="cs-tm-10"
        text={reviewDescription ?? ""}
        type="product-description"
      />

      {sellerReply && (
        <div className={`${CSS.box} ${CSS.arrow_top}`}>
          <div className={CSS.box_heading}>Seller</div>
          <div className={CSS.box_description}>{sellerReply}</div>
        </div>
      )}

      {reviewMedia?.length ? (
        <MediaContainer reviewMedia={reviewMedia} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductReviewDetail;
