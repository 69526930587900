import React, { useEffect, useRef, useState } from "react";
import InputTextAtom from "../../../../../../components/atoms/input-text/input-text.atom";
import SelectAtom from "../../../../../../components/atoms/select/select.atom";
import useDidMountEffect from "../../../../../../hooks/useDidMountEffect";
import {
  defaultRatingOptions,
  defaultStatusOptions,
} from "./config/filter.box.constant";
import CSS from "./filter-box.module.scss";

interface IFilterBox {
  handleFilterChange: Function;
  search: string;
}

const FilterBox = ({
  handleFilterChange = () => {},
  search = "",
}: IFilterBox) => {
  const [searchText, setSearchText] = useState("");
  const isFirstRender = useRef(true);

  useEffect(() => {
    setSearchText(search);
  }, [search]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (!isFirstRender.current) {
        handleFilterChange(searchText, "search");
      }
      isFirstRender.current = false;
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchText]);

  return (
    <div>
      <div className={CSS["cs-filter-box-wrapper"]}>
        <InputTextAtom
          onChange={(e) => setSearchText(e)}
          placeholder="Search by question title"
          value={searchText}
        />

        <SelectAtom
          value={""}
          items={defaultStatusOptions}
          onChange={(e) => handleFilterChange(e, "status")}
          placeholder="All Status"
          id="field-control-all-status"
        />
      </div>
    </div>
  );
};

export default FilterBox;
