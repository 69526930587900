export const defaultProductDetailsConfig = {
  star_color: "#8B8374",
  can_review: "signed_in_user",
  no_review: "no_reviews_img",
  section_heading: "Customer Reviews",
  theme: "theme_colour",
  rating_type: "number_rating",
  rating_btn_text: "Rate This Product",
  seller_reply_title: "Seller Name",
  selected_layout: 1,
  display_all_media: true,
  display_user_avatar: true,
  display_date: true,
  display_review_images: true,
  has_rating_by_feature: true,
  report_button: true,
  threshold: 4,
  ratings_by_features: [],
  is_active: true,
  auto_publish: true,
};
