import React, { useEffect, useState } from "react";
import NoData from "../../components/no-data/no-data";
// import { getProductReview } from "./service/product.reviews.service";
import { useNavigate } from "react-router-dom";
import { RowPerPageLimit } from "./question-answer.constants";
import TableGrid from "./components/table-grid";
import {
  getQuestions,
  IGetQuestionAnswerResponse,
} from "./service/question-answer.service";
import FilterBox from "./components/filter-box";

interface IPaginationData {
  pageList: any[];
  currentPage: number;
  pageSize: number;
  itemTotal: number;
}

interface IFilterData {
  search: string;
  status: string | undefined;
  isFiltered: boolean;
}

const QuestionAnswerTable = () => {
  const [tableData, setTableData] = useState<IGetQuestionAnswerResponse>();
  const [paginationPageData, setPaginationPageData] = useState<IPaginationData>(
    {
      pageList: RowPerPageLimit,
      currentPage: 1,
      pageSize: 10,
      itemTotal: 0,
    }
  );
  const [filterData, setFilterData] = useState<IFilterData>({
    search: "",
    // rating: undefined,
    status: undefined,
    isFiltered: false,
  });
  const [initialCall, setInitialCall] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getTableData();
  }, [paginationPageData.currentPage, paginationPageData.pageSize, filterData]);

  useEffect(() => {
    setInitialCall(true);
  }, []);

  const getTableData = async () => {
    try {
      let payload = {
        ...paginationPageData,
        ...filterData,
      };
      const params = {
        page_size: payload.pageSize,
        sort_by: "desc_date",
        page_no: payload.currentPage,
        search: payload.search,
        status: payload.status,
      };
      let response = await getQuestions(params);
      if (response?.data) {
        setTableData(response.data);

        setPaginationPageData((prevData) => ({
          ...prevData,
          currentPage: +response.data?.page?.current,
          pageSize: response.data?.page?.size,
          itemTotal: response.data?.page?.item_total,
        }));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRowPerPage = (pageSize: string) => {
    setPaginationPageData((prevData) => ({
      ...prevData,
      pageSize: parseInt(pageSize),
    }));
  };

  const handleNextPage = () => {
    let tempCurrent = paginationPageData.currentPage;
    if (
      tempCurrent ===
      Math.ceil(paginationPageData.itemTotal / paginationPageData.pageSize)
    ) {
      return;
    }
    setPaginationPageData((prevData) => ({
      ...prevData,
      currentPage: 1 + tempCurrent,
    }));
  };

  const handleFilterChange = (value: string, key: string) => {
    setFilterData((prev) => ({ ...prev, [key]: value, isFiltered: true }));
  };

  const handlePrevPage = () => {
    let tempCurrent = paginationPageData.currentPage;
    if (tempCurrent === 1) {
      return;
    }
    setPaginationPageData((prevData) => ({
      ...prevData,
      currentPage: tempCurrent - 1,
      pageSize: paginationPageData.pageSize,
    }));
  };

  const handleOpenQuestionClick = (id: string) => {
    const productDetails = tableData?.items?.find(
      (item) => item.id === id
    )?.product;
    navigate(`question-answer/${id}`, { state: productDetails });
  };

  return (
    <div className="cs-tm-24">
      {(initialCall && (tableData?.items?.length ?? 0) > 0) ||
      filterData.isFiltered ? (
        <FilterBox
          search={filterData.search}
          handleFilterChange={handleFilterChange}
        />
      ) : null}
      {(tableData?.items?.length ?? 0) > 0 ? (
        <div className="cs-tm-12">
          <div className="cs-tm-12 cs-vh-100">
            <TableGrid
              handleOpenQuestionClick={handleOpenQuestionClick}
              tableData={tableData}
              paginationPageData={paginationPageData}
              handleNextPage={handleNextPage}
              handleRowPerPage={handleRowPerPage}
              handlePrevPage={handlePrevPage}
            />
          </div>
        </div>
      ) : (
        <div>
          <NoData text="No Question And Answer Found" />
        </div>
      )}
    </div>
  );
};

export default QuestionAnswerTable;
