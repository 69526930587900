import { Months } from "./constants";

export const setCompanyId = (companyId: string | undefined | null) => {
  if (companyId) {
    sessionStorage.setItem("companyId", companyId);
    return true;
  }
  return false;
};

export const getCompanyId = () => {
  return sessionStorage.getItem("companyId") ?? undefined;
};

export const setApplicationInfo = ({
  applicationId,
  token,
  is_active,
  domain,
}: {
  applicationId: string | undefined;
  token?: string | undefined;
  is_active?: boolean | undefined;
  domain?: { name: string } | undefined;
}) => {
  if (applicationId) {
    sessionStorage.setItem(
      "applicationInfo",
      JSON.stringify({ applicationId, token, is_active, domain })
    );
    return true;
  }
  return false;
};

export const getApplicationInfo = (): {
  applicationId: string;
  token: string;
  is_active: boolean;
  domain: {
    name: string;
  };
} => {
  return JSON.parse(sessionStorage.getItem("applicationInfo") ?? `{}`);
};

export function pxToRem(pxValue: any) {
  if (isNaN(pxValue)) {
    return pxValue;
  }
  return pxValue / 10 + "rem";
}

function getFormattedDatesForOneMonth(startDate: any) {
  const formattedDates = [];
  const endDate = new Date();

  while (endDate > startDate) {
    const month =
      endDate?.getMonth() + 1 > 9
        ? endDate?.getMonth() + 1
        : `0${endDate?.getMonth() + 1}`;
    const day =
      endDate.getDate() > 9 ? endDate?.getDate() : `0${endDate?.getDate()}`;
    const x = `${endDate?.getFullYear()}-${month}-${day}`;
    formattedDates.push(x);
    endDate.setDate(endDate.getDate() - 3); // Subtracting 3 days to the current date
  }

  return formattedDates.reverse();
}

export const generateOneMonthIntervals = () => {
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  const formattedDatesForOneMonth = getFormattedDatesForOneMonth(startDate);
  return formattedDatesForOneMonth;
};

export const getFormattedDate = (date: string, withTime: boolean = false) => {
  const newDate = new Date(date);
  let formattedDate = `${newDate?.getDate()} ${
    Months[newDate?.getMonth()]
  } ${newDate?.getFullYear()}`;

  if (withTime) {
    const hh =
      newDate?.getHours() < 9 ? `0${newDate?.getHours()}` : newDate?.getHours();
    const mm =
      newDate?.getMinutes() < 9
        ? `0${newDate?.getMinutes()}`
        : newDate?.getMinutes();
    const formattedTime = `${hh}:${mm}`;

    formattedDate = formattedDate + " " + formattedTime;
  }

  return formattedDate;
};
