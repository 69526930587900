import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { IEmailData, useEmailContext } from "../../context/email-context";

import TextAtom from "../../../../components/atoms/text/text";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import RightPanel from "../../../../components/templates/right-panel/right-panel.template";
import SectionHeadingWithDescBox from "../../../../components/molecules/section-heading-description/section-header";
import ButtonAtom from "../../../../components/atoms/button/button.atom";
import Slider from "../../../../components/molecules/slider/slider.molecule";
import BorderAtom from "../../../../components/atoms/border/border.component";
import ToggleBox from "../../../../components/molecules/toggle-box/toggle-box.molecule";
import FieldSelect from "../../../../components/molecules/field-control/select/select.molecule";
import SideDrawer from "../../../../components/templates/side-drawer/side-drawer.template";
import CodeEditor from "../../../../components/molecules/code-editor/code-editor.molecule";
import SenderEmailContainer from "../../molecules/sender-email/sender-email.molecule";

import {
  defaultEmailOptions,
  defaultProductDetailConfig,
  tabsItems,
} from "./config/product-detail.constants";
import EmailTemplate from "../email-template/email-template.component";
import {
  followUpEmailTemplate,
  defaultFollowUpEmailValues,
  submitReviewEmailTemplate,
  defaultSubmitReviewEmailValues,
} from "../email-template/email-template.constants";

import Tabs from "../../../../components/molecules/tabs/tabs";

import CSS from "./product-detail.module.scss";
import {
  getEmailConfig,
  sendEmail,
  updateEmailConfig,
} from "../../../../services/email.service";
import Modal from "../../../../components/molecules/modal/modal.molecule";
import {
  showDangerToast,
  showSuccessToast,
  TOAST_MESSAGES,
} from "../../../../services/toast.service";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

interface IProductDetailConfig {
  first_email_subject: string;
  first_email_preheader_text: string;
  first_email_hours: number;
  first_email_content: string;
  provider: string;
  send_follow_up_mail: boolean;
  follow_email_subject: string;
  follow_email_preheader_text: string;
  follow_email_hours: number;
  follow_email_content: string;
  send_email_on: string;
  is_enabled: boolean;
}

const ProductDetail: React.FunctionComponent = () => {
  const [productDetailConfig, setProductDetailConfig] = useState(
    defaultProductDetailConfig
  );
  const [showFirstEmailEditor, setShowFirstEmailEditor] =
    useState<boolean>(false);
  const [showFollowEmailEditor, setShowFollowEmailEditor] =
    useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string | number>(1);
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailType, setEmailType] = useState<string>("");
  const { emailData } = useEmailContext();
  const navigate = useNavigate();
  const preventConfigUpdate = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await getEmailConfig<IProductDetailConfig>(
          "email_product_review"
        );
        if (res?.data?.data) {
          setProductDetailConfig(res?.data?.data?.[0]?.config);

          if (!res?.data?.data?.[0]?.config?.is_enabled) {
            preventConfigUpdate.current = true;
          }

          if (res?.data?.data?.[0]?.config?.provider === "") {
            handleConfigChange("provider", providerOptions?.[0]?.value);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      (async () => {
        try {
          const res = await updateEmailConfig<IProductDetailConfig>(
            "email_product_review",
            productDetailConfig
          );

          if (res?.data) {
            showSuccessToast(
              res?.data?.data?.[0]?.config?.is_enabled
                ? TOAST_MESSAGES.configEnabled
                : TOAST_MESSAGES.configDisabled
            );
          }
        } catch (err) {
          console.log(err);
          showDangerToast(
            productDetailConfig?.is_enabled
              ? TOAST_MESSAGES.configEnabledError
              : TOAST_MESSAGES.configDisabledError
          );
          handleConfigChange("is_enabled", !productDetailConfig?.is_enabled);
          preventConfigUpdate.current = true;
        }
      })();
    } else {
      preventConfigUpdate.current = false;
    }
  }, [productDetailConfig.is_enabled]);

  const saveEmailConfig = async () => {
    try {
      const res = await updateEmailConfig<IProductDetailConfig>(
        "email_product_review",
        productDetailConfig
      );
      if (res?.data) {
        showSuccessToast(TOAST_MESSAGES.configUpdated);
      }
    } catch (err) {
      console.log(err);
      showDangerToast(TOAST_MESSAGES.configUpdateError);
    }
  };

  const newSubmitReviewEmailValues = useMemo(() => {
    return {
      ...defaultSubmitReviewEmailValues,
      ...(emailData as IEmailData)?.emailConfig,
    };
  }, [emailData]);

  const newfollowUpEmailValues = useMemo(() => {
    return {
      ...defaultFollowUpEmailValues,
      ...(emailData as IEmailData)?.emailConfig,
    };
  }, [emailData]);

  const providerOptions = useMemo(() => {
    return (emailData as IEmailData)?.emailProviders?.items?.map((provider) => {
      return {
        text: provider?.name,
        value: provider?._id,
      };
    });
  }, [emailData]);

  const selectedProvider = useMemo(() => {
    return (emailData as IEmailData)?.emailProviders?.items?.find(
      (provider) => provider?._id === productDetailConfig?.provider
    );
  }, [productDetailConfig?.provider]);

  const handleConfigChange = (
    configKey: string,
    value: string | boolean | number | string[]
  ) => {
    setProductDetailConfig((prevState) => {
      return { ...prevState, [configKey]: value };
    });
  };

  const handleBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSaveClick = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();
    saveEmailConfig();
  };

  const onEditFirstEmailClick = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();
    setShowFirstEmailEditor(true);
  };

  const onSendFirstEmailClick = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();

    setShowEmailModal(true);
    setEmailType("first_email");
  };

  const handleSendEmail = () => {
    switch (emailType) {
      case "first_email":
        handleSendFirstEmail();
        return;
      case "follow_email":
        handleSendFollowEmail();
        return;
    }
  };

  const handleSendFirstEmail = async () => {
    if (email) {
      try {
        const res = await sendEmail("submit_review", {
          template: productDetailConfig?.first_email_content,
          values: newSubmitReviewEmailValues,
          email: email,
          subject: productDetailConfig?.first_email_subject,
          providerId: selectedProvider?._id ?? undefined,
        });
        if (res?.data) {
          showSuccessToast("Sent Email Successfully");
        }
      } catch (err) {
        console.log(err);
        showDangerToast("Error Sending Email");
      } finally {
        setEmail("");
        setShowEmailModal(false);
        setEmailType("");
      }
    }
  };

  const onEditFollowEmailClick = useCallback(
    (
      event: React.MouseEvent<
        HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
        MouseEvent
      >
    ) => {
      event.preventDefault();
      setShowFollowEmailEditor(true);
    },
    []
  );

  const onSendFollowEmailClick = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();

    setShowEmailModal(true);
    setEmailType("follow_email");
  };

  const handleSendFollowEmail = async () => {
    if (email) {
      try {
        const res = await sendEmail("follow_up", {
          template: productDetailConfig?.follow_email_content,
          values: newfollowUpEmailValues,
          email: email,
          subject: productDetailConfig?.follow_email_subject,
          providerId: selectedProvider?._id ?? undefined,
        });

        if (res?.data) {
          showSuccessToast("Sent Email Successfully");
        }
      } catch (err) {
        console.log(err);
        showDangerToast("Error Sending Email");
      } finally {
        setEmail("");
        setShowEmailModal(false);
        setEmailType("");
      }
    }
  };

  const onProductDetailToggle = () => {
    // add or remove Q & A from the main config
    handleConfigChange("is_enabled", !productDetailConfig?.is_enabled);
  };

  const handleSaveFirstEmailClick = (value: string) => {
    handleConfigChange("first_email_content", value);
    showSuccessToast("Saved Email Successfully");
    setShowFirstEmailEditor(false);
  };

  const handleSaveFollowEmailClick = (value: string) => {
    handleConfigChange("follow_email_content", value);
    showSuccessToast("Saved Email Successfully");
    setShowFollowEmailEditor(false);
  };

  const tabChildren = () => {
    switch (activeTabKey) {
      case 1:
        return (
          <RightPanel
            Component={
              <EmailTemplate
                emailTemplate={productDetailConfig?.first_email_content}
                emailValues={newSubmitReviewEmailValues}
              />
            }
          />
        );

      case 2:
        return (
          <RightPanel
            Component={
              <EmailTemplate
                emailTemplate={productDetailConfig?.follow_email_content}
                emailValues={newfollowUpEmailValues}
              />
            }
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <>
        <div className="cs-bm-24">
          <SectionHeadingWithDescBox
            id="1"
            heading="Product Review Email"
            description="Customise the settings as per your needs"
            value={productDetailConfig?.is_enabled}
            onToggle={onProductDetailToggle}
            onBackClick={handleBackClick}
            isPro={true}
          />
        </div>

        <div
          className={`${CSS["cs-grid-container"]} ${CSS["cs-template-30-70"]} ${CSS["cs-column-gap-25"]}`}
        >
          <form>
            <div className="cs-bm-12">
              <TextAtom
                fontWeight={600}
                id="text-heading"
                text="Settings"
                type="header"
              />
            </div>
            <div className="cs-bm-14">
              <SenderEmailContainer
                name={selectedProvider?.name ?? ""}
                email={selectedProvider?.from_address?.[0]?.email ?? ""}
                label="Sender"
              />
            </div>
            <div className="cs-bm-14">
              <FieldSelect
                items={providerOptions}
                onChange={(value) => handleConfigChange("provider", value)}
                value={productDetailConfig?.provider}
                placeholder="Select Provider"
                id="provider"
                label="Choose Default Provider"
              />
            </div>
            <div className="cs-bm-14">
              <TextAtom
                id="text-heading"
                text="Setup First Email"
                type="heading"
              />
            </div>
            <div className="cs-bm-14">
              <FieldInputBox
                placeholder="Enter Subject Text"
                id="first-email-subject"
                value={productDetailConfig?.first_email_subject}
                onChange={(text) =>
                  handleConfigChange("first_email_subject", text)
                }
                label="Subject"
                maxLength={100}
                textLength={productDetailConfig?.first_email_subject?.length}
                textLimit={100}
              />
            </div>
            {/* <div className="cs-bm-14">
              <FieldInputBox
                placeholder="Enter Preheader Text"
                id="first-email-preheader-text"
                value={productDetailConfig?.first_email_preheader_text}
                onChange={(text) =>
                  handleConfigChange("first_email_preheader_text", text)
                }
                label="Preheader Text"
                maxLength={50}
                textLength={
                  productDetailConfig?.first_email_preheader_text?.length
                }
                textLimit={50}
              />
            </div> */}
            <div className="cs-bm-14">
              <FieldSelect
                items={defaultEmailOptions}
                onChange={(value) => handleConfigChange("send_email_on", value)}
                value={productDetailConfig?.send_email_on}
                placeholder="Select Value"
                id="send-email-on"
                label="Send Email On"
              />
            </div>

            <div className="cs-bm-6">
              <TextAtom
                type="heading"
                text="Send First Email After"
                id="slider-heading"
              />
            </div>
            <div className="cs-bm-6">
              <Slider
                max={72}
                min={12}
                step={12}
                onChange={({ target: { value } }) =>
                  handleConfigChange("first_email_hours", Number(value))
                }
                value={productDetailConfig?.first_email_hours}
                defaultValue={12}
                optionSuffix="hrs"
              />
            </div>
            <div
              className={`${CSS["cs-row-flex"]} ${CSS["cs-justify-row"]} cs-tm-16`}
            >
              <ButtonAtom
                id="send-test-email"
                label="Send Test Email"
                onClick={onSendFirstEmailClick}
                theme={"secondary"}
                borderColor="#7D7676"
                backgroundColor="#fff"
                color="#7D7676"
                padding="0px 12px"
              />
              <ButtonAtom
                id="edit-test-email"
                label="Edit Test Email"
                onClick={onEditFirstEmailClick}
                theme={"secondary"}
                borderColor="#7D7676"
                backgroundColor="#fff"
                color="#7D7676"
                padding="0px 12px"
              />
            </div>
            <div className="cs-bm-14 cs-tm-20">
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>

            <ToggleBox
              id="description"
              value={productDetailConfig?.send_follow_up_mail ?? false}
              onToggle={() =>
                handleConfigChange(
                  "send_follow_up_mail",
                  !productDetailConfig?.send_follow_up_mail
                )
              }
            >
              <TextAtom id="" text="Send Follow Up Email" type="heading" />
            </ToggleBox>
            {productDetailConfig?.send_follow_up_mail && (
              <>
                <div className="cs-bm-14 cs-tm-10">
                  <FieldInputBox
                    placeholder="Enter Subject Text"
                    id="follow-email-subject"
                    value={productDetailConfig?.follow_email_subject}
                    onChange={(text) =>
                      handleConfigChange("follow_email_subject", text)
                    }
                    label="Subject"
                    maxLength={100}
                    textLength={
                      productDetailConfig?.first_email_subject?.length
                    }
                    textLimit={100}
                  />
                </div>
                {/* <div className="cs-bm-14">
                  <FieldInputBox
                    placeholder="Enter Preheader Text"
                    id="preheader-text"
                    value={productDetailConfig?.follow_email_preheader_text}
                    onChange={(text) =>
                      handleConfigChange("follow_email_preheader_text", text)
                    }
                    label="Preheader Text"
                    maxLength={50}
                    textLength={
                      productDetailConfig?.follow_email_preheader_text?.length
                    }
                    textLimit={50}
                  />
                </div> */}
                <div className="cs-bm-6">
                  <Slider
                    max={72}
                    min={12}
                    step={12}
                    onChange={({ target: { value } }) =>
                      handleConfigChange("follow_email_hours", Number(value))
                    }
                    value={productDetailConfig?.follow_email_hours}
                    defaultValue={12}
                    optionSuffix="hrs"
                  />
                </div>
                <div
                  className={`${CSS["cs-row-flex"]} ${CSS["cs-justify-row"]} cs-tm-16`}
                >
                  <ButtonAtom
                    id="send-test-email"
                    label="Send Test Email"
                    onClick={onSendFollowEmailClick}
                    theme={"secondary"}
                    borderColor="#7D7676"
                    backgroundColor="#fff"
                    color="#7D7676"
                    padding="0px 12px"
                  />
                  <ButtonAtom
                    id="edit-test-email"
                    label="Edit Test Email"
                    onClick={onEditFollowEmailClick}
                    theme={"secondary"}
                    borderColor="#7D7676"
                    backgroundColor="#fff"
                    color="#7D7676"
                    padding="0px 12px"
                  />
                </div>
              </>
            )}
            <div className="cs-tm-14">
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-26">
              <ButtonAtom label="Save" onClick={onSaveClick} />
            </div>
          </form>
          <div>
            <div className={CSS.tabs}>
              <Tabs
                items={tabsItems}
                defaultActiveKey={1}
                active={activeTabKey}
                onChange={(key: string | number) => {
                  setActiveTabKey(key);
                }}
              />
            </div>
            <div>{tabChildren()}</div>
          </div>
        </div>
      </>
      {showFirstEmailEditor && (
        <SideDrawer
          showDrawer={showFirstEmailEditor}
          setShowDrawer={() => {
            setShowFirstEmailEditor(false);
          }}
          heading={"Edit First Email"}
          component={
            <CodeEditor
              content={productDetailConfig?.first_email_content}
              defaultValue={submitReviewEmailTemplate}
              handleCancelClick={() => {
                setShowFirstEmailEditor(false);
              }}
              handleSaveClick={handleSaveFirstEmailClick}
            />
          }
        />
      )}
      {showFollowEmailEditor && (
        <SideDrawer
          showDrawer={showFollowEmailEditor}
          setShowDrawer={() => {
            setShowFollowEmailEditor(false);
          }}
          heading={"Edit Follow Up Email"}
          component={
            <>
              <CodeEditor
                content={productDetailConfig?.follow_email_content}
                handleCancelClick={() => {
                  setShowFollowEmailEditor(false);
                }}
                defaultValue={followUpEmailTemplate}
                handleSaveClick={handleSaveFollowEmailClick}
              />
            </>
          }
        />
      )}
      {showEmailModal && (
        <Modal
          showModal={showEmailModal}
          handleCloseModal={() => {
            setShowEmailModal(false);
            setEmail("");
            setEmailType("");
          }}
          id="email"
          kind="acknowledgement"
          negativeButtonLabel="Cancel"
          handleNegativeClick={() => { }}
          handlePositiveClick={handleSendEmail}
          positiveButtonLabel="Send"
          title="Enter email to test"
        >
          <div className="cs-bm-16">
            <FieldInputBox
              placeholder="Your Email"
              id="seller-email"
              value={email}
              onChange={(text) => setEmail(text)}
              label="Email"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProductDetail;
