import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

import TextAtom from "../../../../components/atoms/text/text";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import RightPanel from "../../../../components/templates/right-panel/right-panel.template";
import SectionHeadingWithDescBox from "../../../../components/molecules/section-heading-description/section-header";
import ButtonAtom from "../../../../components/atoms/button/button.atom";

import { defaultQuestionAnswerConfig } from "./config/question-answer.constants";

import CSS from "./question-answer.module.scss";
import SenderEmailContainer from "../../molecules/sender-email/sender-email.molecule";
import FieldSelect from "../../../../components/molecules/field-control/select/select.molecule";
import EmailTemplate from "../email-template/email-template.component";
import {
  defaultSellerRepliedEmailValues,
  sellerRepliedEmailTemplate,
} from "../email-template/email-template.constants";
import SideDrawer from "../../../../components/templates/side-drawer/side-drawer.template";
import CodeEditor from "../../../../components/molecules/code-editor/code-editor.molecule";
import { IEmailData, useEmailContext } from "../../context/email-context";
import {
  getEmailConfig,
  sendEmail,
  updateEmailConfig,
} from "../../../../services/email.service";
import { type } from "os";
import Modal from "../../../../components/molecules/modal/modal.molecule";
import {
  showDangerToast,
  showSuccessToast,
  TOAST_MESSAGES,
} from "../../../../services/toast.service";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

interface IQuestionAnswerConfig {
  subject: string;
  preheader_text: string;
  provider: string;
  seller_reply_email_content: string;
  is_enabled: boolean;
}

const QuestionAnswer = () => {
  const [questionAnswerConfig, setQuestionAnswerConfig] = useState(
    defaultQuestionAnswerConfig
  );
  const [showSellerReplyEmailEditor, setShowSellerReplyEmailEditor] =
    useState<boolean>(false);
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const { emailData } = useEmailContext();
  const navigate = useNavigate();
  const preventConfigUpdate = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await getEmailConfig<IQuestionAnswerConfig>(
          "email_questions_answers"
        );
        if (res?.data?.data) {
          setQuestionAnswerConfig(res?.data?.data?.[0]?.config);

          if (!res?.data?.data?.[0]?.config?.is_enabled) {
            preventConfigUpdate.current = true;
          }
          if (res?.data?.data?.[0]?.config?.provider === "") {
            handleConfigChange("provider", providerOptions?.[0]?.value);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      (async () => {
        try {
          const res = await updateEmailConfig<IQuestionAnswerConfig>(
            "email_questions_answers",
            questionAnswerConfig
          );

          if (res?.data) {
            showSuccessToast(
              res?.data?.data?.[0]?.config?.is_enabled
                ? TOAST_MESSAGES.configEnabled
                : TOAST_MESSAGES.configDisabled
            );
          }
        } catch (err) {
          console.log(err);
          showDangerToast(
            questionAnswerConfig?.is_enabled
              ? TOAST_MESSAGES.configEnabledError
              : TOAST_MESSAGES.configDisabledError
          );
          handleConfigChange("is_enabled", !questionAnswerConfig?.is_enabled);
          preventConfigUpdate.current = true;
        }
      })();
    } else {
      preventConfigUpdate.current = false;
    }
  }, [questionAnswerConfig.is_enabled]);

  const saveEmailConfig = async () => {
    try {
      const res = await updateEmailConfig<IQuestionAnswerConfig>(
        "email_questions_answers",
        questionAnswerConfig
      );

      if (res?.data) {
        showSuccessToast(TOAST_MESSAGES.configUpdated);
      }
    } catch (err) {
      console.log(err);
      showDangerToast(TOAST_MESSAGES.configUpdateError);
    }
  };

  const sellerRepliedEmailValues = useMemo(() => {
    return {
      ...defaultSellerRepliedEmailValues,
      ...(emailData as IEmailData)?.emailConfig,
    };
  }, [emailData]);

  const providerOptions = useMemo(() => {
    return (emailData as IEmailData)?.emailProviders?.items?.map((provider) => {
      return {
        text: provider?.name,
        value: provider?._id,
      };
    });
  }, [emailData]);

  const selectedProvider = useMemo(() => {
    return (emailData as IEmailData)?.emailProviders?.items?.find(
      (provider) => provider?._id === questionAnswerConfig?.provider
    );
  }, [questionAnswerConfig?.provider]);

  const handleConfigChange = (
    configKey: string,
    value: string | boolean | string[]
  ) => {
    setQuestionAnswerConfig((prevState) => {
      return { ...prevState, [configKey]: value };
    });
  };

  const onSaveClick = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();

    saveEmailConfig();
  };

  const onEditEmailClick = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();
    setShowSellerReplyEmailEditor(true);
  };

  const onSendEmailClick = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();

    setShowEmailModal(true);
  };

  const handleSendEmail = async () => {
    try {
      const res = await sendEmail("seller_replied", {
        template: questionAnswerConfig?.seller_reply_email_content,
        values: sellerRepliedEmailValues,
        email: email,
        subject: questionAnswerConfig?.subject,
        providerId: selectedProvider?._id,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setEmail("");
      setShowEmailModal(false);
    }
  };

  const handleSaveSellerReplyEmailClick = (value: string) => {
    handleConfigChange("seller_reply_email_content", value);
    setShowSellerReplyEmailEditor(false);
  };

  const onQuestionAndAnswerToggle = () => {
    handleConfigChange("is_enabled", !questionAnswerConfig?.is_enabled);
  };

  return (
    <>
      <div>
        <div className="cs-bm-24">
          <SectionHeadingWithDescBox
            id="1"
            heading="Questions & Answer"
            description="Customise the settings as per your needs"
            value={questionAnswerConfig?.is_enabled}
            onToggle={onQuestionAndAnswerToggle}
            onBackClick={useCallback(() => navigate(-1), [])}
            isPro={true}
          />
        </div>

        <div
          className={`${CSS["cs-grid-container"]} ${CSS["cs-template-30-70"]} ${CSS["cs-column-gap-25"]}`}
        >
          <form>
            <div className="cs-bm-12">
              <TextAtom
                fontWeight={600}
                id="text-heading"
                text="Settings"
                type="header"
              />
            </div>
            <div className="cs-bm-14">
              <SenderEmailContainer
                name={selectedProvider?.name ?? ""}
                email={selectedProvider?.from_address?.[0]?.email ?? ""}
                label="Sender"
              />
            </div>
            <div className="cs-bm-14">
              <FieldSelect
                items={providerOptions}
                onChange={(value) => handleConfigChange("provider", value)}
                value={questionAnswerConfig?.provider}
                placeholder="Select Provider"
                id="provider"
                label="Choose Default Provider"
              />
            </div>
            <div className="cs-bm-14">
              <FieldInputBox
                placeholder="Enter Subject Text"
                id="subject"
                value={questionAnswerConfig?.subject}
                onChange={(text) => handleConfigChange("subject", text)}
                label="Subject"
                maxLength={100}
                textLength={questionAnswerConfig?.subject?.length}
                textLimit={100}
              />
            </div>
            {/* <div className="cs-bm-16">
              <FieldInputBox
                placeholder="Enter Preheader Text"
                id="preheader-text"
                value={questionAnswerConfig?.preheader_text}
                onChange={(text) => handleConfigChange("preheader_text", text)}
                label="Preheader Text"
                maxLength={50}
                textLength={questionAnswerConfig?.preheader_text?.length}
                textLimit={50}
              />
            </div> */}
            <div className={`${CSS["cs-row-flex"]} ${CSS["cs-justify-row"]} `}>
              <ButtonAtom
                id="send-test-email"
                label="Send Test Email"
                onClick={onSendEmailClick}
                theme={"secondary"}
                borderColor="#7D7676"
                backgroundColor="#fff"
                color="#7D7676"
                padding="0px 12px"
              />
              <ButtonAtom
                id="edit-test-email"
                label="Edit Test Email"
                onClick={onEditEmailClick}
                theme={"secondary"}
                borderColor="#7D7676"
                backgroundColor="#fff"
                color="#7D7676"
                padding="0px 12px"
              />
            </div>
            <div className="cs-tm-26">
              <ButtonAtom label="Save" onClick={onSaveClick} />
            </div>
          </form>
          <div>
            <RightPanel
              Component={
                <EmailTemplate
                  emailTemplate={
                    questionAnswerConfig?.seller_reply_email_content
                  }
                  emailValues={sellerRepliedEmailValues}
                />
              }
            />
          </div>
        </div>
      </div>
      {showSellerReplyEmailEditor && (
        <SideDrawer
          showDrawer={showSellerReplyEmailEditor}
          setShowDrawer={() => {
            setShowSellerReplyEmailEditor(false);
          }}
          heading={"Edit Follow Up Email"}
          component={
            <>
              <CodeEditor
                content={questionAnswerConfig?.seller_reply_email_content}
                handleCancelClick={() => {
                  setShowSellerReplyEmailEditor(false);
                }}
                defaultValue={sellerRepliedEmailTemplate}
                handleSaveClick={handleSaveSellerReplyEmailClick}
              />
            </>
          }
        />
      )}
      {showEmailModal && (
        <Modal
          showModal={showEmailModal}
          handleCloseModal={() => {
            setShowEmailModal(false);
            setEmail("");
          }}
          id="email"
          kind="acknowledgement"
          negativeButtonLabel="Cancel"
          handleNegativeClick={() => { }}
          handlePositiveClick={handleSendEmail}
          positiveButtonLabel="Send"
          title="Enter email to test"
        >
          <div className="cs-bm-16">
            <FieldInputBox
              placeholder="Your Email"
              id="seller-email"
              value={email}
              onChange={(text) => setEmail(text)}
              label="Email"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default QuestionAnswer;
