import PercentIcon from "../../../assets/percent_icon.svg";
import RupeeIcon from "../../../assets/rupee_icon.svg";

export const defaultRewardConfig = {
  generated_coupon_prefix: "",
  discount_type: "percentage", // percentage or fixed
  discount_value: 100,
  minimum_order_value_requirement: 1000,
  max_discount_amount: 100,
  coupon_validity_in_days: 30,
  is_active: true,
  conditions: {
    only_buyer_reviewed: true,
    review_with: "heading_or_body", // "heading_or_body" or "image_or_video"
  },
};

export const collapsibleItems = [
  {
    heading: "Coupon Discount",
    subHeading:
      "The reviewer coupon can be used to get a total discount of 0 INR on the checkout page.",
  },
  {
    heading: "Minimum Requirement",
    subHeading: "The review coupon is only valid for orders of",
  },
  { heading: "Coupon Validity", subHeading: "Coupon can only be used for" },
  {
    heading: "Reward Conditions",
    subHeading: "You are currently not rewarding your reviewers.",
  },
];

export const couponDiscountOptions = [
  {
    icon: PercentIcon,
    text: "Percentage Off",
    key: "percentage",
  },
  {
    icon: RupeeIcon,
    text: "Fixed Amount Off",
    key: "flat",
  },
];

export const rewardConditionOneOptions = [
  {
    icon: PercentIcon,
    text: "Buyers Of The Reviewed Product Only",
    key: "buyers_only",
  },
];

export const rewardConditionTwoOptions = [
  {
    icon: PercentIcon,
    text: "Any Review With Heading Or Body Filled In",
    key: "heading_or_body",
  },
  {
    icon: PercentIcon,
    text: "Any Review With At Least One Photo Or Video",
    key: "image_or_video",
  },
];
