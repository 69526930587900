import { text } from "body-parser";
import React from "react";
import TextAtom from "../../../../components/atoms/text/text";
import NoDataPng from "../../asset/image/Empty-state.png";
import CSS from "./no-data.module.scss";

interface INoDataProps {
  text: string;
}

const NoData: React.FC<INoDataProps> = ({ text }) => {
  return (
    <div>
      <div className={`${CSS.cs_dis_flex} ${CSS.cs_center} ${CSS.cs_height}`}>
        <div>
          <img src={NoDataPng} alt="no data found" />
          <TextAtom style={{ textAlign: "center" }} text={text} type="header" />
        </div>
      </div>
    </div>
  );
};

export default NoData;
