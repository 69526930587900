import { Route, Routes } from "react-router-dom";
import Layout from "../components/templates/layout";
import Dashboard from "../pages/dashboard";
import DisplayWidgets from "../pages/display-widgets";
import ApplicationReview from "../pages/display-widgets/components/application-review/application-review.component";
import FloatingReview from "../pages/display-widgets/components/floating-review/floating-review.component";
import ReviewForm from "../pages/display-widgets/components/review-form";
import ReviewHome from "../pages/display-widgets/components/review-home-carousel/review-home-carousel.component";
import ManageEmails from "../pages/manage-emails";
import ManageReviews from "../pages/manage-review";
import Rewards from "../pages/rewards";
import ManageQuestionAnswer from "../pages/manage-emails/components/question-answer/question-answer.component";
import ManageApplicationReview from "../pages/manage-emails/components/application-review/application-review.component";
import ManageProductDetail from "../pages/manage-emails/components/product-detail/product-detail.component";
import Integration from "../pages/integration";
import ProductDetails from "../pages/display-widgets/components/product-detail";
import DisplayQuestionAnswer from "../pages/display-widgets/components/question-answer/question-answer.component";
import SalesChannelPage from "../pages/sales-channel/sales-channel.component";
import ReviewDetails from "../pages/manage-review/pages/review-detail";
import SmartControls from "../pages/smart-controls";
import QuestionDetail from "../pages/manage-review/pages/question-answer/components/question-detail/question-detail.component";
import Payment from "../pages/payment/payment.component";
import SubscriptionStatus from "../pages/subscription-status/subscription-status.component";

import { RouteWrapper } from "./route-wrapper.component";

const Router = () => {
  return (
    <Routes>
      <Route
        path="/payment"
        element={<Payment />}
      ></Route>
      <Route
        path="/:company_id/subscription-status"
        element={<SubscriptionStatus />}
      ></Route>

      <Route path="/company/:company_id" element={<RouteWrapper>
        <SalesChannelPage />
      </RouteWrapper>} />

      <Route path="/features-page" element={<RouteWrapper><Layout /></RouteWrapper>}>
        <Route index element={<RouteWrapper><Dashboard /></RouteWrapper>}></Route>
        <Route path="/features-page/dashboard" element={<RouteWrapper><Dashboard /></RouteWrapper>}></Route>
        <Route
          path="/features-page/manage-review"
          element={<RouteWrapper><ManageReviews /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/manage-review/product-review/:review_id"
          element={<RouteWrapper><ReviewDetails /></RouteWrapper>}
        ></Route>
        {/* <Route
        path="/features-page/manage-review/product-review/:review_id"
        element={<RouteWrapper><ReviewDetails /></RouteWrapper>}
      ></Route> */}
        <Route
          path="/features-page/manage-review/question-answer/:question_id"
          element={<RouteWrapper><QuestionDetail /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/display-widgets"
          element={<RouteWrapper><DisplayWidgets /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/manage-emails"
          element={<RouteWrapper><ManageEmails /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/smart-controls"
          element={<RouteWrapper><SmartControls /></RouteWrapper>}
        ></Route>
        <Route path="/features-page/rewards" element={<RouteWrapper><Rewards /></RouteWrapper>}></Route>
        <Route
          path="/features-page/integration"
          element={<RouteWrapper><Integration /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/display-widgets/product-detail"
          element={<RouteWrapper><ProductDetails /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/display-widgets/question-answer"
          element={<RouteWrapper><DisplayQuestionAnswer /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/display-widgets/review-form"
          element={<RouteWrapper><ReviewForm /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/display-widgets/review-home-carousel"
          element={<RouteWrapper><ReviewHome /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/display-widgets/application-reviews"
          element={<RouteWrapper><ApplicationReview /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/display-widgets/floating-review"
          element={<RouteWrapper><FloatingReview /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/manage-emails/question-answer"
          element={<RouteWrapper><ManageQuestionAnswer /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/manage-emails/application-review"
          element={<RouteWrapper><ManageApplicationReview /></RouteWrapper>}
        ></Route>
        <Route
          path="/features-page/manage-emails/product-detail"
          element={<RouteWrapper><ManageProductDetail /></RouteWrapper>}
        ></Route>
      </Route>
    </Routes >
  );
};

export default Router;
