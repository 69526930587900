import axios from "../../../services/http.service";
import { getCompanyId } from "../../../utils";

interface QueryParams {
  search?: string;
  limit?: number;
  page?: number;
}

const getApplications = async (queryParams: QueryParams) => {
  /**
   * @todo adding appropriate endpoints
   */
  return axios.get(`/api/v1.0/company/${getCompanyId()}/application`, { params: queryParams });
};

export default getApplications;
