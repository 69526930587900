import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import TextAtom from "../../../../components/atoms/text/text";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import FieldSelect from "../../../../components/molecules/field-control/select/select.molecule";
import RightPanel from "../../../../components/templates/right-panel/right-panel.template";
import RadioBox from "../../../../components/molecules/radio-box/radio-box";
import SectionHeadingWithDescBox from "../../../../components/molecules/section-heading-description/section-header";
import ButtonAtom from "../../../../components/atoms/button/button.atom";

import {
  defaultFloatingReviewConfig,
  defaultPageOptions,
} from "./config/floating-review.constants";

import CSS from "./floating-review.module.scss";
import {
  getFormConfig,
  updateFormConfig,
} from "../../../../services/config.service";
import { getApplicationPages } from "../../../../services/pages.service";
import { useConfigContext } from "../../../../context/config-context";
import {
  showDangerToast,
  showSuccessToast,
  TOAST_MESSAGES,
} from "../../../../services/toast.service";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import Modal from "../../../../components/molecules/modal/modal.molecule";
import { useSubscriptionContext } from "../../../../context/subscription-context";
import ConfirmBody from "../../../../components/templates/confirm-modal";

export type IPageOption = {
  text: string;
  value: string;
  path?: string;
};

interface IFloatingReviewConfig {
  heading: string;
  floating_position: string;
  floating_behaviour: string;
  page_link: string;
  show_pages: IPageOption[];
  is_active: boolean;
}

const FloatingReview = () => {
  const [floatingConfig, setFloatingConfig] = useState<IFloatingReviewConfig>(
    defaultFloatingReviewConfig
  );
  const [pageOptions, setPageOptions] =
    useState<IPageOption[]>(defaultPageOptions);
  const navigate = useNavigate();
  const { setConfig } = useConfigContext();
  const preventConfigUpdate = useRef<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const { subscription } = useSubscriptionContext()

  useEffect(() => {
    setConfig((prevData: any) => ({
      ...prevData,
      floatingReviewConfig: { ...floatingConfig },
      type: "FLOATING_REVIEW",
    }));
  }, [floatingConfig]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getFormConfig<IFloatingReviewConfig>(
          "floating_review"
        );
        if (res?.data?.data) {
          setFloatingConfig(res.data.data?.[0]?.config);

          if (!res?.data?.data?.[0]?.config?.is_active) {
            preventConfigUpdate.current = true;
          }
        }
      } catch (err) {
        console.log(err);
      }

      try {
        const res = await getApplicationPages();
        if (res?.data?.data) {
          setPageOptions(res?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      (async () => {
        try {
          const res = await updateFormConfig<IFloatingReviewConfig>({
            widget: "floating_review",
            payload: floatingConfig,
          });

          if (res?.data) {
            showSuccessToast(
              res?.data?.data?.[0]?.config?.is_active
                ? TOAST_MESSAGES.configEnabled
                : TOAST_MESSAGES.configDisabled
            );
          }
        } catch (err) {
          console.log(err);
          showDangerToast(
            floatingConfig?.is_active
              ? TOAST_MESSAGES.configEnabledError
              : TOAST_MESSAGES.configDisabledError
          );
          handleConfigChange("is_active", !floatingConfig?.is_active);
          preventConfigUpdate.current = true;
        }
      })();
    } else {
      preventConfigUpdate.current = false;
    }
  }, [floatingConfig.is_active]);

  const handleConfigChange = (
    configKey: string,
    value: string | boolean | number | IPageOption[]
  ) => {
    setFloatingConfig((prevState) => {
      return { ...prevState, [configKey]: value };
    });
  };

  const handleSaveClick = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event?.preventDefault();

    try {
      const res = await updateFormConfig<IFloatingReviewConfig>({
        widget: "floating_review",
        payload: floatingConfig,
      });
      if (res?.data) {
        showSuccessToast(TOAST_MESSAGES.configUpdated);
      }
    } catch (err) {
      console.log(err);
      showDangerToast(TOAST_MESSAGES.configUpdateError);
    }
  };

  const handlePageChange = (value: string | string[]) => {
    const selectedPages: IPageOption[] = [];

    (value as string[])?.forEach((page) => {
      const selectedPage = pageOptions?.find(
        (option: IPageOption) => option?.value === page
      );

      if (selectedPage) {
        selectedPages?.push(selectedPage);
      }
    });

    handleConfigChange("show_pages", selectedPages);
  };

  const handleCloseModal = useCallback(() => {
    setShowUpgradeModal(false)
  }, [])

  const handleProModal = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event?.preventDefault();
    setShowUpgradeModal(true);
  };

  return (
    <div>
      <div className="cs-bm-24">
        <SectionHeadingWithDescBox
          id="floating_sidebar"
          heading="Floating Sidebar"
          description="Select your design layout and customise the settings as per your needs"
          value={floatingConfig?.is_active}
          onToggle={() =>
            handleConfigChange("is_active", !floatingConfig?.is_active)
          }
          onBackClick={useCallback(() => navigate(-1), [])}
          isPro={true}
        />
      </div>
      <div
        className={`${CSS["cs-grid-container"]} ${CSS["cs-template-30-70"]} ${CSS["cs-column-gap-25"]}`}
      >
        <form>
          <div className="cs-bm-12">
            <TextAtom
              fontWeight={600}
              id="text-heading"
              text="Settings"
              type="header"
            />
          </div>
          <FieldInputBox
            placeholder="Enter Heading"
            id="heading"
            value={floatingConfig?.heading}
            onChange={(text: string) => handleConfigChange("heading", text)}
            label="Heading"
          />
          <div className="cs-tm-14 cs-bm-14">
            <RadioBox
              radios={[
                {
                  id: "left",
                  onChange: () =>
                    handleConfigChange("floating_position", "left"),
                  value: "left",
                  name: "Left",
                  labelText: "Left",
                  radioValue: floatingConfig?.floating_position,
                },
                {
                  id: "right",
                  onChange: () =>
                    handleConfigChange("floating_position", "right"),
                  value: "right",
                  name: "Right",
                  labelText: "Right",
                  radioValue: floatingConfig?.floating_position,
                },
              ]}
              heading="Floating Position"
              layout="vertical"
            />
          </div>
          <div className="cs-tm-14 cs-bm-14">
            <RadioBox
              radios={[
                {
                  id: "open_in_modal",
                  onChange: () =>
                    handleConfigChange("floating_behaviour", "open_in_modal"),
                  value: "open_in_modal",
                  name: "Open In Modal",
                  labelText: "Open In Modal",
                  radioValue: floatingConfig?.floating_behaviour,
                },
                {
                  id: "open_in_page",
                  onChange: () =>
                    handleConfigChange("floating_behaviour", "open_in_page"),
                  value: "open_in_page",
                  name: "Open In Page",
                  labelText: "Open In Page",
                  radioValue: floatingConfig?.floating_behaviour,
                },
              ]}
              heading="Floating Behaviour"
              layout="vertical"
            />
          </div>
          {floatingConfig?.floating_behaviour === "open_in_page" && (
            <div className="cs-bm-14">
              <FieldInputBox
                placeholder="Enter Page Link"
                id="page_link"
                value={floatingConfig?.page_link}
                onChange={(text: string) =>
                  handleConfigChange("page_link", text)
                }
                label="Page Link"
              />
            </div>
          )}
          <div className="cs-tm-14">
            <FieldSelect
              items={pageOptions}
              onChange={handlePageChange}
              value={
                floatingConfig?.show_pages?.map(
                  (page: IPageOption) => page?.value
                ) ?? []
              }
              placeholder="Select Pages"
              id="show_pages"
              label="Show On Pages"
              mode="multiple"
            />
          </div>
          <div className="cs-tm-24">
            <ButtonAtom label="Save" onClick={subscription?.plan_type === "basic" ? handleProModal : handleSaveClick} />
          </div>
        </form>
        <div>
          <RightPanel />
        </div>
      </div>
      {showUpgradeModal && <div>
        <Modal
          showModal={showUpgradeModal}
          handleCloseModal={handleCloseModal}
          id="upgrade"
          kind="informational"
          title=""
          isClosable={false}
          style={{ "width": "22%" }}
        ><ConfirmBody
            onCancel={handleCloseModal}
            onSuccess={() => navigate('/payment')}
          />
        </Modal>
      </div>}
    </div>
  );
};

export default FloatingReview;
