import CSS from "./chart-info.module.scss";
import upArrow from "./../../../assets/svgs/up_green_arrow.svg";
import downArrow from "./../../../assets/svgs/down_arrow.svg";
import { ProgressBar } from "../../atoms/progress-bar/progress-bar.component";
export const ChartInfo = (props: any) => {
  return (
    <div className={CSS.chart_info_container}>
      {props.data.map((info: any) => {
        return (
          <div className={CSS.chart_info_row_container}>
            <div>{info.label}</div>
            <div className={CSS.padding_right_10}>
              <ProgressBar bgColor={info.color} percentage={info.percentage} />
            </div>
            <div>{info.percentage + "%"}</div>
            {props.filter !== "all" && (
              <div className={info.is_increased ? CSS.green : CSS.red}>
                {" "}
                <img
                  src={info.is_increased ? upArrow : downArrow}
                  alt="chart_info"
                />
                {" " + Math.abs(info.value) + " %"}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
