import axios, { AxiosHeaders } from "axios";
import { getCompanyId } from "../utils";

const reviewAndRatingAxiosInstance = axios.create({
  headers: {
    "x-company-id": undefined,
  },
});

reviewAndRatingAxiosInstance.interceptors.request.use((config) => {
  if (config && config.headers) {
    (config.headers as AxiosHeaders).set("x-company-id", getCompanyId());
  }
  return config;
});

export default reviewAndRatingAxiosInstance;
