import { SubscriptionStatusData } from "../pages/payment/payment.types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getSubscriptionStatus } from "../services/subscription.service";

interface ISubscriptionContextProvider {
  children: React.ReactNode;
}

interface ISubscriptionContext {
  subscription: SubscriptionStatusData;
  setSubscription: React.Dispatch<React.SetStateAction<SubscriptionStatusData>>;
}

export const SubscriptionContext = createContext<ISubscriptionContext>({
  subscription: {
    action: "",
    status: "",
    plan_name: "",
  },
  setSubscription: () => {
    /** empty function */
  },
});

export function SubscriptionContextProvider({
  children,
}: ISubscriptionContextProvider) {
  const [subscription, setSubscription] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    (async () => {
      const subscriptionApproved = params.get("approved");
      if (subscriptionApproved === "false") {
        setIsLoading(false);
        return;
      }
      try {
        const { data } = await getSubscriptionStatus();
        if (data) {
          if (data?.details?.status !== "no_renew") {
            setSubscription(data);
          } else {
            // todo: find better solution
            setTimeout(async () => {
              const { data } = await getSubscriptionStatus();
              setSubscription(data);
            }, 3000);
          }
        }
      } catch (ex) {
        console.log(ex);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <SubscriptionContext.Provider value={{ subscription, setSubscription }}>
      {!isLoading && children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscriptionContext() {
  return useContext<any>(SubscriptionContext);
}
