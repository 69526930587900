import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";
import { useConfigContext } from "../../../../context/config-context";

import BorderAtom from "../../../../components/atoms/border/border.component";
import TextAtom from "../../../../components/atoms/text/text";
import RightPanel from "../../../../components/templates/right-panel/right-panel.template";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import ToggleBox from "../../../../components/molecules/toggle-box/toggle-box.molecule";
import { defaultReviewFormConfig } from "./config/review-form.state.config";
import Collapsible from "../../../../components/molecules/collapsible/collapsible.molecule";
import ButtonAtom from "../../../../components/atoms/button/button.atom";
import { IRowData, ITableData } from "./config/review.form.table-type";
import SectionHeadingWithDescBox from "../../../../components/molecules/section-heading-description/section-header";
import TableMolecule from "./molecules/table/table.molecule";

import {
  getFormConfig,
  updateFormConfig,
} from "../../../../services/config.service";

import CSS from "./review-form.module.scss";
import {
  transformToQuestionData,
  transformToTableData,
} from "./molecules/table/table.utils";
import {
  showDangerToast,
  showSuccessToast,
  TOAST_MESSAGES,
} from "../../../../services/toast.service";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

interface IReviewFormConfig {
  heading: string;
  review_heading: string;
  review_body_heading: string;
  ratings_by_features: boolean;
  smart_assist: boolean;
  show_additional_questions: boolean;
  show_sharing_link: boolean;
  submit_button_text: string;
  additional_questions: string[];
  is_active: boolean;
}

const ReviewForm = () => {
  const [formData, setFormData] = useState<IReviewFormConfig>(
    defaultReviewFormConfig
  );
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const navigate = useNavigate();
  const { setConfig } = useConfigContext();
  const preventConfigUpdate = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await getFormConfig<IReviewFormConfig>("review_form");
        if (res?.data?.data) {
          setFormData(res.data.data?.[0]?.config);
          if (!res?.data?.data?.[0]?.config?.is_active) {
            preventConfigUpdate.current = true;
          }
          setTableData(
            transformToTableData(
              res.data.data?.[0]?.config?.additional_questions
            )
          );
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      (async () => {
        try {
          const res = await updateFormConfig<IReviewFormConfig>({
            widget: "review_form",
            payload: formData,
          });

          if (res?.data) {
            showSuccessToast(
              res?.data?.data?.[0]?.config?.is_active
                ? TOAST_MESSAGES.configEnabled
                : TOAST_MESSAGES.configDisabled
            );
          }
        } catch (err) {
          console.log(err);
          showDangerToast(
            formData?.is_active
              ? TOAST_MESSAGES.configEnabledError
              : TOAST_MESSAGES.configDisabledError
          );
          handleConfigChange(!formData?.is_active, "is_active");
          preventConfigUpdate.current = true;
        }
      })();
    } else {
      preventConfigUpdate.current = false;
    }
  }, [formData.is_active]);

  useEffect(() => {
    setConfig((prevData: any) => ({
      ...prevData,
      reviewFormConfig: { ...formData },
      type: "PRODUCT_REVIEW_FORM",
      ratingByFeature: ["Quality", "Value for money", "Comfortable"],
    }));
  }, [formData]);
  
  useEffect(() => {
    if (tableData) {
      const questions = transformToQuestionData(tableData);
      handleConfigChange(questions, "additional_questions");
    }
  }, [tableData]);

  const handleConfigChange = (
    value: string | number | boolean | string[] | object,
    field: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleTableChange = (data: {
    action: string;
    rowKey: string | number;
    rowData: IRowData[];
  }) => {
    switch (data.action) {
      case "ADD":
        setTableData((currentState) =>
          produce(currentState, (draft) => {
            draft?.push({
              key: uuidv4(),
              rowData: [
                {
                  value: "",
                },
              ],
            });
          })
        );
        break;
      case "CHANGE":
        setTableData((currentState) => {
          return produce(currentState, (draft) => {
            draft?.forEach((tableData: ITableData) => {
              if (tableData?.key === data.rowKey) {
                tableData.rowData = data.rowData;
              }
            });
          });
        });
        break;
      case "DELETE":
        setTableData((currentState) =>
          produce(currentState, (draft) => {
            const index = draft?.findIndex(
              (tableData: ITableData) => tableData?.key === data.rowKey
            );
            draft?.splice(index ?? 0, 1);
          })
        );
        break;
    }
  };

  const onSaveClick = async (event: any) => {
    event.stopPropagation();
    try {
      const res = await updateFormConfig<IReviewFormConfig>({
        widget: "review_form",
        payload: formData,
      });

      if (res?.data) {
        showSuccessToast(TOAST_MESSAGES.configUpdated);
      }
    } catch (err) {
      console.log(err);
      showDangerToast(TOAST_MESSAGES.configUpdateError);
    }
  };

  return (
    <div>
      <SectionHeadingWithDescBox
        id="1"
        heading="Review Form"
        description="Select your design layout and customise the settings as per your needs"
        value={formData?.is_active}
        onToggle={() => handleConfigChange(!formData?.is_active, "is_active")}
        onBackClick={useCallback(() => navigate(-1), [])}
        isPro={false}
      />

      <div className="cs-tm-24 cs-bm-16">
        <TextAtom
          fontWeight={600}
          id="text-heading"
          text="Settings"
          type="header"
        />
      </div>

      <div
        className={`${CSS["cs-grid-container"]} ${CSS["cs-template-30-70"]} ${CSS["cs-column-gap-25"]}`}
      >
        <div>
          <div className="cs-tm-12 cs-bm-14">
            <FieldInputBox
              value={formData.heading}
              onChange={(value) => handleConfigChange(value, "heading")}
              placeholder="Enter form heading"
              id="form-heading"
              label="Form Heading"
            />
          </div>
          <div
            className={`${CSS["cs-grid-container"]} ${CSS["cs-grid-of-two"]} ${CSS["cs-column-gap-16"]} ${CSS["cs-row-gap"]}`}
          >
            <FieldInputBox
              value={formData.review_heading}
              onChange={(value) => handleConfigChange(value, "review_heading")}
              placeholder="Enter review heading"
              id="field-control-review-heading"
              label="Review Heading"
            />

            <FieldInputBox
              value={formData.review_body_heading}
              onChange={(value) =>
                handleConfigChange(value, "review_body_heading")
              }
              placeholder="Enter review body heading"
              id="field-control-model-heading"
              label="Review Body Heading"
            />
          </div>

          <div className="cs-tm-24">
            <BorderAtom borderColor="#ededed" />
          </div>

          <div className="cs-bp-15 cs-tp-15">
            <ToggleBox
              id="ratings_by_features"
              value={formData.ratings_by_features}
              onToggle={() =>
                handleConfigChange(
                  !formData.ratings_by_features,
                  "ratings_by_features"
                )
              }
            >
              <TextAtom
                id="ratings_by_features-txt"
                text="Ratings By Features"
                type="heading"
              />
            </ToggleBox>
          </div>
          <BorderAtom borderColor="#ededed" />

          {/* <div className="cs-bp-15 cs-tp-15">
            <ToggleBox
              id="displayDate"
              value={formData.smart_assist}
              onToggle={() =>
                handleConfigChange(!formData.smart_assist, "smart_assist")
              }
            >
              <TextAtom
                className={CSS["cs-vt-center"]}
                id="heading-smart-assist"
                text="Smart Assist"
                type="heading"
              />
            </ToggleBox>   
          </div> */}
          <BorderAtom borderColor="#ededed" />

          <Collapsible
            className="cs-bp-15 cs-tp-15"
            type={"heading"}
            title="Additional Questions"
            titleClassName={CSS["cs-vt-center"]}
          >
            <div className="cs-bm-22">
              <ToggleBox
                id="add_additional_questions"
                value={formData.show_additional_questions}
                onToggle={() =>
                  handleConfigChange(
                    !formData.show_additional_questions,
                    "show_additional_questions"
                  )
                }
              >
                <TextAtom
                  id="add_additional_question_title"
                  text="Add Additional Question"
                  type="title"
                />
                <TextAtom
                  id="add_additional_question_label"
                  text="Choose the category and add a couple of individual characteristics applicable to the products in that category"
                  type="label"
                />
              </ToggleBox>

              {formData.show_additional_questions ? (
                <>
                  <div className="cs-tm-12 cs-bm-12">
                    <ButtonAtom
                      label="Add Questions"
                      onClick={() =>
                        handleTableChange({
                          action: "ADD",
                          rowKey: "1",
                          rowData: [],
                        })
                      }
                      theme="secondary"
                      color="#2E31BE"
                      backgroundColor="#fff"
                      padding="5.5px 12px"
                    />
                  </div>
                  {tableData?.length !== 0 && (
                    <TableMolecule
                      placeholder="Question Title"
                      headers={["Question Title"]}
                      tableData={tableData}
                      handleChange={handleTableChange}
                      limitOnTextInput={60}
                    />
                  )}
                </>
              ) : null}
            </div>
          </Collapsible>
          <BorderAtom borderColor="#ededed" />

          <div className="cs-bp-15 cs-tp-15">
            <ToggleBox
              id="show_sharing_link"
              value={formData.show_sharing_link}
              onToggle={() =>
                handleConfigChange(
                  !formData.show_sharing_link,
                  "show_sharing_link"
                )
              }
            >
              <TextAtom
                id="show_sharing_link-txt"
                text="Show The Sharing Link On Form Success State"
                type="heading"
              />
            </ToggleBox>
          </div>
          <BorderAtom borderColor="#ededed" />

          <div className="cs-tm-12 cs-bm-14">
            <FieldInputBox
              value={formData.submit_button_text}
              onChange={(value) =>
                handleConfigChange(value, "submit_button_text")
              }
              placeholder="Enter button text"
              id="Button text"
              label="Button text"
            />
          </div>

          <div className="cs-tm-26">
            <ButtonAtom label="Save" onClick={(event) => onSaveClick(event)} />
          </div>
        </div>

        <div>
          <RightPanel></RightPanel>
        </div>
      </div>
    </div>
  );
};

export default ReviewForm;
