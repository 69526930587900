import axiosInstance from "../../../../../services/axios.service";
import { getApplicationInfo, getCompanyId } from "../../../../../utils";

interface IProductReviews {
  page_size: number;
  page_no: number;
  search?: string;
  rating?: number;
  status?: string;
}

interface ISetReviewFavouriteResponse {
  message: string;
  data: [{}];
  ok: boolean;
}

interface IGetAutoGeneratedReplyResponse {
  message: string;
  data: {
    autoReply: string;
  };
}

interface ISetReviewUnfavouriteResponse {
  message: string;
  data: [{}];
  ok: boolean;
}
export const getProductReview = async (props: IProductReviews) => {
  const { applicationId } = getApplicationInfo();
  /**
   * @todo adding appropriate endpoints
   */
  return axiosInstance.get(
    `api/v1.0/company/${getCompanyId()}/application/${applicationId}/review-rating/entity/product/`,
    { params: props }
  );
};

export const setReviewFavourite = async (reviewId: string) => {
  const { applicationId } = getApplicationInfo();

  return axiosInstance.post<ISetReviewFavouriteResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/reviews/${reviewId}/favourite?company_id=${getCompanyId()}`,
    {}
  );
};

export const setReviewUnfavourite = async (reviewId: string) => {
  const { applicationId } = getApplicationInfo();

  return axiosInstance.post<ISetReviewUnfavouriteResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/reviews/${reviewId}/unfavourite?company_id=${getCompanyId()}`,
    {}
  );
};

export const getGeneratedReply = async (reviewId: string) => {
  const { applicationId } = getApplicationInfo();

  return axiosInstance.get<IGetAutoGeneratedReplyResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/reviews/${reviewId}/generate-reply?company_id=${getCompanyId()}`
  );
};
