import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  SalesChannel,
  overWriteBreadCrumpsItems,
  setBreadCrumpsItems,
} from "nitrozen-react-extension";
import getApplications from "./services/sales-channel.service";
import CSS from "./sales-channel.module.scss";

import { setApplicationInfo, setCompanyId } from "../../utils";

export type SalesChannelProps = Record<string, never>;

export type Pagination = {
  current: number;
  has_next: boolean;
  item_total: number;
  size: number;
  type: string;
};

interface SalesChannel {
  is_active: boolean;
  id: string;
  name: string;
  logo: {
    secure_url: string;
  };
  domain: {
    name: string;
  };
  handleSalesChannelClick: (id: string, is_active: boolean) => void;
}

interface IPaginationData {
  limit: number;
  total: number;
  current: number;
}

export type ApplicationType = {
  items: Array<SalesChannel>;
  page: Pagination;
};

const SalesChannelPage: React.FunctionComponent = () => {
  const [applications, setApplications] = useState<ApplicationType>();
  const [limit, setLimit] = useState(10);
  const [current, setCurrent] = useState(1);
  const [search, setSearch] = useState("");
  const pageSizeOptions = [10, 20, 50];
  const navigate = useNavigate();
  const location = useLocation();

  const { company_id: companyId } = useParams();
  useEffect(() => {
    if (companyId) setCompanyId(companyId);
  }, []);

  const handleSearchTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event?.target?.value);
    },
    []
  );

  const handlePaginationChange = useCallback(
    async (paginationData: IPaginationData) => {
      setLimit(paginationData.limit);
      setCurrent(paginationData.current);
    },
    []
  );

  const handleSalesChannelClick = (
    applicationId: string,
    is_active?: string
  ): void => {
    const salesChannel: SalesChannel[] | undefined = applications?.items.filter(
      (item) => {
        return item.id === applicationId;
      }
    );
    setBreadCrumpsItems("Home", location.pathname + location.search);
    if (salesChannel) {
      const applicationData = {
        applicationId: salesChannel[0]?.id,
        is_active: salesChannel[0]?.is_active,
        domain: salesChannel[0]?.domain,
      };
      setApplicationInfo(applicationData);
      setBreadCrumpsItems(
        salesChannel[0].name,
        location.pathname + location.search
      );
      setBreadCrumpsItems("Features", location.pathname + location.search);
    }

    if (applicationId) {
      navigate(`/features-page?status=${is_active}`);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getApplications({
          page: current,
          limit,
          search,
        });
        const { data } = response;
        setApplications(() => data as ApplicationType);
      } catch (error) {
        console.log("FAILED TO FETCH APPLICATION LISTING", error);
      }
    })();
    overWriteBreadCrumpsItems([]);
  }, [limit, current, search]);

  if (applications && Array.isArray(applications?.items)) {
    return (
      <div className={CSS.container}>
        <SalesChannel
          heading="Sales Channel"
          desc="These are the sales channel where this extension is active or inactive"
          searchText={search}
          handleSearchTextChange={handleSearchTextChange}
          applicationList={applications}
          handleSalesChannelClick={handleSalesChannelClick}
          limit={limit}
          current={current}
          pageSizeOptions={pageSizeOptions}
          handlePaginationChange={handlePaginationChange}
        />
      </div>
    );
  }

  return <></>;
};

export default SalesChannelPage;
