export const defaultApplicationConfig = {
  rating_type: "star_rating",
  title: "Rate Your Experience",
  description: "Happy to hear from you",
  button_text: "Submit",
  has_description: true,
  has_map_validation: true,
  map_validation: {
    poor: "Let us know how we can improve",
    bad: "Let us know what went wrong",
    average: "How can we serve you better?",
    good: "Provide us your valuable feedback",
    excellent: "We're thrilled to hear your experience",
  },
  has_map_tags: true,
  map_tags: {
    poor: ["Helpful", "Delivery", "Quality"],
    bad: ["Helpful", "Delivery", "Quality"],
    average: ["Helpful", "Delivery", "Quality"],
    good: ["Helpful", "Delivery", "Quality"],
    excellent: ["Helpful", "Delivery", "Quality"],
  },
  is_active: true,
};

export const ratingTypeOptions = [
  {
    text: "Star Rating",
    value: "star_rating",
  },
  {
    text: "Number Rating",
    value: "number_rating",
  },
  {
    text: "Emoji Rating",
    value: "emoji_rating",
  },
];
