import React, { memo, useState } from "react";
import CrossIcon from "../../../assets/cross.svg";
import CSS from "./input-with-tags.atom.module.scss";

interface InputTextWithTagsAtomProps {
  placeholder?: string;
  tags?: string[];
  onChange: (tags: string[]) => void;
  limit?: number;
}

const InputTextWithTagsAtom: React.FunctionComponent<
  InputTextWithTagsAtomProps
> = ({
  placeholder = "Add Tag",
  tags = [],
  onChange = (tags: string[]) => {},
  limit = undefined,
}) => {
  const [input, setInput] = useState<string>("");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    const trimmedInput = input.trim() ?? "";

    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      event.preventDefault();
      if (limit) {
        if (tags?.length < limit) {
          onChange([...tags, trimmedInput]);
          setInput("");
        }
      } else {
        onChange([...tags, trimmedInput]);
        setInput("");
      }
    }
  };

  const deleteTag = (tagIndex: number) => {
    const newTags = tags.filter((tag, i) => i !== tagIndex);
    onChange(newTags);
  };

  return (
    <div className={CSS.main_wrapper}>
      <div className={CSS.tag_wrapper}>
        {tags.map((tag, index) => (
          <div className={CSS.tag}>
            {tag}
            <button
              onClick={() => deleteTag(index)}
              className={CSS.tag_cross_button}
            >
              <img src={CrossIcon} alt="cross" />
            </button>
          </div>
        ))}
      </div>
      <input
        type={"text"}
        value={input}
        placeholder={placeholder}
        onKeyUp={(event) => onKeyUp(event)}
        onChange={(event) => onInputChange(event)}
      />
    </div>
  );
};

export default memo(InputTextWithTagsAtom);
