import React, { memo } from "react";
import CSS from "./layout.atom.module.scss";
import center_modal from "../../../assets/center-modal.png";
import withSubscription from "../../../hoc/subscription-hoc/subscription.hoc";

export interface LayoutAtomProps {
  onClick: (id: number) => void;
  item: { id: number; name: string; src?: string, isPro?: boolean };
}

function Layout({ item, onClick }: LayoutAtomProps): JSX.Element {
  const handleSelect = (value: number) => {
    onClick(value);
  };


  return (
    <>
      <div data-testid={"layout-atom" + item.id}
        onClick={() => handleSelect(item.id)}>
        <img
          alt="layout-atom"
          className={`${CSS["cs-width-max"]} ${CSS["cs-obj-fit-cover"]}`}
          src={item.src || center_modal}
        />
      </div>
    </>
  );
}

const LayoutAtom = memo(withSubscription(Layout));
export default LayoutAtom;
