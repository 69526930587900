import { ChangeEvent, useEffect, useState } from "react";
import { ColorInputProps } from "./color-input-props";
import CSS from "./color-input.component.module.scss";

const removeHash = (color: string) => {
  if (!color) {
    return;
  }
  return color.substring(1);
};

function ColorInput({ value = "#000000", onChange }: ColorInputProps) {
  const [color, setColor] = useState(value);
  const [textInput, setTextInput] = useState(removeHash(value));

  useEffect(() => {
    setColor(value)
    setTextInput(removeHash(value))
  }, [value])

  const handleColorPickerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const color = e.target.value;
    setTextInput(removeHash(color) || "000000");
    setColor(color);
    onChange?.(color);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTextInput(value);
    if (value?.length === 6) {
      const color = `#${value}`;
      setColor(color);
      onChange?.(color);
    }
  };

  return (
    <div className={CSS["color-input"]}>
      <div className={CSS["color-input__text"]}>
        <div className={CSS["color-input__unit"] + " flex-center"}>#</div>
        <input type="text" value={textInput} onChange={handleInputChange} />
        <input type="color" value={color} onChange={handleColorPickerChange} />
      </div>
    </div>
  );
}

export default ColorInput;
