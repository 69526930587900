import ProductContainerOrganism from "../organism/review-container/review.container.organism";
import dropdownIc from "../assets/icons/dropdownIC.png";
import CSS from "../table.module.scss";
import { IApplicationReviewData } from "../../../service/application-review.service";
import ReviewContainerOrganism from "../organism/review-container/review.container.organism";
import TextAtom from "../../../../../../../components/atoms/text/text";
import StarAtom from "../../../../../../../components/atoms/star/star.atom";

export const tableColumnData = () => [
  {
    field: "Review",
    width: 700,
    headerComponent: () => <div style={{ display: "flex" }}>Review</div>,
    cellStyle: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    autoHeight: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: (content: { data: IApplicationReviewData }) => {
      return (
        <ReviewContainerOrganism
          tags={content.data?.tags}
          reviewText={content?.data?.description}
          id="main-table-vew"
        />
      );
    },
  },
  {
    field: "Order Id",
    width: 250,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
    },
    cellRenderer: (content: { data: IApplicationReviewData }) => {
      return (
        <>
          <TextAtom
            className="title"
            text={content?.data?.order_id}
            fontWeight={400}
          />
        </>
      );
    },
  },
  {
    field: "rating",
    width: 200,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
    },
    cellRenderer: (content: { data: IApplicationReviewData }) => {
      return (
        <>
          <StarAtom defaultRating={content.data?.rating} />
        </>
      );
    },
  },
  {
    field: "Review Date",
    width: 250,
    autoHeight: true,
    cellStyle: {
      display: "flex",
      alignItem: "center",
    },
    cellRenderer: (content: { data: IApplicationReviewData }) => {
      const formattedDate = new Date(content.data.created_at).toLocaleString(
        "en-US",
        {
          day: "2-digit",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
      );
      return <div>{formattedDate.replace(/,/g, "")}</div>;
    },
  },
];
