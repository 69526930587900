import { useCallback, useEffect, useRef, useState } from "react";
import { produce } from "immer";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useConfigContext } from "../../../../context/config-context";

import BorderAtom from "../../../../components/atoms/border/border.component";
import TextAtom from "../../../../components/atoms/text/text";
import Collapsible from "../../../../components/molecules/collapsible/collapsible.molecule";
import FieldColorBox from "../../../../components/molecules/field-control/color-box/color.molecule";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import LayoutMolecule from "../../../../components/molecules/field-control/layout-box/layout-box.molecules";
import FieldSelect from "../../../../components/molecules/field-control/select/select.molecule";
import {
  layoutItems,
  ratingType,
  themeColour,
  whenNoReview,
  whoCanReview,
} from "../../../../pages/display-widgets/components/product-detail/config/product-details.config";
import RightPanel from "../../../../components/templates/right-panel/right-panel.template";
import ButtonAtom from "../../../../components/atoms/button/button.atom";
import ToggleBox from "../../../../components/molecules/toggle-box/toggle-box.molecule";
import { defaultProductDetailsConfig } from "./config/product-details.state.config";
import SectionHeadingWithDescBox from "../../../../components/molecules/section-heading-description/section-header";
import ProductFeatureTable, {
  ICategoryOption,
  IRowData,
  ITableData,
} from "./molecules/product-feature-table/product-feature-table.molecule";

import {
  TOP_REVIEW,
  REVIEW_WITH_IMAGES,
  REVIEW_METRICS,
} from "./config/constant";

import CSS from "./product-detail.module.scss";
import {
  getFormConfig,
  updateFormConfig,
} from "../../../../services/config.service";
import {
  transformCategoryToTableData,
  transformTableToCategoryData,
} from "./molecules/product-feature-table/product-features-table.utils";
import {
  getCatalogCategories,
  ICatalogCategory,
} from "../../../../services/categories.service";
import {
  showDangerToast,
  showSuccessToast,
  TOAST_MESSAGES,
} from "../../../../services/toast.service";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import Modal from "../../../../components/molecules/modal/modal.molecule";
import { useSubscriptionContext } from "../../../../context/subscription-context";
import ConfirmBody from "../../../../components/templates/confirm-modal";

export interface IProductDetailConfig {
  star_color: string;
  can_review: string;
  no_review: string;
  section_heading: string;
  theme: string;
  rating_type: string;
  rating_btn_text: string;
  seller_reply_title: string;
  selected_layout: number;
  display_all_media: boolean;
  display_user_avatar: boolean;
  display_date: boolean;
  display_review_images: boolean;
  has_rating_by_feature: boolean;
  ratings_by_features: any[];
  report_button: boolean;
  threshold: number;
  is_active: boolean;
  auto_publish: boolean;
}

const ProductDetails = () => {
  const [formData, setFormData] = useState<IProductDetailConfig>(
    defaultProductDetailsConfig
  );
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [categoryData, setCategoryData] = useState<ICatalogCategory[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<ICategoryOption[]>([]);
  const { setConfig } = useConfigContext();

  const navigate = useNavigate();
  const preventConfigUpdate = useRef<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { subscription } = useSubscriptionContext();

  useEffect(() => {
    (async () => {
      try {
        const res = await getFormConfig<IProductDetailConfig>("product_review");
        if (res?.data?.data) {
          setFormData(res.data.data?.[0]?.config);

          if (!res?.data?.data?.[0]?.config?.is_active) {
            preventConfigUpdate.current = true;
          }

          setTableData(
            transformCategoryToTableData(
              res.data.data?.[0]?.config?.ratings_by_features
            )
          );
        }
      } catch (err) {
        console.log(err);
      }

      try {
        const params = { pageSize: 5000, pageNo: 1 };

        const res = await getCatalogCategories(params);
        if (res?.data?.data) {
          setCategoryData(res?.data?.data?.[0]?.categories);

          const categoryOptions = res?.data?.data?.[0]?.categories?.map(
            (category: ICatalogCategory) => {
              return {
                text: category?.name,
                value: category?.slug,
              };
            }
          );
          setCategoryOptions(categoryOptions);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    setConfig((prevData: any) => ({
      ...prevData,
      reviewMetrics: REVIEW_METRICS,
      imagesByProduct: REVIEW_WITH_IMAGES,
      reviewListItem: TOP_REVIEW.items,
      pageItem: TOP_REVIEW.page,
      selectedOption: "desc_date",
      type: "PRODUCT_RATING_REVIEW",
      ratingByFeaturePDP: [
        { feature: "Quality", average_rating: 5 },
        { feature: "Value for money", average_rating: 4.5 },
        { feature: "Comfortable", average_rating: 1 },
      ],
    }));
  }, []);

  useEffect(() => {
    setConfig((prevData: any) => ({
      ...prevData,
      productReviewConfig: { ...formData },
    }));
  }, [formData]);

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      (async () => {
        try {
          const res = await updateFormConfig<IProductDetailConfig>({
            widget: "product_review",
            payload: formData,
          });

          if (res?.data) {
            showSuccessToast(
              res?.data?.data?.[0]?.config?.is_active
                ? TOAST_MESSAGES.configEnabled
                : TOAST_MESSAGES.configDisabled
            );
          }
        } catch (err) {
          console.log(err);
          showDangerToast(
            formData?.is_active
              ? TOAST_MESSAGES.configEnabledError
              : TOAST_MESSAGES.configDisabledError
          );
          handleConfigChange(!formData?.is_active, "is_active");
          preventConfigUpdate.current = true;
        }
      })();
    } else {
      preventConfigUpdate.current = false;
    }
  }, [formData.is_active]);

  const handleConfigChange = (
    value: string | number | boolean | string[],
    field: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleTableChange = async (data: {
    action: string;
    rowKey: string | number;
    rowData: IRowData[];
    id?: string;
  }) => {
    switch (data.action) {
      case "ADD":
        setTableData((currentState) =>
          produce(currentState, (draft) => {
            draft?.push({
              key: uuidv4(),
              rowData: [
                {
                  value: "",
                },
                {
                  value: [],
                },
              ],
            });
          })
        );
        break;
      case "CHANGE":
        setTableData((currentState) => {
          return produce(currentState, (draft) => {
            draft?.forEach((tableData: ITableData) => {
              if (tableData?.key === data.rowKey) {
                tableData.rowData = data.rowData;
                if (data?.id) {
                  tableData._id = data?.id;
                }
              }
            });
          });
        });
        break;
      case "DELETE":
        setTableData((currentState) =>
          produce(currentState, (draft) => {
            const index = draft?.findIndex(
              (tableData: ITableData) => tableData?.key === data.rowKey
            );
            draft?.splice(index, 1);
          })
        );
        break;
    }
  };

  const onSaveClick = async (event: any) => {
    event.stopPropagation();

    const featuresData = transformTableToCategoryData(tableData);

    const featuresNewData = featuresData?.map((feature) => {
      const category = categoryData?.find(
        (category) => category.slug === feature?.category
      );

      return { ...category, ...feature };
    });

    try {
      const res = await updateFormConfig<IProductDetailConfig>({
        widget: "product_review",
        payload: { ...formData, ratings_by_features: featuresNewData },
      });

      if (res?.data) {
        showSuccessToast(TOAST_MESSAGES.configUpdated);
      }
    } catch (err) {
      console.log(err);
      showDangerToast(TOAST_MESSAGES.configUpdateError);
    }
  };
  const handleCloseModal = useCallback(() => {
    setShowUpgradeModal(false);
  }, []);

  const handleProModal = useCallback(() => {
    setShowUpgradeModal(true);
  }, []);

  return (
    <div>
      <div className="cs-bm-24">
        <SectionHeadingWithDescBox
          id="1"
          heading="Product Detail Reviews"
          description="Select your design layout and customise the settings as per your needs"
          value={formData?.is_active}
          onToggle={() => handleConfigChange(!formData?.is_active, "is_active")}
          onBackClick={useCallback(() => navigate(-1), [])}
          isPro={formData.selected_layout !== 1}
        />
      </div>

      <div
        className={`${CSS["cs-grid-container"]} ${CSS["cs-template-30-70"]} ${CSS["cs-column-gap-25"]}`}
      >
        <div>
          <div className="cs-bm-16">
            <TextAtom
              fontWeight={600}
              id="text-heading"
              text="Layouts"
              type="header"
            />
          </div>

          <LayoutMolecule
            selected={formData.selected_layout}
            items={layoutItems}
            id="design-layout"
            onClick={(value) => handleConfigChange(value, "selected_layout")}
          />

          <div className="cs-tm-24 cs-bm-12">
            <TextAtom
              id="text-settings"
              text="Settings"
              type="header"
              fontWeight={600}
            />
          </div>

          <div
            className={`${CSS["cs-grid-container"]} ${CSS["cs-grid-of-two"]} ${CSS["cs-column-gap-16"]}`}
          >
            {/* 1 */}

            <FieldSelect
              items={whoCanReview}
              value={formData.can_review}
              onChange={(value) => handleConfigChange(value, "can_review")}
              placeholder="Select who can review"
              id="who-can-review"
              label="Who Can Review"
            />

            <FieldSelect
              items={whenNoReview}
              value={formData.no_review}
              onChange={(value) => handleConfigChange(value, "no_review")}
              placeholder="When No Review"
              id="who-can-review"
              label="When No Review"
            />

            {/* 2 */}

            <FieldInputBox
              onChange={(value) => handleConfigChange(value, "section_heading")}
              value={formData.section_heading}
              placeholder="Customer Reviews"
              id="customer-reviews"
              label="Section Heading"
            />

            <FieldColorBox
              label="Star Color"
              value={formData.star_color}
              onChange={(value) => handleConfigChange(value, "star_color")}
            />

            <FieldSelect
              value={formData.theme}
              items={themeColour}
              onChange={(value) => handleConfigChange(value, "theme")}
              placeholder="Select theme color"
              id="who-can-review"
              label="Rating Bar Type"
            />

            <FieldSelect
              value={formData.rating_type}
              items={ratingType}
              onChange={(value) => handleConfigChange(value, "rating_type")}
              placeholder="Select rating type"
              id="who-can-review"
              label="Rating Type"
            />

            <FieldInputBox
              value={formData.rating_btn_text}
              onChange={(value) => handleConfigChange(value, "rating_btn_text")}
              placeholder="Enter text"
              id="field-control"
              label="Rating Button Text"
            />

            <FieldInputBox
              value={formData.seller_reply_title}
              onChange={(value) =>
                handleConfigChange(value, "seller_reply_title")
              }
              placeholder="Seller Name"
              id="field-control"
              label="Reply From"
            />
          </div>

          <div className="cs-tm-24">
            <BorderAtom borderColor="#ededed" />
          </div>

          <div>
            <Collapsible
              className="cs-bp-15 cs-tp-15"
              type={"heading"}
              title="Ratings By Features"
              titleClassName={CSS["cs-vt-center"]}
            >
              <div className="cs-bm-22">
                <div className="cs-bm-10">
                  <ToggleBox
                    id="hasRatingByFeature"
                    value={formData.has_rating_by_feature}
                    onToggle={() =>
                      handleConfigChange(
                        !formData.has_rating_by_feature,
                        "has_rating_by_feature"
                      )
                    }
                  >
                    <TextAtom
                      id="mapProductFeatures"
                      text="Map Product Features"
                      type="title"
                    />
                    <TextAtom
                      id="5"
                      text="Add category and characteristics for feature review."
                      type="label"
                    />
                  </ToggleBox>
                </div>

                {formData?.has_rating_by_feature && (
                  <>
                    <ButtonAtom
                      label="Add Features"
                      onClick={() => {
                        handleTableChange({
                          action: "ADD",
                          rowKey: "1",
                          rowData: [],
                        });
                      }}
                      theme="secondary"
                      color="#2E31BE"
                      backgroundColor="#fff"
                      padding="5.5px 12px"
                    />
                    {tableData?.length !== 0 && (
                      <div className="cs-tm-22">
                        <ProductFeatureTable
                          headers={["Feature Category", "Feature Tags"]}
                          handleChange={handleTableChange}
                          tableData={tableData}
                          categoryOptions={categoryOptions}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </Collapsible>
            <BorderAtom borderColor="#ededed" />

            <div className="cs-bp-15 cs-tp-15">
              <ToggleBox
                id="displayAllMedia"
                value={formData.display_all_media}
                onToggle={() =>
                  handleConfigChange(
                    !formData.display_all_media,
                    "display_all_media"
                  )
                }
              >
                <TextAtom
                  className={CSS["cs-vt-center"]}
                  id="heading"
                  text="Display All Media"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <BorderAtom borderColor="#ededed" />

            <div className="cs-bp-15 cs-tp-15">
              <ToggleBox
                id="3"
                value={formData.display_user_avatar}
                onToggle={() =>
                  handleConfigChange(
                    !formData.display_user_avatar,
                    "display_user_avatar"
                  )
                }
              >
                <TextAtom
                  className={CSS["cs-vt-center"]}
                  id="displayUserAvatar"
                  text="Display User Avatar"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <BorderAtom borderColor="#ededed" />

            <div className="cs-bp-15 cs-tp-15">
              <ToggleBox
                id="displayDate"
                value={formData.display_date}
                onToggle={() =>
                  handleConfigChange(!formData.display_date, "display_date")
                }
              >
                <TextAtom
                  className={CSS["cs-vt-center"]}
                  id="heading-display-date"
                  text="Display Date"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <BorderAtom borderColor="#ededed" />

            <div className="cs-bp-15 cs-tp-15">
              <ToggleBox
                id="displayReviewImages"
                value={formData.display_review_images}
                onToggle={() =>
                  handleConfigChange(
                    !formData.display_review_images,
                    "display_review_images"
                  )
                }
              >
                <TextAtom
                  className={CSS["cs-vt-center"]}
                  id="4"
                  text="Display Review Images"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <BorderAtom borderColor="#ededed" />
            <div className="cs-bp-15 cs-tp-15">
              <ToggleBox
                id="reportButton"
                value={formData.report_button}
                onToggle={() =>
                  handleConfigChange(!formData.report_button, "report_button")
                }
              >
                <TextAtom
                  className={CSS["cs-vt-center"]}
                  id="4"
                  text="Report Abuse"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <BorderAtom borderColor="#ededed" />
          </div>
          <div className="cs-tm-24">
            <ButtonAtom
              label="Save"
              onClick={
                subscription?.plan_type === "basic" &&
                formData.selected_layout !== 1
                  ? handleProModal
                  : onSaveClick
              }
            />
          </div>
        </div>

        <div>
          <RightPanel />
        </div>
      </div>
      {showUpgradeModal && (
        <div>
          <Modal
            showModal={showUpgradeModal}
            handleCloseModal={handleCloseModal}
            id="upgrade"
            kind="informational"
            title=""
            isClosable={false}
            style={{ width: "22%" }}
          >
            <ConfirmBody
              onCancel={handleCloseModal}
              onSuccess={() => navigate("/payment")}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
