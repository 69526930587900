import { memo, useEffect, useRef, useState } from "react";
import { produce } from "immer";

import InputWithTagsAtom from "../../../../../../components/atoms/input-with-tags/input-with-tags.atom";
import SelectAtom from "../../../../../../components/atoms/select/select.atom";

import EditIcon from "../../../../../../assets/edit.svg";
import DeleteIcon from "../../../../../../assets/delete.svg";
import TickIcon from "../../../../../../assets/tick.svg";
import CrossIcon from "../../../../../../assets/cross.svg";

import CSS from "./product-feature-table.module.scss";
import {
  createProductFeatures,
  deleteProductFeatures,
  updateProductFeatures,
} from "../../../../../../services/features.service";
import {
  getCatalogCategories,
  ICatalogCategory,
} from "../../../../../../services/categories.service";

export interface ITableProps {
  headers?: string[];
  tableData?: ITableData[];
  categoryOptions: ICategoryOption[];
  handleChange: (data: {
    action: string;
    rowKey: string | number;
    rowData: IRowData[];
    id?: string;
  }) => void;
}

export interface IRowData {
  value: string | string[];
}

export interface ITableData {
  key: string | number;
  _id?: string;
  rowData: IRowData[];
}

export type ICategoryOption = {
  text: string;
  value: string;
};

const ProductFeatureTable: React.FunctionComponent<ITableProps> = ({
  headers = [],
  tableData = [],
  categoryOptions = [],
  handleChange = () => {},
}) => {
  const [tableDataCopy, setTableDataCopy] = useState<ITableData[]>(tableData);
  const [prevTableLength, setPrevTableLength] = useState<number>(0);
  const [inEditMode, setInEditMode] = useState<{
    status: boolean;
    rowKey: number | string | null;
  }>({
    status: false,
    rowKey: null,
  });
  const isFirstRender = useRef(true);

  useEffect(() => {
    setTableDataCopy(tableData);

    if (tableData?.length > prevTableLength && !isFirstRender.current) {
      const newData = tableData[tableData?.length - 1];

      setInEditMode({
        status: true,
        rowKey: newData?.key ?? null,
      });
    }

    setPrevTableLength(tableData?.length);
    isFirstRender.current = false;
  }, [tableData]);

  const handleOnChange = (
    key: number | string,
    index: number,
    value: string | string[]
  ) => {
    setTableDataCopy((prevState) => {
      return produce(prevState, (draft: ITableData[]) => {
        draft?.forEach((tableData: ITableData) => {
          if (tableData?.key === key) {
            tableData.rowData[index].value = value;
          }
        });
      });
    });
  };

  const handleEditButtonClick = (key: number | string) => {
    setInEditMode({
      status: true,
      rowKey: key,
    });
  };

  const handleCrossButtonClick = () => {
    setTableDataCopy(tableData);
    setInEditMode({
      status: false,
      rowKey: null,
    });
  };

  const handleSaveButtonClick = async (key: string | number) => {
    const newTableData = tableDataCopy?.find(
      (tableData) => tableData.key === key
    );

    if (
      newTableData?.rowData?.[0]?.value?.length === 0 ||
      newTableData?.rowData?.[1]?.value?.length === 0
    ) {
      handleDeleteButtonClick(newTableData?.key);
    } else {
      handleChange({
        action: "CHANGE",
        rowKey: key,
        rowData: newTableData?.rowData ?? [],
      });

      setInEditMode({
        status: false,
        rowKey: null,
      });
    }
  };

  const handleDeleteButtonClick = async (key: string | number) => {
    handleChange({ action: "DELETE", rowKey: key, rowData: [] });
  };

  return (
    <div className={CSS.table_wrapper}>
      <table className={CSS.product_table}>
        <thead className={CSS.table_heading}>
          <tr className={CSS.table_row}>
            {headers?.map((heading) => {
              return <th>{heading}</th>;
            })}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableDataCopy?.map((item: ITableData) => {
            return (
              <tr key={item.key} className={CSS.table_row}>
                {item.rowData?.map((row: IRowData, index: number) => {
                  return (
                    <>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === item.key ? (
                          <>
                            {Array.isArray(row.value) ? (
                              <InputWithTagsAtom
                                tags={row.value}
                                onChange={(tags) => {
                                  handleOnChange(item.key, index, tags);
                                }}
                                limit={3}
                              />
                            ) : (
                              <SelectAtom
                                value={row.value}
                                onChange={(text) => {
                                  handleOnChange(item.key, index, text);
                                }}
                                items={categoryOptions}
                                placeholder="Please Select"
                                isSearchable={true}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {Array.isArray(row.value)
                              ? row.value.join(" | ")
                              : row.value}
                          </>
                        )}
                      </td>
                    </>
                  );
                })}
                <td>
                  {inEditMode.status && inEditMode.rowKey === item.key ? (
                    <div className={CSS.table_action_wrapper}>
                      <button
                        className={CSS.table_action_button}
                        onClick={() => handleSaveButtonClick(item.key)}
                      >
                        <img src={TickIcon} alt="tick" />
                      </button>
                      <button
                        className={CSS.table_action_button}
                        onClick={() => handleCrossButtonClick()}
                      >
                        <img src={CrossIcon} alt="cross" />
                      </button>
                    </div>
                  ) : (
                    <div className={CSS.table_action_wrapper}>
                      <button
                        className={CSS.table_action_button}
                        onClick={() => handleEditButtonClick(item.key)}
                      >
                        <img src={EditIcon} alt="edit" />
                      </button>
                      <button
                        className={CSS.table_action_button}
                        onClick={() => handleDeleteButtonClick(item.key)}
                      >
                        <img src={DeleteIcon} alt="delete" />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default memo(ProductFeatureTable);
