import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import CSS from './preview.template.module.scss';
import { useParams } from 'react-router-dom';
import { ConfigContextProvider, useConfigContext } from "../../../context/config-context";
import { ConfigMessage } from "../../../configs/message";
import { getCompanyId } from "../../../utils/index";

interface IPreviewProps {
    isMobile: boolean;
}

const Preview: React.FunctionComponent<IPreviewProps> = ({ isMobile }) => {
    const [iframeReady, setIframeReady] = useState(false);
    const { application_id } = useParams();
    const { config } = useConfigContext()

    const iFrameRef = useRef<HTMLIFrameElement | null>(null);

    const sendData = (data: object) => {
        console.log("DATA TO SEND", data);
        data = JSON.parse(JSON.stringify(data));
        iFrameRef?.current?.contentWindow?.postMessage(
            data,
            window.location.origin as any
        );
    };

    const handleIframeReady = useCallback((event: any) => {
        if (event.data.auth === "cHJldmlldw==" && event.data.data === "ready") {
            setIframeReady(true);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("message", handleIframeReady);
        return () => {
            window.removeEventListener("message", handleIframeReady);
        };
    }, [handleIframeReady]);

    useEffect(() => {
        if (!iframeReady) {
            return;
        }
        sendData(
            new ConfigMessage({
                data: {
                    ...config,
                    company_id: getCompanyId(),
                    application_id: application_id,
                    isMobile: isMobile,
                    mode: "PREVIEW",
                    // type: type,
                },
            })
        );
    }, [iframeReady, config, isMobile, application_id]);

    return (
        <iframe
            src="/preview"
            ref={iFrameRef}
            title="preview"
            className={
                isMobile
                    ? `${CSS["iframeMobile"]}`
                    : `${CSS["iframe"]}`
            }
        />
    )
}

export default Preview