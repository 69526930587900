import ReviewIcon from "../../assets/review-icon.svg";
import HelpIcon from "../../assets/help-icon.svg";
import PreviewIcon from "../../assets/preview-icon.svg";

export const defaultManageEmails = [
  {
    name: "Product Review",
    description:
      "Customise and send emails to gather valuable product reviews from verified buyers.",
    is_active: true,
    path: "/features-page/manage-emails/product-detail",
    icon: ReviewIcon,
    type: "email_product_review",
    isPro: true,
  },
  {
    name: "Questions and Answers",
    description:
      "Customise and send emails when brand answers any customer queries.",
    is_active: true,
    path: "/features-page/manage-emails/question-answer",
    icon: HelpIcon,
    type: "email_questions_answers",
    isPro: true,
  },
];
