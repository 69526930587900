import axios from "axios";
import { getApplicationInfo, getCompanyId } from "../../../utils";

export type IAverageRatingsData = {
  analyticsInRange: {
    totalPercentageCount: [
      {
        star: number;
        count: number;
      }
    ];
  };
  analyticsTillRange: {
    totalPercentageCount: [
      {
        star: number;
        count: number;
      }
    ];
  };
  analyticsCompared: {
    totalPercentageCount: [
      {
        star: number;
        count: number;
      }
    ];
  };
  totalResponsesCountForReviews: number;
};

export type ITopProductData = {
  media: [
    {
      url: string;
      type: "image" | "video";
    }
  ];
  name: string;
  description: string;
  brand: string;
  created_at: string;
  updated_at: string;
  product_id: number;
  review_count: number;
  rating: number;
  last_reviewed: string;
  review_metric: {
    _id: string;
    rating_count: 1;
    rating_metric: [
      {
        rating: number;
        count: number;
      }
    ];
    rating_sum: number;
    review_count: number;
    updated_at: string;
  };
};

export type ICustomerSentimentData = {
  analyticsInRange: {
    happy: number;
    neutral: number;
    sad: number;
  };
  analyticsTillRange: {
    happy: number;
    neutral: number;
    sad: number;
  };
  analyticsCompared: {
    happy: number;
    neutral: number;
    sad: number;
  };
};

export type INpsData = {
  analyticsInRange: [
    {
      _id: string;
      promoters: number;
      passives: number;
      detractors: number;
    }
  ];
  analyticsTillRange: [
    {
      _id: string;
      promoters: number;
      passives: number;
      detractors: number;
    }
  ];
  analyticsCompared: [
    {
      _id: string;
      promoters: number;
      passives: number;
      detractors: number;
    }
  ];
};

export type ITotalReviews = {
  reviewsInRange: [
    {
      _id: string; // date - 2023-08-23
      negativeCount: number;
      positiveCount: number;
    }
  ];
  analyticsCompared: [
    {
      _id: string; // date - 2023-08-23
      negativeCount: number;
      positiveCount: number;
    }
  ];
};

export type IAnalyticsCountData = {
  analyticsInRange: {
    reviewCount: number;
    totalCouponSentCount: number;
    totalEmailSentCount: number;
    totalCouponAppliedCount: number;
  };
  analyticsTillRange: {
    reviewCount: number;
    totalCouponSentCount: number;
    totalEmailSentCount: number;
    totalCouponAppliedCount: number;
  };
  analyticsCompared: {
    reviewCount: number;
    totalCouponSentCount: number;
    totalEmailSentCount: number;
    totalCouponAppliedCount: number;
  };
};

interface IGetAnalyticsCountResponse {
  message: string;
  data: IAnalyticsCountData;
}

interface IGetAverageRatingsResponse {
  message: string;
  data: IAverageRatingsData;
}

interface IGetCustomerSentimentResponse {
  message: string;
  data: ICustomerSentimentData;
}

interface IGetTopProductsResponse {
  message: string;
  data: ITopProductData[];
}

interface IGetNpsScoreResponse {
  message: string;
  data: INpsData;
}

interface IGetTotalReviewsResponse {
  message: string;
  data: ITotalReviews;
}

export const getAverageRatings = async (days: number | string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetAverageRatingsResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/analytics/average-ratings?company_id=${getCompanyId()}&days=${days}`
  );
};

export const getCustomerSentiment = async (days: number | string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetCustomerSentimentResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/analytics/customer-sentiment?company_id=${getCompanyId()}&days=${days}`
  );
};

export const getTopProducts = async (days: number) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetTopProductsResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/analytics/top-products?company_id=${getCompanyId()}&days=${days}`
  );
};

export const getNpsScore = async (days: number | string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetNpsScoreResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/analytics/nps-score?company_id=${getCompanyId()}&days=${days}`
  );
};

export const getTotalReviews = async (days: number) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetTotalReviewsResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/analytics/total-reviews?company_id=${getCompanyId()}&days=${days}`
  );
};

export const getAnalyticsCount = async (days: number | string) => {
  const { applicationId } = getApplicationInfo();

  return axios.get<IGetAnalyticsCountResponse>(
    `/api/v2.0/company/${getCompanyId()}/application/${applicationId}/analytics/counts?company_id=${getCompanyId()}&days=${days}`
  );
};
