import { Line } from "react-chartjs-2";
import { CategoryScale, Chart } from "chart.js";
import { Chart as ChartJS, registerables } from "chart.js";
import { generateOneMonthIntervals } from "../../../../../utils";
import { ITotalReviews } from "../../../service/dashboard.service";
import { useMemo } from "react";
ChartJS.register(...registerables);
Chart.register(CategoryScale);

interface ILineGraphProps {
  totalReviews: ITotalReviews["reviewsInRange"] | [];
  totalReviewsCompared: ITotalReviews["analyticsCompared"] | [];
}
export const LineGraph: React.FC<ILineGraphProps> = ({
  totalReviews = [],
  totalReviewsCompared = [],
}) => {
  const labelDates = useMemo(
    () => generateOneMonthIntervals()?.slice(0, Math.max(11, 9)),
    [totalReviews]
  );

  const formattedLabelDates = useMemo(() => {
    return labelDates?.map((date) => {
      const newDate = new Date(date);
      const monthAbbreviation = newDate.toLocaleString("en-US", {
        month: "short",
      });
      const day = newDate.getDate();
      const formattedDate = `${monthAbbreviation} ${day}`;
      return formattedDate;
    });
  }, [labelDates]);

  const positiveReviews = useMemo(() => {
    const reviews: number[] = [];

    labelDates?.forEach((labelDate) => {
      const review = totalReviews?.find((review) => review?._id === labelDate);

      reviews.push(review?.positiveCount ?? 0);
    });
    return reviews;
  }, [labelDates, totalReviews]);

  const negativeReviews = useMemo(() => {
    const reviews: number[] = [];

    labelDates?.forEach((labelDate) => {
      const review = totalReviews?.find((review) => review?._id === labelDate);

      reviews.push(review?.negativeCount ?? 0);
    });
    return reviews;
  }, [labelDates, totalReviews]);

  const data = {
    datasets: [
      {
        label: "Positive Reviews",
        borderColor: "#2E31BE",
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "#E7EEFF");
          gradient.addColorStop(1, "rgba(231, 238, 255, 0.00)");

          return gradient;
        },
        data: positiveReviews,
        borderWidth: 2,
        fill: true,
      },
      {
        label: "Negative Reviews",
        borderColor: "#B24141",
        data: negativeReviews,
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "#E7EEFF");
          gradient.addColorStop(1, "rgba(231, 238, 255, 0.00)");

          return gradient;
        },
        borderWidth: 2,
        fill: true,
      },
    ],
    labels: formattedLabelDates,
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.4, // Adjust this value to control the curve (0 = straight lines, 1 = curved)
      },
    },
  };

  return <Line data={data} options={options} width={1040} />;
};
