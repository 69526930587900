import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import BorderAtom from "../../../../components/atoms/border/border.component";
import TextAtom from "../../../../components/atoms/text/text";
import FieldInputBox from "../../../../components/molecules/field-control/input-box/input-box.molecules";
import FieldSelect from "../../../../components/molecules/field-control/select/select.molecule";
import RightPanel from "../../../../components/templates/right-panel/right-panel.template";
import ToggleBox from "../../../../components/molecules/toggle-box/toggle-box.molecule";
import RadioBox from "../../../../components/molecules/radio-box/radio-box";
import SectionHeadingWithDescBox from "../../../../components/molecules/section-heading-description/section-header";
import LayoutMolecule from "../../../../components/molecules/field-control/layout-box/layout-box.molecules";
import ButtonAtom from "../../../../components/atoms/button/button.atom";

import {
  defaultReviewHomeRating,
  layoutItems,
  ratingTypeOptions,
} from "./config/review-home-carousel.constants";

import CSS from "./review-home-carousel.module.scss";
import {
  getFormConfig,
  updateFormConfig,
} from "../../../../services/config.service";

import TOP_REVIEWS from "./config/top-reviews.json";
import { useConfigContext } from "../../../../context/config-context";
import {
  showDangerToast,
  showSuccessToast,
  TOAST_MESSAGES,
} from "../../../../services/toast.service";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import Modal from "../../../../components/molecules/modal/modal.molecule";
import { useSubscriptionContext } from "../../../../context/subscription-context";
import ConfirmBody from "../../../../components/templates/confirm-modal";

interface IReviewHomeConfig {
  selected_layout: number;
  section_heading: string;
  rating_type: string;
  slide_grids: string;
  review_media: boolean;
  display_user_avatar: boolean;
  view_product_link: boolean;
  review_date: boolean;
  slider_navigation: boolean;
  slider_pagination: boolean;
  slider_overflow: boolean;
  slider_pause_on_hover: boolean;
  is_active: boolean;
}

const ReviewHome = () => {
  const [reviewHomeConfig, setReviewHomeConfig] = useState<IReviewHomeConfig>(
    defaultReviewHomeRating
  );
  const navigate = useNavigate();
  const { setConfig } = useConfigContext();
  const preventConfigUpdate = useRef<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { subscription } = useSubscriptionContext();

  useEffect(() => {
    (async () => {
      try {
        const res = await getFormConfig<IReviewHomeConfig>("home_carousel");
        if (res?.data?.data) {
          if (!res?.data?.data?.[0]?.config?.is_active) {
            preventConfigUpdate.current = true;
          }
          setReviewHomeConfig(res.data.data?.[0]?.config);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useDidMountEffect(() => {
    if (!preventConfigUpdate.current) {
      (async () => {
        try {
          const res = await updateFormConfig<IReviewHomeConfig>({
            widget: "home_carousel",
            payload: reviewHomeConfig,
          });

          if (res?.data) {
            showSuccessToast(
              res?.data?.data?.[0]?.config?.is_active
                ? TOAST_MESSAGES.configEnabled
                : TOAST_MESSAGES.configDisabled
            );
          }
        } catch (err) {
          console.log(err);
          showDangerToast(
            reviewHomeConfig?.is_active
              ? TOAST_MESSAGES.configEnabledError
              : TOAST_MESSAGES.configDisabledError
          );
          handleConfigChange("is_active", !reviewHomeConfig?.is_active);
          preventConfigUpdate.current = true;
        }
      })();
    } else {
      preventConfigUpdate.current = false;
    }
  }, [reviewHomeConfig.is_active]);

  useEffect(() => {
    setConfig((prevData: any) => ({
      ...prevData,
      homeReviewCarouselConfig: { ...reviewHomeConfig },
      topReviewsList: TOP_REVIEWS,
      type: "REVIEW_CAROUSEL",
    }));
  }, [reviewHomeConfig]);

  const handleConfigChange = (
    configKey: string,
    value: string | boolean | number | string[]
  ) => {
    setReviewHomeConfig((prevState) => {
      return { ...prevState, [configKey]: value };
    });
  };

  const handleSaveClick = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();

    try {
      const res = await updateFormConfig<IReviewHomeConfig>({
        widget: "home_carousel",
        payload: reviewHomeConfig,
      });

      if (res?.data) {
        showSuccessToast(TOAST_MESSAGES.configUpdated);
      }
    } catch (err) {
      console.log(err);
      showDangerToast(TOAST_MESSAGES.configUpdateError);
    }
  };

  const handleCloseModal = useCallback(() => {
    setShowUpgradeModal(false);
  }, []);

  const handleProModal = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
      MouseEvent
    >
  ) => {
    event?.preventDefault();
    setShowUpgradeModal(true);
  };

  return (
    <div>
      <div className="cs-bm-24">
        <SectionHeadingWithDescBox
          id="review_home_carousel"
          heading="Review Home Carousel"
          description="Select your design layout and customise the settings as per your needs"
          value={reviewHomeConfig?.is_active}
          onToggle={() =>
            handleConfigChange("is_active", !reviewHomeConfig?.is_active)
          }
          onBackClick={useCallback(() => navigate(-1), [])}
          isPro={true}
        />
      </div>
      <div
        className={`${CSS["cs-grid-container"]} ${CSS["cs-template-30-70"]} ${CSS["cs-column-gap-25"]}`}
      >
        <form>
          <div className="cs-bm-12">
            <TextAtom
              fontWeight={600}
              id="text-heading"
              text="Layouts"
              type="header"
            />
          </div>
          <LayoutMolecule
            selected={reviewHomeConfig?.selected_layout}
            items={layoutItems}
            id="design-layout"
            onClick={(value) => handleConfigChange("selected_layout", value)}
          />
          <div className="cs-tm-24 cs-bm-12">
            <TextAtom
              fontWeight={600}
              id="text-heading"
              text="Settings"
              type="header"
            />
          </div>
          <div
            className={`${CSS["cs-grid-container"]} ${CSS["cs-grid-of-two"]} ${CSS["cs-column-gap-16"]} ${CSS["cs-row-gap"]}`}
          >
            <FieldInputBox
              placeholder="Enter Section Heading"
              id="section-heading"
              value={reviewHomeConfig?.section_heading}
              onChange={(text) => handleConfigChange("section_heading", text)}
              label="Section Heading"
            />
            <FieldSelect
              items={ratingTypeOptions}
              onChange={(value) => handleConfigChange("rating_type", value)}
              value={reviewHomeConfig?.rating_type}
              placeholder="Select Rating Type"
              id="rating-type"
              label="Rating Type"
            />
          </div>
          <div>
            <div className="cs-tm-24">
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            {reviewHomeConfig?.selected_layout === 1 && (
              <div className="cs-tm-12 cs-bm-12">
                <RadioBox
                  radios={[
                    {
                      id: "masonry",
                      onChange: () =>
                        handleConfigChange("slide_grids", "masonry"),
                      value: "masonry",
                      name: "Masonry",
                      labelText: "Masonry",
                      radioValue: reviewHomeConfig?.slide_grids,
                    },
                    {
                      id: "horizontal",
                      onChange: () =>
                        handleConfigChange("slide_grids", "horizontal"),
                      value: "horizontal",
                      name: "Horizontal",
                      labelText: "Horizontal",
                      radioValue: reviewHomeConfig?.slide_grids,
                    },
                  ]}
                  heading="Slide Grids"
                  layout="horizontal"
                />
              </div>
            )}
            {reviewHomeConfig?.selected_layout === 1 && (
              <div>
                <BorderAtom borderColor="#ededed" borderSize="0.5px" />
              </div>
            )}
            <div className="cs-tm-12 cs-bm-12">
              <ToggleBox
                id="review-media"
                value={reviewHomeConfig?.review_media ?? false}
                onToggle={() =>
                  handleConfigChange(
                    "review_media",
                    !reviewHomeConfig?.review_media
                  )
                }
              >
                <TextAtom
                  id="review-media"
                  text="Review Media"
                  type="heading"
                />
              </ToggleBox>
            </div>
            {reviewHomeConfig?.selected_layout !== 2 && (
              <div>
                <div>
                  <BorderAtom borderColor="#ededed" borderSize="0.5px" />
                </div>
                <div className="cs-tm-12 cs-bm-12">
                  <ToggleBox
                    id="display-user-avatar"
                    value={reviewHomeConfig?.display_user_avatar ?? false}
                    onToggle={() =>
                      handleConfigChange(
                        "display_user_avatar",
                        !reviewHomeConfig?.display_user_avatar
                      )
                    }
                  >
                    <TextAtom
                      id="display-user-avatar"
                      text="Display User Avatar"
                      type="heading"
                    />
                  </ToggleBox>
                </div>
              </div>
            )}
            <div>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-12 cs-bm-12">
              <ToggleBox
                id="view-product-link"
                value={reviewHomeConfig?.view_product_link ?? false}
                onToggle={() =>
                  handleConfigChange(
                    "view_product_link",
                    !reviewHomeConfig?.view_product_link
                  )
                }
              >
                <TextAtom
                  id="view-product-link"
                  text="View Product Link"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <div>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-12 cs-bm-12">
              <ToggleBox
                id="review-date"
                value={reviewHomeConfig?.review_date ?? false}
                onToggle={() =>
                  handleConfigChange(
                    "review_date",
                    !reviewHomeConfig?.review_date
                  )
                }
              >
                <TextAtom id="review-date" text="Review Date" type="heading" />
              </ToggleBox>
            </div>
            <div>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-12 cs-bm-12">
              <ToggleBox
                id="slider-navigation"
                value={reviewHomeConfig?.slider_navigation ?? false}
                onToggle={() =>
                  handleConfigChange(
                    "slider_navigation",
                    !reviewHomeConfig?.slider_navigation
                  )
                }
              >
                <TextAtom
                  id="slider-navigation"
                  text="Slider Navigation"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <div>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-12 cs-bm-12">
              <ToggleBox
                id="slider-pagination"
                value={reviewHomeConfig?.slider_pagination ?? false}
                onToggle={() =>
                  handleConfigChange(
                    "slider_pagination",
                    !reviewHomeConfig?.slider_pagination
                  )
                }
              >
                <TextAtom
                  id="slider-pagination"
                  text="Slider Pagination"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <div>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-12 cs-bm-12">
              <ToggleBox
                id="slider-overflow"
                value={reviewHomeConfig?.slider_overflow ?? false}
                onToggle={() =>
                  handleConfigChange(
                    "slider_overflow",
                    !reviewHomeConfig?.slider_overflow
                  )
                }
              >
                <TextAtom
                  id="slider-overflow"
                  text="Slider Overflow"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <div>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-12 cs-bm-12">
              <ToggleBox
                id="slider-pause-on-hover"
                value={reviewHomeConfig?.slider_pause_on_hover ?? false}
                onToggle={() =>
                  handleConfigChange(
                    "slider_pause_on_hover",
                    !reviewHomeConfig?.slider_pause_on_hover
                  )
                }
              >
                <TextAtom
                  id="slider-pause-on-hover"
                  text="Slider Pause On Hover"
                  type="heading"
                />
              </ToggleBox>
            </div>
            <div>
              <BorderAtom borderColor="#ededed" borderSize="0.5px" />
            </div>
            <div className="cs-tm-24">
              <ButtonAtom
                label="Save"
                onClick={
                  subscription?.plan_type === "basic"
                    ? handleProModal
                    : handleSaveClick
                }
              />
            </div>
          </div>
        </form>
        <div>
          <RightPanel />
        </div>
      </div>
      {showUpgradeModal && (
        <div>
          <Modal
            showModal={showUpgradeModal}
            handleCloseModal={handleCloseModal}
            id="upgrade"
            kind="informational"
            title=""
            isClosable={false}
            style={{ "width": "22%" }}
          >
            <ConfirmBody
              onCancel={handleCloseModal}
              onSuccess={() => navigate("/payment")}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ReviewHome;
