export const defaultApplicationReviewConfig = {
  subject: "",
  preheader_text: "",
  provider: "default",
  order_delivered_email_content: ''
};

export const defaultProviderOptions = [
  {
    text: "Default",
    value: "default",
  },
  {
    text: "SMTP",
    value: "smtp",
  },
];
