import React from "react";
import CSS from "./subscription.hoc.module.scss";
import { useSubscriptionContext } from "../../context/subscription-context";
import { LayoutAtomProps } from "../../components/atoms/layout/layout.atom";

export function withSubscription<T>(Component: React.ComponentType<T>) {

    return (props: T & any) => {
        const { subscription } = useSubscriptionContext()

        const { item, cardData } = props

        return (
            subscription.plan_type === "basic" && (item?.isPro || cardData?.isPro) ?
                <div>
                    <div className={CSS.proContainer}>
                        <div className={`${CSS.pro} ${cardData?.isPro ? CSS.cardPro : ''}`}>PRO</div>
                    </div>
                    <Component {...props} />
                </div > :
                <Component {...props} />

        );
    };
}

export default withSubscription;
