import React from 'react';

import exclamation from '../../../assets/exclamation.svg'
import CSS from "./confirm-body.template.module.scss";

interface confirmBody {
    onCancel: Function;
    onSuccess: Function;
}


const ConfirmBody = ({ onCancel, onSuccess }: confirmBody) => {

    return (<div>
        <div className={CSS['pro-layout']}>
            <div className={CSS['pro-container']}>
                <img alt="exclamation" src={exclamation} />
                <div className={CSS['upgrade']}>Upgrade to Pro</div>
            </div>
        </div>
        <div className={CSS['button-container']}>
            <button className={`${CSS['success-button']} ${CSS['cancel']}`} onClick={() => onCancel()}>Cancel</button>
            <button className={CSS['success-button']} onClick={() => onSuccess()}>Go to Pricing</button>
        </div>
    </div>)


}

export default ConfirmBody